@charset "utf-8";
/*
#overview
modTable
*/

/*
#styleguide
modTable…通常のtableスタイル
modTable_fixed…各列が均等になります。
modTable_scroll…スマホで横スクロールになります。
modTable_column…スマホで縦並びになります。

※「modTable_scroll」「modTable_column」は、スマホでのスタイルを指定する役割を持ちます。
単体では意味を持たず、「modTable」もしくは「modTable_fixed」と組み合わせて使用します。
また、「modTable_scroll」は「scrollWrap」で囲む必要があります。
```
<div class="scrollWrap">
  <table class="modTable_fixed modTable_scroll">
    <tbody>
      <tr>
        <th>見出し</th>
        <th>見出し</th>
        <th>見出し</th>
      </tr>
      <tr>
        <th class="bgGray">サンプルテキスト</th>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
      </tr>
      <tr>
        <th class="bgGray">サンプルテキスト</th>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
      </tr>
    </tbody>
  </table>
</div>

<table class="modTable modTable_column">
  <tbody>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <th class="bgGray">サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th class="bgGray">サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
  tbody {
    th {
      background-color: #103459;
      color: #fff;
      padding: 10px 33px;
      border: 1px solid #fff;
    }
    td {
      background-color: #f5f5f5;
      padding: 10px 33px;
      border: 1px solid #fff;
    }
    .bgGray {
      background-color: #e6e6e6;
      border: 1px solid #fff;
      color: $baseColor;
    }
  }
  &_fixed {
    @extend .modTable;
    table-layout: fixed;
  }
}

.modTable {
  &_column {
    @include sphoneP {
      thead,
      tr,
      tbody,
      th,
      td {
        display: block;
        width: 100%;
      }
    }
  }
  &_scroll {
    @include sphoneP {
      min-width: 600px;
      margin-bottom: 10px !important;
    }
  }
}
.scrollWrap {
  overflow: auto;
  margin-bottom: 45px;
}

/*
#styleguide
modTable_gray…採用情報に使用している、グレーのtableスタイル
```
<table class="modTable_gray modTable_column">
  <tbody>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable_gray {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
  tbody {
    th {
      background-color: #646464;
      color: #fff;
      padding: 10px 33px;
      border: 1px solid #fff;
      @include sphoneP_plus {
        width: 178px;
      }
    }
    td {
      background-color: #f5f5f5;
      padding: 10px 33px;
      border: 1px solid #fff;
    }
  }
}

/*
#styleguide
modTable_large…LPに使用している、大き目のtableスタイル
```
<table class="modTable_large modTable_column">
  <tbody>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable_large {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
  tbody {
    th {
      background-color: $colorBlue;
      color: #fff;
      padding: 20px 33px;
      border: 1px solid #fff;
      @include sphoneP_plus {
        width: 235px;
      }
    }
    td {
      background-color: #f5f5f5;
      padding: 20px 33px;
      border: 1px solid #fff;
    }
  }
}