@charset "utf-8";

/*
#overview
modBlock
*/

/*
#styleguide
modBlock01
modBlock02
modBlock03
modBlock04

```
<div class="modBlock01">
  <h3 class="modTitle03">H3タイトルが入りますタイトルが入ります</h3>
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock02">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock03">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock04">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
```
*/
.modBlock01 {
  padding: 30px 30px 40px;
  background-color: #f5f5f5;
  margin-bottom: 55px;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.modBlock02 {
  padding: 30px 30px 40px;
  border: 1px solid #c8c8c8;
  margin-bottom: 55px;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.modBlock03 {
  padding: 30px 30px 40px;
  border: 1px solid #1e1e1e;
  margin-bottom: 55px;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}
.modBlock04 {
  padding: 30px 30px 40px;
  border: 1px solid $colorRed;
  margin-bottom: 55px;
  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

/*
#styleguide
modPager

```
<div class="modPager">
  <div class="wp-pagenavi">
    <a href="#" class="prevpostslink">前へ</a>
    <span class="current">1</span>
    <a href="#" class="page">2</a>
    <a href="#" class="page">3</a>
    <a href="#" class="page">4</a>
    <a href="#" class="nextpostslink">次へ</a>
  </div>
</div>
```
*/
.modPager {
  margin-top: 40px;
  .wp-pagenavi {
    text-align: center;
  }
  .page {
    text-decoration: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    @include fz(14);
    border: 1px solid #c8c8c8;
    line-height: 1;
    padding-top: 4px;
  }
  .page:hover,
  .current {
    text-decoration: none;
    display: inline-block;
    width: 24px;
    height: 24px;
    @include fz(14);
    color: $colorRed;
    border: 1px solid $colorRed;
    line-height: 1;
    padding-top: 4px;
  }
  .page,
  .current {
    margin: 0 6px;
  }
  .prevpostslink {
    @include fz(16);
    margin-right: 15px;
    text-decoration: none;
    line-height: 1.8;
    &:before {
      content: "";
      display: inline-block;
      background: url(../img/arrow_pager.png) no-repeat;
      background-size: contain;
      width: 7px;
      height: 13px;
      transform: rotate(180deg);
      margin-right: 13px;
    }
  }
  .nextpostslink {
    @include fz(16);
    margin-left: 15px;
    text-decoration: none;
    line-height: 1.8;
    &:after {
      content: "";
      display: inline-block;
      background: url(../img/arrow_pager.png) no-repeat;
      background-size: contain;
      width: 7px;
      height: 13px;
      margin-left: 13px;
    }
  }
}

/*
#styleguide
modBlockSubjectList
modBlockSubjectList_clm2

```
<div class="modBlockSubjectList">
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">在庫適正化・PSI・計画業務</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">在庫削減・在庫適正化</a></li>
				<li><a href="">在庫の見える化（可視化）</a></li>
				<li><a href="">需要予測（出荷予測）の精度向上</a></li>
				<li><a href="">生産計画の精度向上</a></li>
				<li><a href="">需給調整業務、表計算からの脱却需給調整業務、表計算からの脱却</a></li>
				<li><a href="">複数倉庫の在庫適正配分複数倉庫の在庫適正配分複数倉庫の</a></li>
				<li><a href="">発注業務の効率化</a></li>
				<li><a href="">デッドストック化の防止</a></li>
				<li><a href="">特売・催事に対応した発注</a></li>
			</ul>
		</div>
	</div>
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">販売物流業務・オーダーマネジメント</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">正確な在庫引当て</a></li>
				<li><a href="">即時納期回答（サービス向上）</a></li>
				<li><a href="">受注手配業務の効率化</a></li>
				<li><a href="">賞味期限・有効期限管理の徹底</a></li>
				<li><a href="">納品問合せ対応</a></li>
				<li><a href="">トラブル時の再手配</a></li>
				<li><a href="">納品リードタイムの短縮</a></li>
				<li><a href="">バックオーダー管理業務の効率化</a></li>
				<li><a href="">物流環境変化への対応</a></li>
			</ul>
		</div>
	</div>
</div><!--/modBlockSubjectList-->
<div class="modBlockSubjectList modBlockSubjectList_clm2">
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">在庫適正化・PSI・計画業務</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">在庫削減・在庫適正化</a></li>
				<li><a href="">在庫の見える化（可視化）</a></li>
				<li><a href="">需要予測（出荷予測）の精度向上</a></li>
				<li><a href="">生産計画の精度向上</a></li>
				<li><a href="">需給調整業務、表計算からの脱却需給調整業務、表計算からの脱却</a></li>
				<li><a href="">複数倉庫の在庫適正配分複数倉庫の在庫適正配分複数倉庫の</a></li>
				<li><a href="">発注業務の効率化</a></li>
				<li><a href="">デッドストック化の防止</a></li>
				<li><a href="">特売・催事に対応した発注</a></li>
			</ul>
		</div>
	</div>
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">販売物流業務・オーダーマネジメント</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">正確な在庫引当て</a></li>
				<li><a href="">即時納期回答（サービス向上）</a></li>
				<li><a href="">受注手配業務の効率化</a></li>
				<li><a href="">賞味期限・有効期限管理の徹底</a></li>
				<li><a href="">納品問合せ対応</a></li>
				<li><a href="">トラブル時の再手配</a></li>
				<li><a href="">納品リードタイムの短縮</a></li>
				<li><a href="">バックオーダー管理業務の効率化</a></li>
				<li><a href="">物流環境変化への対応</a></li>
			</ul>
		</div>
	</div>
</div><!--/modBlockSubjectList-->
```
*/
.modBlockSubjectList {
  padding-top: 10px;
  &__item {
    background-color: #fff;
    margin-top: 40px;
    @include sphoneP {
      width: 100%;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 45px;
    box-shadow: 0 3px 5px 2px rgba(0,0,0,0.1);
    align-items: flex-start;
    @include sphoneP {
      padding: 16px;
    }
  }
  &__thumb {
    @include sphoneP {
      width: 85px;
    }
  }
  &__link {
    width: calc(100% - 280px);
    display: flex;
    flex-wrap: wrap;
    @include sphoneP {
      width: calc(100% - 100px);
    }
  }
  li {
    @include fz(16);
    margin-right: 25px;
    width: 292px;
    line-height: 1.8;
    margin-bottom: 30px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    @include sphoneP {
      margin-bottom: 0;
    }
  }
  a {
    text-decoration: none;
    padding-right: 20px;
    display: inline-block;
    max-width: 100%;
    position: relative;
    &:hover {
      opacity: 1;
      color: $colorRed;
    }
    &:after {
      width: 7px;
      height: 13px;
      background: url(../img/home/arrow_red.png) no-repeat;
      background-size: contain;
      content: "";
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 7px;
      @include sphoneP {
        width: 5px;
        top: 8px;
      }
    }
    @include sphoneP {
      @include fz(12);
      padding-left: 1.1em;
      padding-right: 0;
      &:after {
        left: 0px;
        right: auto;
      }
    }
  }
  &__title {
    padding: 8px;
    background-color: $colorRed;
    text-align: center;
    @include fz(18);
    color: #fff;
    @include sphoneP {
      @include fz(14);
      padding: 4px 8px;
    }
  }
  &_clm2 {
    display: flex;
    justify-content: space-between;
    @include sphoneP {
      flex-direction: column;
    }
    .modBlockSubjectList {
      &__item {
        width: 630px;
        background-color: #fff;
        @include sphoneP {
          width: 100%;
        }
      }
      &__inner {
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
      }
      &__link {
        width: 265px;
        @include sphoneP {
          width: calc(100% - 100px);
        }
      }
    }
    li {
      @include fz(15);
      line-height: 2;
      margin-bottom: 0;
      margin-right: 0;
    }
    a {
      padding-right: 23px;
      &:after {
        top: 9px;
      }
    }
  }
}

/*
#styleguide
modBlockSolution
modBlockSolution_low

```
<div class="modBlockSolution">
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">賞味期限・ロット別在庫への自動引当</span><span class="modBlockSolution__text">食品業界必須の賞味期限管理。産地など属性別の在庫を自動引当し、業務コストを抑えて販売物流の業務スピード向上</span><span class="modBlockSolution__tag">食品流通</span></a>
	</div>
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">需給調整スピードアップ、生産計画精度向上</span><span class="modBlockSolution__text">PSI可視化・コントロールで需給調整業務を支援し、クイックレスポンスの実現へ</span><span class="modBlockSolution__tag">食品製造</span></a>
	</div>
</div><!--/modBlockSolution-->
<div class="modBlockSolution modBlockSolution_low">
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">賞味期限・ロット別在庫への自動引当</span><span class="modBlockSolution__text">食品業界必須の賞味期限管理。産地など属性別の在庫を自動引当し、業務コストを抑えて販売物流の業務スピード向上</span><span class="modBlockSolution__tag">食品流通</span></a>
	</div>
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">需給調整スピードアップ、生産計画精度向上</span><span class="modBlockSolution__text">PSI可視化・コントロールで需給調整業務を支援し、クイックレスポンスの実現へ</span><span class="modBlockSolution__tag">食品製造</span></a>
	</div>
</div><!--/modBlockSolution-->
```
*/
.modBlockSolution {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -40px;
  &__item {
    width: 630px;
    background-color: #fff;
    margin-bottom: 40px;
    @include sphoneP {
      margin: 0 auto 30px;
    }
  }
  a {
    display: block;
    position: relative;
    text-decoration: none;
    padding: 0 30px 30px;
    @include sphoneP {
      padding: 0 13px 13px;
    }
  }
  &__thumb {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-bottom: 12px;
    display: block;
    @include sphoneP {
      width: calc(100% + 26px);
      margin-left: -13px;
    }
  }
  &__tag {
    text-align: center;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: $colorRed;
    line-height: 1.4;
    @include fz(14);
    @include sphoneP {
      width: 100px;
      height: 48px;
      @include fz(11);
      line-height: 1.2;
      letter-spacing: -0.02em;
    }
  }
  &__title {
    @include fz(20);
    padding-right: 23px;
    position: relative;
    display: inline-block;
    display: block;
    @include sphoneP {
      @include fz(15);
      line-height: 1.2;
    }
    &:after {
      content: "";
      width: 10px;
      height: 17px;
      background: url(../img/home/arrow_red.png) no-repeat;
      background-size: contain;
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 10px;
      @include sphoneP {
        width: 7px;
        top: 4px;
      }
    }
    small {
      @include fz(16);
      @include sphoneP {
        @include fz(11);
      }
    }
  }
  &__text {
    margin-top: 10px;
    @include fz(16);
    padding-top: 14px;
    position: relative;
    display: block;
    @include sphoneP {
      @include fz(13);
    }
    &:before {
      width: 33px;
      height: 2px;
      display: inline-block;
      content: "";
      top: 0px;
      left: 0px;
      position: absolute;
      background: linear-gradient(90deg,$colorRed 0%,$colorRed 80%,#444444 80%,#444444 100%);
      @include sphoneP {
        width: 20px;
      }
    }
  }
}
.modBlockSolution_low {
  margin-top: 100px;
  &.modBlockSolution {
    a {
      padding: 0 20px 20px;
    }
  }
  .modBlockSolution {
    &__item {
      width: 438px;
      box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.1);
    }
    &__thumb {
      margin-left: -20px;
      margin-right: -20px;
      width: calc(100% + 40px);
    }
    &__title {
      line-height: 1.3;
    }
  }
}

/*
#styleguide
recentPostBlock

```
<div class="recentPostBlock">
<div class="recentPostBlock__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/thumb_sks.jpg" alt=""></div>
<p class="recentPostBlock__text"><span class="recentPost__client">精密機械商社</span><br>
<a class="modLinkArrow">株式会社清光社様</a><br>
<span class="strong">需給調整業務をExcel管理からシステム化。業務標準化・作業効率化・在庫有効活用に効果あり</span><br>
運動機械部品のメーカーとユーザーを結ぶ専門商社、株式会社清光社様のご活用事例です。</p>
</div>
```
*/
.recentPostBlock {
  margin-bottom: 50px;
  display: flex;
  a {
    font-weight: bold;
  }
  @include sphoneP {
    flex-direction: row;
    > *:first-child {
      margin-right: 10px;
    }
  }
  &__img {
    width: 120px;
    margin-right: 30px;
    @include sphoneP {
      width: 80px;
      margin-right: 15px;
    }
  }
  &__text {
    flex: 1;
    @include fz(16);
  }
}

/*
#styleguide
textImgRow

```
<div class="textImgRow">
<p class="modText">商品ライフサイクルが短縮化している近年、いわゆる「定番品」の比率が低下し、需要予測は大変困難な時代です。現場では特売やキャンペーンなどイベントも多く、予測の課題は増すばかり。いまだ表計算で予測を行っている企業様は、限界があるように感じてはいるものの、高価な予測システムを入れるほどでは…と思われているのではないでしょうか。<br>
また、営業部の作成した予測が、全社計画のベースになる場合も多いですが、「在庫計画上は疑問も多い」といった声も聞かれます</p>
<div class="textImgRow__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/img_solution_human.jpg" alt=""></div>
</div>
```
*/
.textImgRow {
  display: flex;
  justify-content: space-between;
  @include sphoneP {
    flex-direction: column;
  }
  &__img {
    flex: none;
    @include sphoneP {
      text-align: center;
    }
  }
  p {
    margin-top: 0;
  }
  > *:first-child {
    margin-right: 45px;
    @include sphoneP {
      margin-bottom: 20px;
      margin-right: 0px;
    }
  }
}

/*
#styleguide
lpIntro

```
<div class="lpHero">
<div class="lpContainer">
<div class="lpHero__inner">
<div class="lpHero__left">
<p class="lpHero__title">在庫/販売物流<br />イノベーションセミナー＆<br />相談会</p>
<p class="lpHero__location">会場：東京都千代田区（神田）</p>
</div>
<div class="lpHero__right"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_hero.png" alt="今こそ、業務の改革を。" /></div>
</div>
</div>
</div>
```
*/
.lpHero {
  height: 428px;
  background: url(../img/lp/bg_hero.jpg) no-repeat center;
  background-size: cover;
  margin-bottom: 75px;
  @include sphoneP {
    height: auto;
    padding: 60px 0;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    @include sphoneP {
      flex-direction: column;
    }
  }
  &__left {
    padding-top: 110px;
    padding-left: 35px;
    width: 590px;
    @include sphoneP {
      padding-top: 0;
      width: auto;
      padding-left: 0;
      padding-bottom: 20px;
    }
  }
  &__title {
    @include fz(47);
    line-height: 1.4;
    margin-bottom: 27px;
    letter-spacing: -0.03em;
    @include sphoneP {
      @include fz(26);
    }
  }
  &__location {
    background-color: $colorBlue;
    color: #fff;
    font-size: 23px;
    padding: 3px;
    text-align: center;
  }
}

/*
#styleguide
lpIntro

```
<div class="lpIntroTop">
<div class="lpIntroTopLeft">
<div class="lpIntroTopLeft__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/logo_conductor.png" alt="Conductor for Foods" /></div>
<p class="lpIntroTopLeft__text">食品業界向け</p>
</div>
<div class="lpIntroTopRight">
<div class="lpIntroTopRight__contact">
<p class="lpIntroTopRight__tag">お問合せ</p>
<p class="lpIntroTopRight__tel"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_tel.png" alt="" width="20" />03-5822-6511</p>
</div>
<p class="lpIntroTopRight__name">株式会社フェアウェイソリューションズ</p>
</div>
</div>
<div class="lpIntroBottom">
<div class="lpIntroBottomLeft">
<p class="lpIntroBottomLeft__tag">業務支援型</p>
<p class="lpIntroBottomLeft__text">販売物流ソリューション</p>
</div>
<ul class="lpIntroBottomRight">
<li>
<p class="lpIntroBottomRight__title">商社卸売業</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_track.png" alt="" width="71" /></div>
<p class="lpIntroBottomRight__description">食肉・生鮮食品</p>
</li>
<li>
<p class="lpIntroBottomRight__title">製造業</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_building.png" alt="" width="52" /></div>
<p class="lpIntroBottomRight__description">加工食品・惣菜</p>
</li>
<li>
<p class="lpIntroBottomRight__title">外食チェーン</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_house.png" alt="" width="53" /></div>
<p class="lpIntroBottomRight__description">輸入食品ほか</p>
</li>
</ul>
</div>
```
*/
.lpIntroTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $colorRed;
  padding-bottom: 25px;
  @include sphoneP {
    flex-direction: column;
  }
}
.lpIntroTopLeft {
  display: flex;
  align-items: center;
  @include sphoneP {
    flex-direction: column;
  }
  &__image {
    margin-right: 15px;
    @include sphoneP {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  &__text {
    @include fz(22);
  }
}
.lpIntroTopRight {
  &__contact {
    display: flex;
    align-items: center;
  }
  &__tag {
    background-color: $colorRed;
    color: #fff;
    width: 90px;
    line-height: 24px;
    @include fz(14);
    text-align: center;
    margin-right: 28px;
    border-radius: 12px;
  }
  &__tel {
    display: flex;
    align-items: center;
    @include fz(22);
    img {
      margin-right: 5px;
    }
  }
  &__name {
    font-size: 16px;
    font-weight: bold;
  }
}
.lpIntroBottom {
  display: flex;
  justify-content: space-between;
  @include sphoneP {
    flex-direction: column;
  }
}
.lpIntroBottomLeft {
  display: flex;
  align-items: center;
  padding-top: 15px;
  @include sphoneP {
    padding-top: 30px;
    flex-direction: column;
  }
  &__tag {
    width: 220px;
    line-height: 48px;
    background-color: $colorRed;
    color: #fff;
    text-align: center;
    @include fz(24);
    border-radius: 24px;
    margin-right: 24px;
    @include sphoneP {
      margin-right: 0;
    }
  }
  &__text {
    @include fz(46);
    @include sphoneP {
      @include fz(26);
    }
  }
}
.lpIntroBottomRight {
  display: flex;
  padding-top: 25px;
  @include sphoneP {
    justify-content: space-between;
  }
  li {
    width: 140px;
    height: 140px;
    text-align: center;
    border: 2px solid $colorRed;
    color: $colorRed;
    border-radius: 20px;
    margin-left: 17px;
    padding-top: 17px;
    @include sphoneP {
      margin-left: 0;
      width: 31%;
    }
  }
  &__title {
    @include fz(16);
    line-height: 1;
    font-weight: bold;
  }
  &__image {
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__description {
    @include fz(14);
    line-height: 1;
  }
}

/*
#styleguide
lpGuideTop

```
<div class="lpGuideTop">
<div class="lpContainer">
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">「ほとんどの仕事は人工知能に取って代わられる」という衝撃的なフレーズを耳にされたことがありませんか？<br />在庫管理や需給調整、販売物流に関わる業務も例外ではなく、グローバルな競争環境で厳しいコスト削減とスピード経営が叫ばれる中で、<span class="strong">従来の属人的なやり方を続けている業務現場には限界が近づいています。</span><br />これからの時代、IT活用による飛躍的な業務合理化と、<span class="strong">ベテランの経験値による業務判断とをバランス良く両立できる業務インフラ</span>が必要となって来るでしょう。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_01.jpg" alt="" /></div>
</div>
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">さて、私共フェアウェイソリューションズは、在庫適正化・販売物流業務の最適化をテーマとした専門アプリケーションを開発・販売し今年で17年目となります。様々な企業様とのお付き合いのなかで断言できることはたった一つ、<br /><span class="strong fz22 color_blue2">多くの企業様で『業務プロセス改革』は一筋縄では行きません。</span><br />在庫適正化という目的ひとつを取っても、アプローチは様々です。<br />私たちは業務課題を解決するためには、まず企業様それぞれのご事情把握とビジョンの共有が肝要と考えており、この度、一方的な情報発信ではない<br /><span class="strong fz20 color_red">１社対応の相談会形式セミナー</span>を開催する運びとなりました。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_02.jpg" alt="" /></div>
</div>
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">当日は、製造・流通業の数多のお客様でSCMシステム構築経験を持つスペシャリストが、お客様の課題解決に向けてアドバイスをさせていただきます。<br />システム導入を検討されている方も、まだ時期は早いと思われている方も、ぜひお気軽にご参加下さい。<br />在庫や物流に関する課題、煩雑なオペレーションの省力化にお悩みの企業様に、解決の一助となる事を願っております。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_03.jpg" alt="" /></div>
</div>
</div>
</div>
```
*/
.lpGuideTop {
  background-color: #f5f5f5;
  margin-bottom: 120px;
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  &:after{
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(245, 245, 245, 0);
    border-top-width: 40px;
    border-bottom-width: 40px;
    border-left-width: 65px;
    border-right-width: 65px;
    margin-left: -65px;
    border-top-color:#F5F5F5;
    top: 100%;
    left: 50%;
  }
  &__rows {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    @include sphoneP {
      flex-direction: column;
    }
  }
  &Left {
    width: 917px;
    @include fz(17);
    @include sphoneP {
      width: auto;
      margin-bottom: 20px;
    }
  }
  &__image {
    @include sphoneP {
      text-align: center;
    }
  }
}

/*
#styleguide
lpGuideFlowBlock

```
<div class="lpGuideFlowBlock">
<div class="lpGuideFlowBlock__left">
<p class="lpGuideFlowBlock__balloon">同業他社ではどのように在庫（需給）を管理しオペレーションを改善しているのか知りたい。<br />SCM/業務システムの最近の技術動向を知りたい。</p>
<p class="lpGuideFlowBlock__title">セミナー</p>
<p class="lpGuideFlowBlock__text">最新技術を用いた企業様の業務改革への取組みを様々な導入事例をもとに解り易くご紹介します。</p>
</div>
<div class="lpGuideFlowBlock__right"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_flow_01.jpg" alt="" /></div>
</div>
```
*/
.lpGuideFlowBlock {
  margin-top: 40px;
  border: 1px solid #e6e6e6;
  padding: 25px 70px 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sphoneP {
    flex-direction: column;
    padding: 20px;
  }
  &:first-child {
    margin-top: 60px;
  }
  &:last-child {
    margin-bottom: 70px;
  }
  &__left {
    width: 840px;
    @include sphoneP {
      width: auto;
      margin-bottom: 20px;
    }
  }
  &__balloon {
    background-color: #f5f5f5;
    margin-bottom: 15px;
    position: relative;
    padding: 25px 34px;
    @include fz(17);
    line-height: 2.2;
    border-radius: 8px;
    &:after{
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
      border-color: rgba(245, 245, 245, 0);
      border-top-width: 24px;
      border-bottom-width: 24px;
      border-left-width: 35px;
      border-right-width: 35px;
      margin-left: -35px;
      border-top-color:#F5F5F5;
      top: 100%;
      left: 50%;
    }
  }
  &__right {
    @include sphoneP {
      text-align: center;
    }
  }
  &__title {
    color: $colorRed;
    @include fz(34);
    font-weight: bold;
    line-height: 1.6;
  }
  &__text {
    @include fz(20);
    line-height: 1.6;
  }
}

/*
#styleguide
lpContact

```
<section class="lpContact">
<div class="lpContainer">
<h1 class="lpContact__headline">お申込み</h1>
<p class="lpContact__text">ご来場をお待ちしております。ご不明の点はお気軽にお問合せ下さい。</p>
<div class="modBtn01 modBtn01_large"><a href="#">お申込みはこちら</a></div>
<p class="lpContact__text lpContact__text_small">株式会社フェアウェイソリューションズ　イノベーションセミナー事務局<br />TEL 03-5822-6511 ／ FAX 03-5822-6515</p>
<p class="modLinkArrow strong"><a href="https://www.fw-solutions.com/" target="_blank" rel="noopener noreferrer">https://www.fw-solutions.com/</a></p>
</div>
</section>
```
*/
.lpContact {
  text-align: center;
  background-color: #f5f5f5;
  padding: 80px 0 60px;
  margin-bottom: 200px;
  @include sphoneP {
    padding-top: 35px;
  }
  &__headline {
    padding-top: 74px;
    line-height: 1;
    background: url(../img/lp/icon_pen.png) no-repeat center top;
    background-size: 52px auto;
    color: $colorRed;
    @include fz(42);
    font-weight: bold;
    margin-bottom: 60px;
    @include sphoneP {
      @include fz(31);
    }
  }
  &__text {
    @include fz(17);
    margin-bottom: 30px;
    &.lpContact__text_small {
      margin-bottom: 10px;
    }
  }
}

/*
#styleguide
lpFeatureListBlock

```
<div class="lpFeatureListBlock">
<dl class="lpFeatureList__item lpFeatureList__item_task">
<dt>お悩み</dt>
<dd>いろいろな形態・方式で注文が来るため、手配作業に入る前の「受注入力」自体に時間がかかっている。</dd>
</dl>
<dl class="lpFeatureList__item lpFeatureList__item_solve">
<dt>解決策</dt>
<dd>流通BMSに標準対応。Web・FAX・Excel表を利用して受注入力作業負荷の軽減を図ります</dd>
</dl>
</div>
```
*/
.lpFeatureListBlock {
  margin-top: 35px;
  &_mb140 {
    margin-bottom: 140px;
  }
}
.lpFeatureList {
  &__item {
    padding: 6px;
    display: flex;
    background-color: #f5f5f5;
    @include sphoneP {
      flex-direction: column;
      padding: 20px;
    }
    dt {
      min-height: 108px;
      width: 168px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      @include fz(24);
      font-weight: bold;
      margin-right: 46px;
      position: relative;
      @include sphoneP {
        width: 100%;
        margin-right: 0;
        @include fz(21);
        margin-bottom: 12px;
      }
      @include sphoneP_plus {
        &:after{
          border: solid transparent;
          content: '';
          height: 0;
          width: 0;
          pointer-events: none;
          position: absolute;
          border-color: rgba(245, 245, 245, 0);
          border-top-width: 13px;
          border-bottom-width: 13px;
          border-left-width: 13px;
          border-right-width: 13px;
          margin-top: -13px;
          border-left-color:#fff;
          top: 50%;
          left: 100%;
        }
      }
    }
    dd {
      width: calc(100% - 214px);
      @include fz(18);
      display: flex;
      align-items: center;
      @include sphoneP {
        width: 100%;
        @include fz(17);
      }
    }
    &_task {
      margin-bottom: 65px;
      position: relative;
      dt {
        color: $colorRed;
      }
      &:after {
        content: "";
        position: absolute;
        bottom: -50px;
        left: 50%;
        margin-left: -17px;
        background: url(../img/lp/arrow_gray.png) no-repeat;
        background-size: contain;
        width: 34px;
        height: 32px;
      }
    }
    &_solve {
      dt {
        color: $colorBlue;
      }
    }
  }
}

/*
#styleguide
lpSupportFlow

```
<section class="lpSupportFlow">
<h2 class="lpSupportHeadline">ご導入までの流れ</h2>
<div class="lpSupportFlowBlock">
<div class="lpSupportFlowBlock__left arrowPlus">
<p class="lpSupportFlowBlock__largeText">システム化計画支援</p>
<p class="lpSupportFlowBlock__text">業務改革の目的に沿ったシステム化計画を、投資対効果を考慮しながらご一緒に作成していきます。</p>
<div class="lpSupportFlowWhite">
<ul class="lpSupportFlowList">
<li>①現状業務の確認（As-Is）</li>
<li>②Conductorを活用した新業<br />務提案（To-Be）</li>
<li>③導入効果の事前推定</li>
<li>④概算お見積</li>
</ul>
</div>
</div>
<div class="lpSupportFlowBlock__center">
<h3 class="lpSupportH3">最短距離での目的達成へ</h3>
<div class="lpSupportFlowBlock__column arrowPlus">
<p class="lpSupportFlowBlock__largeText">要件定義 １〜２ヶ月</p>
<div class="lpSupportFlowWhite">
<ul class="lpSupportFlowList">
<li>①業務要件定義</li>
<li>②外部I/F要件定義</li>
<li>③システム要件定義</li>
<li>④稼働方式検討・承認</li>
<li>⑤ご導入可否判断</li>
</ul>
</div>
</div>
<div class="lpSupportFlowBlock__column arrowPlus">
<p class="lpSupportFlowBlock__largeText">導入準備・開発 ２〜３ヶ月</p>
<div class="lpSupportFlowWhite">
<dl class="lpSupportFlow2">
<dt>導入準備</dt>
<dd>移行準備（マスタ・トラン）</dd>
<dd>Conductor研修</dd>
<dd>ユーザテスト準備・実施</dd>
<dd>システム間連携テスト</dd>
<dt>Conductor開発</dt>
<dd>設計/開発/テスト</dd>
<dt>環境構築</dt>
<dd>ハードウェア調達</dd>
<dd>Conductorセットアップ</dd>
</dl>
</div>
</div>
</div>
<div class="lpSupportFlowBlock__right">
<p class="lpSupportFlowBlock__largeText">導入後サポート</p>
<p class="lpSupportFlowBlock__text">導入後の効果を定期的に追跡し、目標達成を検証します。必要時は改善提案を継続します。</p>
<div class="lpSupportFlowWhite">
<dl class="lpSupportFlow2">
<dt>運用サポート</dt>
<dd>運用定着化サポート</dd>
<dd>Conductor運用・活用相談</dd>
<dd>オペレーションヘルプデスク</dd>
</dl>
<dl class="lpSupportFlow2">
<dt>導入後効果追跡</dt>
<dd>導入効果検証</dd>
<dd>データベース使用状況点検</dd>
<dd>運用改善提案</dd>
</dl>
</div>
</div>
</div>
</section>
```
*/
.lpSupportHeadline {
  padding: 23px 10px;
  line-height: 1;
  color: #fff;
  background: $colorBlue;
  @include fz(32);
  text-align: center;
}
.arrowPlus {
  position: relative;
  @include sphoneP_plus {
    &:after {
      content: "";
      position: absolute;
      bottom: 149px;
      right: -45px;
      width: 34px;
      height: 32px;
      background: url(../img/lp/arrow_gray.png) no-repeat;
      background-size: contain;
      transform: rotate(-90deg);
    }
  }
}
.lpSupportFlowBlock {
  display: flex;
  justify-content: space-between;
  padding: 43px 40px 35px;
  background-color: #f5f5f5;
  margin-bottom: 70px;
  @include sphoneP {
    flex-direction: column;
    padding: 20px;
  }
  &__left {
    width: 262px;
    margin-top: -10px;
    @include sphoneP {
      margin-top: 0;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &__center {
    width: 582px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include sphoneP {
      width: 100%;
      flex-direction: column;
      margin-bottom: 30px;
    }
  }
  &__right {
    width: 262px;
    margin-top: -10px;
    @include sphoneP {
      margin-top: 0;
      width: 100%;
      margin-bottom: 30px;
    }
  }
  &__column {
    width: 262px;
    @include sphoneP {
      width: 100%;
      margin-bottom: 1px;
    }
  }
  &__largeText {
    font-size: 20px;
    margin-bottom: 10px;
    color: $colorBlue;
    border-radius: 6px;
  }
  &__text {
    margin-bottom: 10px;
  }
}
.lpSupportFlowWhite {
  background-color: #ffffff;
  height: 338px;
  border-radius: 12px;
  padding: 20px 24px;
}
.lpSupportH3 {
  width: 100%;
  line-height: 1em;
  padding: 15px 10px;
  color: #fff;
  background-color: $colorBlue;
  text-align: center;
  @include fz(26);
  margin-bottom: 20px;
  border-radius: 8px;
}
.lpSupportFlowList {
  li {
    margin-left: 1em;
    text-indent: -1em;
    @include fz(16);
    line-height: 2.1;
  }
}
.lpSupportFlow2 {
  @include fz(16);
  line-height: 1.5;
  &:not(:first-child) {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #c8c8c8;
  }
  dt {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  dd {
    margin-left: 30px;
    text-indent: -13px;
    &:before {
      width: 4px;
      height: 4px;
      content: "";
      display: inline-block;
      background-color: $baseColor;
      margin-right: 5px;
      vertical-align: 3px;
    }
  }
}

/*
#styleguide
lpExample

```
<div class="lpExample">
<div class="lpExample__item"><a><span class="lpExample__thumb"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/thumb_example.jpg" alt="" /></span><span class="lpExample__right"><span class="lpExample__title">総合小売業</span><span class="lpExample__largeText">生鮮食品、加工食品</span><span class="lpExample__text">賞味期限別週間発注への引当・出荷調整業務</span></span></a></div>
<div class="lpExample__item"><a><span class="lpExample__thumb"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/thumb_example.jpg" alt="" /></span><span class="lpExample__right"><span class="lpExample__title">外食チェーン</span><span class="lpExample__largeText">本部から店舗への物流を効率化</span><span class="lpExample__tag">100店舗を超えるレストランチェーンの本部</span><span class="lpExample__text">食材や用度品の手配本部業務を効率化</span></span></a></div>
</div>
```
*/
.lpExample {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  flex-wrap: wrap;
  &__item {
    width: 625px;
    border: 1px solid #e6e6e6;
    margin-bottom: 50px;
    a {
      display: flex;
      width: 100%;
      text-decoration: none;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 14px;
        top: 50%;
        right: 15px;
        margin-top: -7px;
        background: url(../img/arrow_red.png) no-repeat;
        background-size: contain;
        @include sphoneP {
          right: 7px;
        }
      }
    }
  }
  &__thumb {
    @include sphoneP {
      width: 95px;
    }
  }
  &__left {
    flex: none;
  }
  &__right {
    padding: 10px 15px 5px;
    flex: 1;
    @include sphoneP {
      padding: 10px 25px 5px 15px;
    }
  }
  &__title {
    @include fz(22);
    font-weight: bold;
    border-bottom: 2px solid $colorRed;
    margin-bottom: 10px;
    display: block;
  }
  &__largeText {
    @include fz(18);
    display: block;
  }
  &__text {
    margin-top: 10px;
    @include fz(15);
    display: block;
  }
  &__tag {
    padding: 0 10px;
    background-color: $colorRed;
    display: inline-block;
    color: #fff;
    @include fz(16);
    font-weight: bold;
  }
}

/*
#styleguide
lpExample

```
<div class="lpMoreBlock">
<p class="lpMoreBlock__largeText">詳しいことが知りたい！というお客様へ</p>
<div class="lpMoreBlock__row">
<div class="modBtn01 modBtn01_middle"><a>セミナー/相談会のお申込み</a></div>
<div class="modBtn01 modBtn01_middle"><a>お問合せ・資料ご請求</a></div>
</div>
<p class="lpMoreBlock__text"><a href="https://www.fw-solutions.com/" class="modLinkArrow" target="_blank" rel="noopener noreferrer">FAIRWAY SOLUTIONS 公式ホームページへ</a></p>
</div>
```
*/
.lpMoreBlock {
  text-align: center;
  padding: 30px;
  border: 1px solid #c8c8c8;
  .modBtn01 {
    margin-bottom: 25px;
  }
  &__largeText {
    @include fz(20);
    font-weight: bold;
    margin-bottom: 35px;
  }
  &__row {
    display: flex;
    justify-content: center;
    > * {
      margin: 0 24px;
      width: 520px;
    }
    @include sphoneP {
      flex-direction: column;
      > * {
        width: auto;
        margin: 0 0 10px;
      }
    }
  }
}

/*
#styleguide
lpSimulation

```
<div class="lpSimulation">
<h3 class="modTitle08 modTitle08_red mb0">3.改善効果の試算</h3>
<div class="lpSimulationBlock">
<div class="lpSimulationBlock__left">
<p class="lpText">実現可能な対策を検討し、定量的効果を試算します。</p>
<p class="lpText">改善策が実行されたとき、経営へのインパクトがどのくらいあるのか、ご関心のない経営者はおられないことでしょう。<br />在庫診断サービスでは、皆様と「実行可能な改善策は何か？」「対象となる商品群はどれか？」を検討した上で在庫圧縮効果を推算します。</p>
<ul class="modListCheck">
<li>「まずは定番品だけでも、発注点を適正に設定し直せば、在庫日数が〇〇日→〇日に！」</li>
<li>現実的に到達可能な目標と、そこに至る方法を明確化</li>
</ul>
</div>
<div class="lpSimulationBlock__right">
<div class="colorBox"><a href="../wp/wp-content/themes/fwsol/assets/img/lp/img_simulation.jpg"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_simulation.jpg" alt="" /></a></div>
<p class="underColorbox">クリックすると拡大します。</p>
</div>
</div>
</div>
```
*/
.lpSimulation {
  margin-top: 170px;
}
.lpSimulationBlock {
  display: flex;
  padding: 45px 50px 80px;
  border: 1px solid #c8c8c8;
  background: url(../img/lp/arrow_up_down.png) no-repeat center 90%;
  @include sphoneP {
    padding: 20px 20px 95px;
    display: block;
    background-position: center 98%;
    text-align: center;
  }
  &__left {
    @include sphoneP {
      text-align: left;
    }
  }
  &__right {
    margin-left: 58px;
    flex: none;
    @include sphoneP {
      display: inline-block;
      margin: 0 auto;
    }
  }
}

/*
#styleguide
colorGuide

```
<p>赤系</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_red">#aa1400</div>
</div>
<p>青系</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_blue01">#096383</div>
  <div class="modColorBlock__item modColorBlock_blue02">#103459</div>
</div>
<p>背景色等</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_bg01">#1e1e1e</div>
  <div class="modColorBlock__item modColorBlock_bg02">#646464</div>
  <div class="modColorBlock__item modColorBlock_bg03">#c8c8c8</div>
  <div class="modColorBlock__item modColorBlock_bg04">#e6e6e6</div>
  <div class="modColorBlock__item modColorBlock_bg05">#f5f5f5</div>
</div>
<p>標準テキスト</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_text">#444444</div>
</div>
```
*/
.modColorBlock {
  display: flex;
  &__item {
    width: 75px;
    height: 75px;
    text-align: center;
    margin-bottom: 25px;
  }
  &_red {
    background: $colorRed;
    color: #fff;
  }
  &_blue01 {
    background: #096383;
    color: #fff;
  }
  &_blue02 {
    background: #103459;
    color: #fff;
  }
  &_bg01 {
    background: #1e1e1e;
    color: #fff;
  }
  &_bg02 {
    background: #646464;
    color: #fff;
  }
  &_bg03 {
    background: #c8c8c8;
    color: #fff;
  }
  &_bg04 {
    background: #e6e6e6;
  }
  &_bg05 {
    background: #f5f5f5;
  }
  &_text {
    background: #444;
    color: #fff;
  }
}