.columnList {
  margin-bottom: 25px;
  > .modTitle01 {
    margin-top: 0;
  }
  .modPager {
    margin-top: 60px;
  }
}

.columnListItem {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: flex-start;
  @include sphoneP {
    flex-direction: column;
  }
  a {
    text-decoration: none;
    &:hover {
      color: $colorRed;
    }
  }
  &__thumb {
    margin-right: 25px;
    flex: none;
    width: 222px;
    display: flex;
    a {
      display: flex;
      min-height: 164px;
      width: 100%;
      justify-content: center;
      align-items: center;
      background-color: #ebebeb;
      img {
        flex: none;
      }
    }
    @include sphoneP {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
  &__right {

  }
  &__title {
    @include fz(20);
    font-weight: bold;
    margin: 5px 0 10px;
    letter-spacing: 0.05em;
  }
  &__excerpt {
    @include fz(16);
  }
}

.columnCatBlock {
  @include sphoneP {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__date {
    @include fz(14);
    color: #646464;
    letter-spacing: 0.04em;
    margin-right: 14px;
    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      background: url(../img/low/icon_clock.png) no-repeat center;
      background-size: contain;
      vertical-align: -3px;
      margin-right: 7px;
    }
  }
  &__cat {
    @include fz(14);
    line-height: 1.7;
    border: 1px solid $colorBlue2;
    color: $colorBlue2;
    padding: 0 15px;
    display: inline-block;
  }
}

.singlePost {
  > .modTitle01 {
    margin-top: 0;
  }
  .columnCatBlock {
    margin-bottom: 30px;
  }
}

.wpPost_single {
  > *:last-child {
    margin-bottom: 0;
  }
  padding-bottom: 55px;
  margin-bottom: 65px;
  border-bottom: 1px solid #e6e6e6;
}