@charset "utf-8";

// font
$baseFont: 14;
$baseLineHeight: 1.8;
$baseColor: #444;
$baseLinkColor: #333;
$mainColor: #333;

// color
$colorStrong: #E33E41; //強調系（エラーなど）
$colorBlue: #103459;
$colorBlue2: #096383;
$colorGreen: #1ABC9C;
$colorRed: #aa1400;

$colorGray: #e6e6e6;
$colorGray64: #646464;
$colorGrayf5: #f5f5f5;

// brake-point
$bpSphoneP: 750px;
$bpTabletP: 768px;
$bpTabletL: 992px;
$baseWidthWide: 1200px;

// container-size
$container-sm: 750px;
$container-md: 970px;
$container-lg: 1330px;

// container-padding
$containerPadding: 15;

// font-family
// $baseFontFamily : -apple-system,BlinkMacSystemFont,"メイリオ",Meiryo,"Hiragino Kaku Gothic ProN",sans-serif;
$baseFontFamily : -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI','Noto Sans Japanese', 'Open Sans', 'Hiragino Kaku Gothic ProN', meiryo, sans-serif;
$fontAlpha : Helvetica, Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
$fontMincho : "游明朝体", "Yu Mincho",'ヒラギノ明朝 Pro W3','Hiragino Mincho Pro',"メイリオ", Meiryo, serif;

// font-weight
$regular: 400;
$bold: 700;