@charset "utf-8";
/*
#overview
modBtn
*/

/*
#styleguide
modBtn01
modBtn02
modBtn03
modBtn04

```
<div class="modBtn01"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_ptnConcept"><a href="#">サンプルテキスト</a></div>
<div class="modBtn02"><a href="#">サンプルテキスト</a></div>
<div class="modBtn03"><a href="#">サンプルテキスト</a></div>
<div class="modBtn04"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_blue modBtn01_large"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_red modBtn01_large"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01  modBtn01_large"><a href="#">サンプルテキスト</a></div>
```
*/
.modBtn01 {
	margin-bottom: 45px;
	a {
		display: inline-block;
		text-align: center;
		@include fz(16);
		line-height: 1.2;
		padding: 30px 60px;
		min-width: 370px;
		text-align: center;
		text-decoration: none;
		position: relative;
		color: #fff;
		background-color: #1e1e1e;
		@include sphoneP {
			min-width: initial;
			width: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			background: url(../img/arrow_white.png) no-repeat;
			background-size: contain;
			right: 24px;
			top: 50%;
			width: 27px;
			height: 6px;
			margin-top: -3px;
		}
	}
	&_ptnConcept {
		a {
			width: 100%;
			max-width: 428px;
			@include fz(20);
			padding: 27px 60px;
		}
	}
	&_red {
		a {
			background-color: $colorRed;
		}
	}
	&_blue {
		a {
			background-color: $colorBlue;
		}
	}
	&_large {
		a {
			width: 100%;
			max-width: 800px;
			@include fz(24);
		}
	}
	&_middle {
		a {
			width: 100%;
			max-width: 520px;
			@include fz(24);
		}
	}
}
.modBtn02 {
	margin-bottom: 45px;
	a {
		display: inline-block;
		text-align: center;
		@include fz(16);
		line-height: 1.2;
		padding: 30px 60px;
		min-width: 370px;
		text-align: center;
		text-decoration: none;
		position: relative;
		color: #444;
		background-color: #c8c8c8;
		@include sphoneP {
			min-width: initial;
			width: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			background: url(../img/arrow_black.png) no-repeat;
			background-size: contain;
			right: 24px;
			top: 50%;
			width: 27px;
			height: 6px;
			margin-top: -3px;
		}
	}
	&_large {
		a {
			width: 100%;
			max-width: 800px;
			@include fz(24);
		}
	}
	&_middle {
		a {
			width: 100%;
			max-width: 520px;
			@include fz(24);
		}
	}
}
.modBtn03 {
	margin-bottom: 45px;
	a {
		display: inline-block;
		text-align: center;
		@include fz(16);
		line-height: 1.2;
		padding: 30px 60px;
		min-width: 370px;
		text-align: center;
		text-decoration: none;
		position: relative;
		color: #444;
		border: 1px solid #444;
		@include sphoneP {
			min-width: initial;
			width: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			background: url(../img/arrow_black.png) no-repeat;
			background-size: contain;
			right: 24px;
			top: 50%;
			width: 27px;
			height: 6px;
			margin-top: -3px;
		}
	}
	&_large {
		a {
			width: 100%;
			max-width: 800px;
			@include fz(24);
		}
	}
	&_middle {
		a {
			width: 100%;
			max-width: 520px;
			@include fz(24);
		}
	}
}
.modBtn04 {
	margin-bottom: 45px;
	a {
		display: inline-block;
		text-align: center;
		@include fz(16);
		line-height: 1.2;
		padding: 30px 60px;
		min-width: 370px;
		text-align: center;
		text-decoration: none;
		position: relative;
		color: #444;
		border: 1px solid $colorRed;
		@include sphoneP {
			min-width: initial;
			width: 100%;
		}
		&:after {
			content: "";
			position: absolute;
			background: url(../img/arrow_black.png) no-repeat;
			background-size: contain;
			right: 24px;
			top: 50%;
			width: 27px;
			height: 6px;
			margin-top: -3px;
		}
	}
}

/*
トップページ用
*/
.modBtnLink {
  margin-top: 50px;
  text-align: center;
  @include sphoneP {
    margin-top: 28px;
  }
  a {
    display: inline-block;
    background-color: #1e1e1e;
    color: #fff;
    font-size: 20px;
    width: 380px;
		padding: 28px 50px;
		line-height: 1.2;
    text-decoration: none;
    position: relative;
    @include sphoneP {
      width: 100%;
			font-size: 14px;
			padding: 19px 50px;
    }
    &:after {
      content: "";
      position: absolute;
      width: 27px;
      height: 5px;
      background: url(../img/arrow_white.png) no-repeat;
      background-size: contain;
      right: 17px;
      top: 50%;
      margin-top: -3px;
    }
  }
  &-row4 {
    display: flex;
    justify-content: space-between;
    a {
      width: 290px;
      font-size: 16px;
      padding-top: 28px;
    }
    @include sphoneP {
      flex-wrap: wrap;
      a {
        width: 100%;
				font-size: 14px;
				padding: 19px 50px;
        margin-bottom: 16px;
      }
    }
  }
}