.wpPost {
  .solutionBlock {
    padding: 16px 30px 30px;
    background-color: #f5f5f5;
    margin-top: 100px;
    > *:last-child {
      margin-bottom: 0;
    }
    &__heading {
      padding: 16px 0px 0px 52px;
      margin-bottom: 25px;
      border-bottom: 2px solid $colorRed;
      @include fz(24);
      font-weight: bold;
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        background: url(../img/low/icon_solution.png) no-repeat;
        background-size: contain;
        width: 45px;
        height: 50px;
        margin-right: 10px;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }
  .beforeIcon__hint {
    margin-left: 50px;
    position: relative;
    border-bottom: 2px solid $colorRed;
    @include fz(24);
    font-weight: bold;
    margin-bottom: 40px;
    &:before {
      content: "";
      position: absolute;
      top: 0px;
      left: -50px;
      background: url(../img/low/icon_hint.png) no-repeat;
      background-size: contain;
      width: 39px;
      height: 56px;
    }
    + .modTitle03 {
      margin-top: 0;
    }
  }
  .contactBlock {
    .modTitle02_center {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 30px;
      text-align: center;
    }
    .modBtnLink {
      margin-top: 30px;
      a {
        width: 458px;
        @include fz(16);
        padding-top: 27px;
        @include sphoneP {
          width: 100%;
          @include fz(13);
          padding-top: 17px;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .recentPost__client {
    color: #646464;
    @include fz(14);
    display: inline-block;
    border: 1px solid #646464;
    padding: 9px 10px;
    line-height: 1;
  }
  .contactBlock {
    margin-top: 100px;
  }

  .conceptTag {
    display: flex;
    margin-top: 70px;
    margin-bottom: 25px;
    dt {
      padding: 6px 18px;
      color: white;
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-weight: bold;
    }
    dd {
      padding: 6px 18px;
      border: 1px solid #000;
      display: flex;
      align-items: center;
    }
    &.theme_blue {
      dt {
        background-color: $colorBlue;
      }
      dd {
        color: $colorBlue;
        border-color: $colorBlue;
      }
    }
    &.theme_red {
      dt {
        background-color: $colorRed;
      }
      dd {
        color: $colorRed;
        border-color: $colorRed;
      }
    }
  }

  .partnerBlock {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -40px;
    &__item {
      width: 438px;
      min-height: 320px;
      border: 1px solid #e6e6e6;
      margin-bottom: 40px;
      a {
        display: block;
        padding: 30px 30px 25px;
        height: 100%;
        text-decoration: none;
      }
    }
    &__img {
      display: block;
      text-align: center;
      margin-bottom: 25px;
    }
    &__name {
      display: inline-block;
      margin-bottom: 10px;
      font-weight: bold;
      position: relative;
      padding-right: 20px;
      @include fz(16);
      &:after {
        width: 9px;
        height: 16px;
        content: "";
        position: absolute;
        top: 4px;
        right: 0px;
        background: url(../img/arrow_red.png) no-repeat center;
        background-size: contain;
      }
    }
    &__text {
      display: block;
      @include fz(14);
    }
  }

  .recruitInfo {
    padding: 20px 24px;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    dt {
      @include fz(18);
    }
    dd {
      @include fz(16);
    }
  }
}

.productLogoBlock {
  display: flex;
  align-items: flex-end;
  @include sphoneP {
    flex-direction: column;
    align-items: flex-start;
  }
  &__logo {
    margin-right: 17px;
    @include sphoneP {
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  &__text {
    @include fz(24);
    font-weight: bold;
    line-height: 1.2;
  }
}
.productRowBlock {
  padding: 45px 30px 50px;
  display: flex;
  justify-content: space-between;
  background-color: $colorGrayf5;
  margin-bottom: 50px;
  @include sphoneP {
    flex-direction: column;
  }
  &__left {
    > * {
      margin-top: 0 !important;
    }
  }
  &__right {
    flex: none;
    margin-left: 20px;
    @include sphoneP {
      margin-left: 0;
    }
  }
}
.productFirstImg {
  +.modTitle01 {
    margin-top: 45px;
  }
}
.productSec {
  > *:first-child {
    margin-top: 100px !important;
  }
}