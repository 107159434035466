@charset "utf-8";
@import url('//fonts.googleapis.com/earlyaccess/notosansjapanese.css');
@import url('//fonts.googleapis.com/css?family=Open+Sans:400,700');

/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td
{
	margin: 0;
	padding: 0;
}
address,
em
{
	font-style: normal;
}
strong,
th
{
	font-weight: normal;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}
th
{
	text-align: left;
}
hr,
legend
{
	display: none;
}
h1,
h2,
h3,
h4,
h5,
h6
{
	font-size: 100%;
	font-weight: normal;
}
img,
fieldset
{
	border: 0;
}
img
{
	border:0;
	vertical-align:top;
	max-width:100%;
	height:auto;
}

li
{
	list-style-type: none;
}
input[type="submit"],button,label,select{
	cursor:pointer;
}

input[type="submit"],button{
	&:hover{
		
	}
}

.clearfix{
	@include clearfix;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main {
	display: block;
}

/* link
------------------------------------------------------------*/
a { 
	color: $baseLinkColor;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}
a,button{
	&:hover {
		opacity:0.8;
	}
}

/* Fonts
------------------------------------------------------------*/

html{ font-size: 62.5%; }/* 10px base */

body{
	-webkit-text-size-adjust: 100%;
	word-wrap : break-word;
	overflow-wrap : break-word;
	font-family:$baseFontFamily;
	line-height:$baseLineHeight;
	color: $baseColor;
	@include fz;
	//IE11以下は別のフォントを指定する場合
	// &.IEUnder{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
	min-width: 1330px;
	@include sphoneP {
		min-width: 1px;
	}
}

input,button,textarea,select
{
	line-height:$baseLineHeight;
	color: $baseColor;
	font-family:$baseFontFamily;
	//IE11以下は別のフォントを指定する場合
	// .IEUnder &{
	// 	font-family: "メイリオ",Verdana, "Hiragino Kaku Gothic ProN",  Meiryo, sans-serif;
	// }
}


/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select{
	padding:10px;
	border-radius:5px;
	border:solid 1px #aaa;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="tel"].error,
input[type="email"].error,
select.error{
	background-color:rgba(255,242,242,1.00);
	border:solid 2px $colorStrong;
}



/* radio
-------------*/
input[type="radio"] {
	display: none;
}

input[type="radio"] + label,
label.radioStyle {
	position: relative;
	display: inline-block;
	padding: 3px 40px 3px 24px;
	cursor: pointer;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after,
label.radioStyle:after,
label.radioStyle:before {
	content: '';
	top: 50%;
	position: absolute;
	border-radius: 100%;
	transition: all .2s;
}

input[type="radio"] + label::before,
label.radioStyle:before {
	width: 16px;
	height: 16px;
	margin-top: -9px;
	left: 0;
	background: #EEE;
	border: 1px solid #ccc;
}
input[type="radio"].error + label::before{
	border: 1px solid $colorStrong;
	background-color:rgba(255,242,242,1.00);
}
input[type="radio"] + label:hover::before,
label.radioStyle:hover:before {
	background: #FEFEFE;
}
input[type="radio"] + label::after,
label.radioStyle:after {
	opacity: 0;
	left: 3px;
	width: 10px;
	height: 10px;
	margin-top: -6px;
	background: $colorBlue;
	transform: scale(0.5);
}
input[type="radio"]:checked + label::before,
label.radioStyleChecked:before {
	background: #fff;
	border: 1px solid $colorBlue;
}
input[type="radio"]:checked + label::after,
label.radioStyleChecked:after {
	opacity: 1;
	transform: scale(1);
}


/* checkbox
-------------*/
input[type="checkbox"] {
	display: none;
}
input[type="checkbox"] + label,
label.addStyle {
	position: relative;
	display: inline-block;
	padding: 3px 10px 3px 25px;
	cursor: pointer;
	margin-right: 33px;
	@include sphoneP {
		width: 100%;
	}
}
label.addStyle:hover {
	opacity: 0.8;
}
input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after,
label.addStyle::before,
label.addStyle::after {
	position: absolute;
	content: '';
}
input[type="checkbox"] + label::before,
label.addStyle::before {
	top: 50%;
	left: 0;
	width: 18px;
	height: 18px;
	margin-top: -9px;
	background: #fff;
	border: 1px solid #646464;
	border-radius: 0px;
}
input[type="checkbox"] + label::after,
label.addStyle::after {
	opacity: 0;
	top: 50%;
	left: 3px;
	width: 12px;
	height: 6px;
	margin-top: -5px;
	border-left: 1px solid #6d6d6d;
	border-bottom: 1px solid #6d6d6d;
	transform: rotate(-45deg) scale(0.5);
}
input[type="checkbox"] + label:hover::before,
label.addStyle:hover::before {
	background: #fff;
}
input[type="checkbox"]:checked + label::before,
label.addStyleChecked::before {
	background: #fff;
	border: 1px solid $colorBlue;
}
input[type="checkbox"]:checked + label::after,
label.addStyleChecked::after {
	opacity: 1;
	transform: rotate(-45deg) scale(1);
}


