@charset "utf-8";
/* CSS Document */

.container {
	max-width:$container-lg;

	@include sphoneP{
		max-width: $container-sm;
	}
}

.pc-non {
	@include sphoneP_plus {
		display: none !important;
	}
}

.sp-non {
	@include sphoneP {
		display: none !important;
	}
}