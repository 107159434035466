.homeMv {
  height: 500px;
  background: url(../img/home/bg_mv2.jpg) no-repeat;
  background-size: cover;
  &__h1 {
    max-width: 1340px;
    padding-top: 126px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;
  }
  @include sphoneP {
    background-image: none;
    height: auto;
    margin-bottom: 150px;
    &__h1 {
      padding-top: 50px;
      padding-left: 0;
      padding-right: 0;
      width: 83%;
      margin: 0 auto;
    }
  }
}
.spMvWrap {
  @include sphoneP {
    background: url(../img/home/bg_mv2_sp.jpg) no-repeat;
    background-size: cover;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
}
.homeNews {
  display: flex;
  margin-top: -48px;
  position: relative;
  z-index: 3;
  width: 100%;
  margin-bottom: 100px;
  @include sphoneP {
    margin-top: 0;
    flex-direction: column;
    margin-bottom: 0;
  }
  &__title {
    width: 147px;
    background-color: rgba(4,44,58,0.8);
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    letter-spacing: 0.08em;
    @include fz(16);
    @include sphoneP {
      width: 100%;
      padding: 10px;
      text-align: left;
      @include fz(12);
      display: block;
    }
  }
  &__info {
    padding: 30px 50px;
    background-color: rgba(9,99,131,0.8);
    width: calc(100% - 147px);
    @include sphoneP {
      width: 100%;
      padding: 10px;
    }
  }
  &__text {
    @include fz(16);
    @include sphoneP {
      @include fz(12);
    }
    a {
      color: #fff;
      text-decoration: none;
      display: inline-block;
      padding-right: 50px;
      position: relative;
      &:after {
        content: "";
        background: url(../img/arrow_white.png) no-repeat;
        background-size: contain;
        width: 27px;
        height: 5px;
        display: inline-block;
        position: absolute;
        right: 0px;
        top: 50%;
        margin-top: -3px;
        @include sphoneP {
          width: 20px;
        }
      }
    }
  }
}

.homeSlider {
  .slick-slide img {
    margin: 0 auto;
  }
  .slick-slide {
    margin: 0 5px;
  }
  .overlay {
    opacity: 0.3;
  }
  .slick-center {
    &.overlay {
      opacity: 1;
    }
  }
  &:not(.slick-center) {
    -webkit-backface-visibility:hidden;
    backface-visibility:hidden;
  }
  .overlay1 {
    opacity: 1 !important;
  }
  .overlay2 {
    opacity: 0.3 !important;
  }
  .slick-prev {
    left: 50%;
    z-index: 5;
    margin-left: -572px;
    width: 24px;
    height: 38px;
    @include sphoneP {
      width: 15px;
      height: 30px;
      margin-left: -44%;
    }
    &:before {
      width: 100%;
      height: 100%;
      background: url(../img/home/prev.png) no-repeat;
      background-size: contain;
      text-indent: -9999px;
      display: block;
    }
  }
  .slick-next {
    right: 50%;
    z-index: 5;
    margin-right: -572px;
    width: 24px;
    height: 38px;
    @include sphoneP {
      width: 15px;
      height: 30px;
      margin-right: -44%;
    }
    &:before {
      width: 100%;
      height: 100%;
      background: url(../img/home/next.png) no-repeat;
      background-size: contain;
      text-indent: -9999px;
      display: block;
    }
  }
  .slick-dots {
    bottom: -40px;
    @include sphoneP {
      bottom: -30px;
    }
    li {
      margin: 0 1px;
      button:before {
        color: #333333;
      }
      &.slick-active button:before {
        color: $colorRed;
      }
    }
  }
  &.slick-slider {
    margin-bottom: 170px;
    @include sphoneP {
      margin-bottom: 70px;
    }
  }
  &.slick-dotted.slick-slider {
    margin-bottom: 170px;
    @include sphoneP {
      margin-bottom: 70px;
    }
  }
}
.homeSupply {
  padding-bottom: 185px;
  @include sphoneP {
    padding-bottom: 64px;
  }
}
.homeSolution {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
  margin-bottom: 150px;
  @include sphoneP {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 64px;
  }
}
.homeSubject {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
  margin-bottom: 150px;
  @include sphoneP {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 64px;
  }
}
.homeSubjectList {
  display: flex;
  justify-content: space-between;
  @include sphoneP {
    flex-direction: column;
  }
  &__item {
    width: 630px;
    background-color: #fff;
    @include sphoneP {
      width: 100%;
    }
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    padding: 45px;
    @include sphoneP {
      padding: 16px;
    }
  }
  &__thumb {
    @include sphoneP {
      width: 85px;
    }
  }
  &__link {
    width: 265px;
    @include sphoneP {
      width: calc(100% - 100px);
    }
  }
  li {
    @include fz(15);
    line-height: 2;
  }
  a {
    text-decoration: none;
    padding-right: 23px;
    display: inline-block;
    max-width: 100%;
    position: relative;
    &:hover {
      opacity: 1;
      color: $colorRed;
    }
    &:after {
      width: 7px;
      height: 13px;
      background: url(../img/home/arrow_red.png) no-repeat;
      background-size: contain;
      content: "";
      display: inline-block;
      position: absolute;
      right: 0px;
      top: 7px;
      @include sphoneP {
        width: 5px;
        top: 8px;
      }
    }
    @include sphoneP {
      @include fz(12);
      padding-left: 1.1em;
      padding-right: 0;
      &:after {
        left: 0px;
        right: auto;
      }
    }
  }
  &__title {
    padding: 8px;
    background-color: $colorRed;
    text-align: center;
    @include fz(18);
    color: #fff;
    @include sphoneP {
      @include fz(14);
      padding: 4px 8px;
    }
  }
}
.homeColumn {
  margin-bottom: 150px;
  @include sphoneP {
    margin-bottom: 64px;
  }
  &__bgArea {
    background: url(../img/home/bg_column.jpg) no-repeat center top;
    background-size: cover;
    height: 430px;
    padding-top: 64px;
    color: #fff;
    @include sphoneP {
      height: 296px;
      padding: 45px 20px 0;
      background-image: url(../img/home/bg_column_sp.jpg);
    }
  }
}

.homeColumnList {
  margin-top: -150px;
  display: flex;
  justify-content: space-between;
  @include sphoneP {
    flex-direction: column;
    margin-top: -48px;
  }
  &__item {
    width: 630px;
    box-shadow: -3px 2px 5px 3px rgba(0,0,0,0.1);
    position: relative;
    @include sphoneP {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  a {
    display: flex;
    padding: 30px 60px 30px 30px;
    justify-content: space-between;
    text-decoration: none;
    background-color: #fff;
    height: 100%;
    @include sphoneP {
      padding: 15px 40px 15px 15px;
    }
    &:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      width: 24px;
      height: 48px;
      margin-top: -24px;
      background: url(../img/home/arrow_column.png);
      background-size: contain;
    }
    &:hover {
      opacity: 0.9;
      &:after {
        opacity: 0.7;
      }
    }
  }
  &__info {
    width: 402px;
    @include sphoneP {
      width: calc(100% - 90px);
    }
  }
  &__tag {
    min-width: 124px;
    line-height: 32px;
    padding: 0 4px;
    @include fz(14);
    text-align: center;
    display: inline-block;
    border: 1px solid #1871b9;
    color: #1871b9;
    margin-bottom: 13px;
    @include sphoneP {
      min-width: 62px;
      line-height: 18px;
      @include fz(11);
      margin-bottom: 0;
    }
  }
  &__title {
    @include fz(18);
    margin-bottom: 7px;
    @include sphoneP {
      @include fz(15);
      margin-bottom: 0;
    }
  }
  &__text {
    @include fz(16);
    @include sphoneP {
      @include fz(13);
    }
  }
  &__thumb {
    width: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      flex: 100%;
    }
    @include sphoneP {
      width: 75px;
    }
  }
}

.homeInfo {
  margin-bottom: 160px;
  @include sphoneP {
    margin-bottom: 120px;
  }
}
.homeInfoList {
  border-top: 1px solid #e6e6e6;
  &__item {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    @include sphoneP {
      flex-wrap: wrap;
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      color: $colorRed;
    }
  }
  &__date {
    width: 150px;
    padding-top: 6px;
    @include sphoneP {
      width: 80px;
      @include fz(13);
      padding-top: 0;
    }
  }
  &__tag {
    width: 220px;
    @include sphoneP {
      width: calc(100% - 80px);
    }
    span {
      min-width: 150px;
      @include fz(14);
      line-height: 38px;
      text-align: center;
      display: inline-block;
      border: 1px solid #000;
      @include sphoneP {
        @include fz(11);
        line-height: 19px;
        min-width: 120px;
      }
      &.color-seminar {
        color: #aa1400;
        border-color: #aa1400;
      }
      &.color-products {
        color: #646464;
        border-color: #646464;
      }
      &.color-works {
        color: #103459;
        border-color: #103459;
      }
      &.color-news {
        color: #096383;
        border-color: #096383;
      }
    }
  }
  &__title {
    width: calc(100% - 400px);
    @include fz(16);
    padding-top: 5px;
    @include sphoneP {
      width: 100%;
      @include fz(13);
      line-height: 1.5;
    }
  }
}
