
@charset "utf-8";
/*
#overview
base
*/

/* Header
------------------------------------------------------------*/
.header {
	position: relative;
}
.headerInner {
	padding-top: 30px;
	@include sphoneP {
		padding-top: 12px;
		padding-bottom: 12px;
		position: relative;
	}
}
.headerTop {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@include sphoneP {
		padding-right: 58px;
		position: relative;
	}
}
.headerLeft {
	display: flex;
}
.headerDescription {
	@include fz(12);
	color: #828282;
	@include sphoneP {
		display: none;
	}
}
.logo {
	margin-right: 20px;
	@include sphoneP {
		margin-right: 0;
		width: 110px;
	}
}
.headerSubNavi {
	padding-top: 10px;
	@include sphoneP {
		padding-top: 2px;
	}
	&__list {
		display: flex;
	}
	li {
		margin-left: 30px;
		text-align: center;
		line-height: 1.6em;
		@include sphoneP {
			width: 23px;
		}
	}
	a {
		text-decoration: none;
		font-weight: bold;
		@include fz(14);
		letter-spacing: 0.01em;
	}
}
.spMenuBtn {
	display: none;
	@include sphoneP {
		padding: 7px;
		display: block;
		position: absolute;
		top: 15px;
		right: 5px;
		&__inner {
			position: relative;
			width: 18px;
			height: 14px;
		}
		span {
			height: 1px;
			width: 18px;
			display: inline-block;
			position: absolute;
			background-color: $colorRed;
			transition: .2s;
			&:first-child {
				top: 0px;
				left: 0px;
			}
			&:nth-child(2) {
				top: 50%;
				left: 0px;
			}
			&:last-child {
				bottom: 0px;
				left: 0px;
			}
		}
		&.is-active {
			span {
				&:first-child {
					top: 6px;
					left: 1px;
					transform: rotate(45deg);
				}
				&:nth-child(2) {
					top: 50%;
					left: 0px;
					display: none;
				}
				&:last-child {
					bottom: 7px;
					left: 1px;
					transform: rotate(-45deg);
				}
			}
		}
	}
}
.gNavi {
	margin-top: 46px;
	@include sphoneP {
		display: none;
	}
	&__list {
		display: flex;
		justify-content: space-between;
	}
	li {
		width: 16.67%;
		text-align: center;
		position: relative;
		line-height: 1;
		&:before {
			position: absolute;
			left: 0px;
			top: 1px;
			width: 2px;
			height: 13px;
			content: "";
			background-color: #1e1e1e;
		}
		&:last-child {
			&:after {
				position: absolute;
				right: 0px;
				top: 1px;
				width: 2px;
				height: 13px;
				content: "";
				background-color: #1e1e1e;
			}
		}
	}
	a {
		display: block;
		text-decoration: none;
		@include fz(16);
		padding-bottom: 25px;
		&:hover {
			color: $colorRed;
			opacity: 1;
		}
	}
	.is-hover {
		a {
			color: $colorRed;
		}
	}
	.is-current {
		a {
			padding-bottom: 19px;
			border-bottom: 6px solid $colorRed;
		}
	}
}

.searchArea {
	position: absolute;
	top: 106px;
	left: 0px;
	width: 100%;
	background-color: #fff;
	border-top: 1px solid #e6e6e6;
	padding-top: 18px;
	padding-bottom: 18px;
	display: none;
	z-index: 10;
	@include sphoneP {
		top: 58px;
	}
	&__inner {
		display: flex;
		justify-content: space-between;
		@include sphoneP {
			flex-direction: column;
		}
	}
	&__left {
		width: 1105px;
		@include sphoneP {
			width: 100%;
			margin-bottom: 15px;
		}
	}
	&__input {
		width: 100%;
		height: 56px;
		border-radius: 0px !important;
		@include fz(16);
		@include sphoneP {
			@include fz(14);
		}
	}
	&__submit {
		-webkit-appearance: none;
		border-style: none;
		width: 170px;
		height: 56px;
		background: #1e1e1e;
		@include fz(18);
		color: #fff;
		@include sphoneP {
			width: 100%;
			height: 45px;
			@include fz(14);
			margin-bottom: 20px;
			background: #1e1e1e url(../img/arrow_white.png) no-repeat 96% 50%;
			background-size: 17px auto;
		}
	}
	&__close {
		@include fz(12);
		font-weight: bold;
		text-align: center;
		&:before {
			content: "";
			display: inline-block;
			width: 8px;
			height: 8px;
			background: url(../img/icon_close.png) no-repeat;
			background-size: contain;
			margin-right: 5px;
		}
	}
}
.subNavi {
	position: absolute;
	top: 100%;
	padding: 45px 60px 60px;
	background-color: rgba(0,0,0,0.9);
	color: #fff;
	display: flex;
	opacity: 0;
	visibility: hidden;
	transition: .2s;
	z-index: 10;
	@include sphoneP {
		display: none;
	}
	&.is-show {
		opacity: 1;
		visibility: visible;
	}
	&__column {
		margin-right: 100px;
	}
	&__tag {
		padding-left: 45px;
		position: relative;
		@include fz(16);
		margin-bottom: 15px;
		&:before {
			position: absolute;
			width: 36px;
			height: 2px;
			top: 12px;
			left: 0px;
			background-color: $colorRed;
			content: "";
		}
	}
	&__link {
		padding-left: 45px;
		li {
			line-height: 1.4;
			@include fz(13);
			margin-bottom: 5px;
		}
		a {
			padding-right: 17px;
			position: relative;
			display: inline-block;
			&:after {
				content: "";
				width: 5px;
				height: 9px;
				background: url(../img/arrow_red.png) no-repeat;
				display: inline-block;
				background-size: contain;
				top: 4px;
				right: 0px;
				position: absolute;
			}
		}
	}
	&__row {
		width: 480px;
		.subNavi__link {
			padding-left: 0;
			display: flex;
			flex-wrap: wrap;
			li {
				width: 240px;
				padding-right: 30px;
			}
		}
	}
	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			color: $colorRed;
			opacity: 1;
		}
	}
}
.spNav {
	@include sphoneP_plus {
		display: none;
	}
	position: absolute;
	top: 100%;
	left: 100%;
	width: 100%;
	z-index: 100;
	transition: .2s;
	&.is-active {
		left: 0px;
		box-shadow: 0px 4px 4px 1px rgba(0,0,0,0.1);
	}
	&__list {
		border-top: 1px solid #e6e6e6;
		> li {
			border-bottom: 1px solid #e6e6e6;
			position: relative;
			> a {
				display: block;
				padding: 15px 24px;
				background-color: #f5f5f5;
				text-decoration: none;
				@include fz(14);
				line-height: 1.2;
				position: relative;
				&:after {
					position: absolute;
					top: 50%;
					right: 23px;
					height: 12px;
					width: 7px;
					margin-top: -6px;
					content: "";
					background: url(../img/arrow_red.png) no-repeat;
					background-size: contain;
				}
			}
		}
	}
	.spSubNav {
		display: none;
		li {
			border-bottom: 1px solid #c8c8c8;
			&:last-child {
				border-bottom-style: none;
			}
			a {
				background-color: #e6e6e6;
				@include fz(12);
				line-height: 1.2;
				padding: 12px 24px 12px 37px;
				display: block;
				text-decoration: none;
				position: relative;
				&:after {
					position: absolute;
					top: 50%;
					right: 26px;
					height: 12px;
					width: 7px;
					margin-top: -6px;
					content: "";
					background: url(../img/arrow_red.png) no-repeat;
					background-size: contain;
				}
			}
		}
	}
}
.navOpen {
	width: 30px;
	height: 30px;
	background: url(../img/icon_plus.png) no-repeat center;
	background-size: 12px;
	position: absolute;
	top: 23px;
	margin-top: -15px;
	right: 12px;
	&.is-close {
		background-image: url(../img/icon_minus.png);
		background-position: center;
	}
}
.spHasChild {
	> a {
		&:after {
			display: none;
		}
	}
}

.contents {
	padding-bottom: 160px;
	@include sphoneP {
		padding-bottom: 120px;
	}
	&_home {
		padding-bottom: 0px;
		@include sphoneP {
			padding-bottom: 0px;
		}
	}
}
#wrapper {
	@include sphoneP {
		overflow: hidden;
	}
}


/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/

/* Container
------------------------------------------------------------*/
.container {
	margin-right: auto;
	margin-left: auto;
	padding-right: ($containerPadding)+px;
	padding-left:  ($containerPadding)+px;
	@include sphoneP {
		padding-right: 20px;
		padding-left: 20px;
		&-header {
			padding-right: 12px;
			padding-left: 12px;
		}
	}
}


/* Fotter
------------------------------------------------------------*/

#footer { }
.pageTop {
	text-align: center;
	margin-bottom: 30px;
	a {
		color: $colorRed;
		text-decoration: none;
		@include fz(12);
		padding-top: 30px;
		background: url(../img/gotop.png) no-repeat center top;
		background-size: 20px 23px;
		display: inline-block;
		@include openSans();
	}
	@include sphoneP {
		margin-bottom: 18px;
	}
}
.footerInner {
	background-color: #1e1e1e;
	color: #fff;
	padding-top: 70px;
	padding-bottom: 45px;
	@include sphoneP {
		padding-top: 32px;
		padding-bottom: 15px;
	}
	a {
		color: #fff;
		text-decoration: none;
		&:hover {
			opacity: 1;
			color: $colorRed;
		}
	}
}
.footerTop {
	display: flex;
	justify-content: space-between;
	margin-bottom: 60px;
	@include sphoneP {
		margin-bottom: 18px;
		flex-direction: column;
	}
	&Contact {
		a {
			text-align: center;
			display: inline-block;
			background-color: #646464;
			color: #fff;
			@include fz(16);
			width: 300px;
			height: 64px;
			padding-top: 19px;
			text-decoration: none;
			position: relative;
			&:after {
				content: "";
				position: absolute;
				width: 27px;
				height: 5px;
				background: url(../img/arrow_white.png) no-repeat;
				background-size: contain;
				right: 17px;
				top: 50%;
				margin-top: -3px;
			}
		}
		@include sphoneP {
			a {
				width: 100%;
				@include fz(14);
				height: 60px;
				padding-top: 17px;
			}
		}
	}
	&Left {
		@include sphoneP {
			display: flex;
			justify-content: space-between;
			margin-bottom: 20px;
		}
	}
	&__logo {
		margin-bottom: 25px;
		img {
			border: 2px solid #fff;
			width: 144px;
		}
		@include sphoneP {
			width: 111px;
		}
	}
	&__address {
		display: block;
		line-height: 2.1;
		@include fz(13);
		@include sphoneP {
			width: calc(100% - 150px);
			@include fz(11);
		}
	}
}
.footerNav {
	display: flex;
	justify-content: space-between;
	padding-right: 70px;
	@include sphoneP {
		display: none;
	}
	&__tag {
		@include fz(16);
		padding-left: 48px;
		position: relative;
		line-height: 1;
		margin-bottom: 25px;
		&:before {
			width: 26px;
			height: 2px;
			content: "";
			position: absolute;
			top: 6px;
			left: 0px;
			background-color: $colorRed;
		}
	}
	&__list {
		&_clm2 {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 50%;
			}
		}
		li {
			padding-left: 62px;
			position: relative;
			margin-bottom: 17px;
			@include fz(12);
			line-height: 1.3;
			&:after {
				left: 48px;
				top: 3px;
				content: "";
				width: 6px;
				height: 6px;
				background-color: $colorRed;
				position: absolute;
			}
		}
	}
}
.footerNavListWrapper {
  // display: flex;
  // justify-content: space-between;
  width: 505px;
}
.footerBottom {
	display: flex;
	justify-content: space-between;
	margin-top: 115px;
	@include sphoneP {
		margin-top: 30px;
		flex-direction: column;
	}
	&SubNav {
		flex: 1;
		&__list {
			@include sphoneP {
				text-align: center;
				margin-bottom: 30px;
			}
			li {
				margin-right: 30px;
				display: inline-block;
				@include fz(12);
				@include sphoneP {
					margin: 0 10px;
				}
			}
		}
	}
}
.copyright {
	@include fz(13);
	width: 28%;
	text-align: right;
	white-space: nowrap;
	@include sphoneP {
		text-align: center;
		width: 100%;
	}
}
.spFooterNav {
	display: none;
	@include sphoneP {
		display: block;
		li {
			border-bottom: 1px solid #a8a2a1;
		}
		a {
			padding: 15px 0;
			display: block;
			position: relative;
			&:after {
				content: "";
				background: url(../img/arrow_red.png) no-repeat;
				background-size: contain;
				width: 7px;
				height: 14px;
				position: absolute;
				right: 0px;
				top: 50%;
				margin-top: -7px;
			}
		}
	}
}

/* Page Common
------------------------------------------------------------*/
.breadCrumb {
	background-color: #f5f5f5;
	padding: 10px 0;
	margin-bottom: 80px;
	@include fz(13);
	@include sphoneP {
		padding: 10px 20px;
		@include fz(11);
	}
	&__list {
		width: 1300px;
		margin: 0 auto;
		@include sphoneP {
			width: 100%;
		}
	}
	a {
		color: #828282;
	}
	span {
		display: inline;
		&.current-item {
			font-weight: bold;
		}
	}
	span:not(:last-child) {
		margin-right: 27px;
		position: relative;
		&:after {
			content: ">";
			@include fz(16);
			position: absolute;
			right: -18px;
			top: -6px;
			@include sphoneP {
				top: -7px;
			}
		}
	}
	.home {
		a {
			text-decoration: none;
			text-indent: 100%;
			overflow: hidden;
			width: 15px;
			height: 14px;
			display: inline-block;
			background: url(../img/icon_home.png) no-repeat;
			background-size: contain;
			position: relative;
			top: 2px;
			@include sphoneP {
				top: 2px;
			}
		}
	}
}
.layoutClm1 {
	width: 1300px;
	margin: 0 auto;
	@include sphoneP {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}
}
.layoutClm2 {
	display: flex;
	justify-content: space-between;
	width: 1300px;
	margin: 0 auto;
	@include sphoneP {
		width: 100%;
		padding-left: 20px;
		padding-right: 20px;
		flex-direction: column;
	}
}
.contents_clm2 {
	width: 916px;
	@include sphoneP {
		width: 100%;
	}
}
.side {
	width: 326px;
	padding-bottom: 160px;
	@include sphoneP {
		padding: 30px 20px 64px;
		width: 100vw;
		margin-left: -20px;
		border-top: 2px solid #f5f5f5;
	}
}
.sideBlock {
	&__title {
		border-top: 2px solid $colorRed;
		background-color: #1e1e1e;
		color: #fff;
		@include fz(24);
		font-weight: bold;
		@include sphoneP {
			@include fz(20);
			line-height: 1.6;
		}
		&_nolink {
			padding: 17px 25px;
		}
		a {
			padding: 17px 25px;
			display: block;
			color: inherit;
			text-decoration: none;
		}
	}
	&:not(:first-child) {
		margin-top: 40px;
	}
}
.sideNav {
	padding: 15px 25px;
	background-color: #fafafa;
	border-bottom: 2px solid #c8c8c8;
	&:empty {
		padding: 0;
		border-style: none;
	}
	> li {
		padding: 6px 0;
		position: relative;
		&:not(:last-child) {
			border-bottom: 1px solid #e4e4e4;
		}
		&:after {
			width: 7px;
			height: 13px;
			background: url(../img/arrow_red.png) no-repeat;
			background-size: contain;
			content: "";
			position: absolute;
			right: 0px;
			top: 14px;
		}
		> a {
			font-size: 16px;
			display: block;
			text-decoration: none;
			padding-right: 15px;
			&:hover {
				color: $colorRed;
				opacity: 1;
			}
		}
	}
	.current_page_ancestor {
		> a {
			font-weight: bold;
		}
	}
	.current_page_item {
		> a {
			font-weight: bold;
		}
	}
}
.sideNav .children {
	padding-left: 14px;
	li {
		padding-top: 10px;
	}
	a {
		text-decoration: none;
		display: block;
		&:hover {
			color: $colorRed;
			opacity: 1;
		}
	}
}