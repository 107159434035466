.lpContainer {
  max-width: 1300px;
  margin: 0 auto;
  @include sphoneP {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.lpHeader {
  padding-top: 33px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  @include sphoneP {
    padding-top: 10px;
  }
}

.lpIntro {
  padding-bottom: 115px;
}

.lpBeforeBtn {
  text-align: center;
  @include fz(17);
  margin-bottom: 30px;
  &_mt70 {
    margin-top: 70px;
  }
}
.lpGuideFlow {
  padding-top: 155px;
}
.lpText {
  @include fz(17);
  margin-bottom: 40px;
  &_mb20 {
    margin-bottom: 20px;
  }
}

.lpGuideTable {
  padding-top: 155px;
  padding-bottom: 150px;
  &__toMap {
    margin-left: 45px;
  }
}

.colorBox {
  a {
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      width: 47px;
      height: 47px;
      bottom: 0px;
      right: 0px;
      background: url(../img/lp/icon_zoom.png) no-repeat;
      background-size: contain;
    }
  }
}
.underColorbox {
  text-align: right;
  margin-top: 10px;
  @include fz(14);
}

.lpFeature {
  &__banner {
    margin-top: 60px;
    margin-bottom: 130px;
    text-align: center;
  }
  &__image {
    max-width: 800px;
    margin: 0 auto;
  }
}
.lpFeatureList {
  &__title {
    @include fz(20);
    font-weight: bold;
    margin-top: 100px;
  }
  &__text {
    @include fz(17);
  }
}

.lpSupportLargeText {
  margin-top: 70px;
  margin-bottom: 30px;
  @include fz(20);
  font-weight: bold;
  text-align: center;
}

.lpForm {
  max-width: 900px;
  margin: 130px auto 0;
  .contactTable {
    width: 100%;
    margin-bottom: 50px;
    th {
      width: 195px;
      @include fz(16);
      line-height: 1.5;
      padding: 25px 20px 25px 0;
      border-top: 1px solid #e6e6e6;
      @include sphoneP {
        border-style: none;
      }
    }
    td {
      line-height: 1.5;
      padding: 15px 0;
      @include fz(16);
      border-top: 1px solid #e6e6e6;
    }
    .required {
      color: $colorRed;
      @include fz(14);
      margin-left: 8px;
      display: inline-block;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      border: 1px solid #e6e6e6;
      border-radius: 0px;
      width: 100%;
      @include fz(16);
      padding-left: 15px;
      &::-webkit-input-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: #c8c8c8;
      }
    }
    textarea {
      width: 100%;
      padding-left: 15px;
      @include fz(16);
      margin-top: 15px;
    }
    @include sphoneP {
      margin-bottom: 30px;
      tbody,
      tr,
      th,
      td {
        display: block;
        width: 100%;
      }
      th {
        padding-bottom: 0;
      }
    }
  }
}

.clearBtn {
  -webkit-appearance: none;
  border-style: none;
  background-color: #c8c8c8;
  padding: 30px 10px;
  max-width: 240px;
  width: 100%;
  display: inline-block;
  @include fz(16);
  margin-right: 30px;
  &:hover {
    opacity: 0.8;
  }
  @include sphoneP {
    max-width: initial;
    margin-bottom: 5px;
  }
}

.lpFooter {
  padding: 120px 0 40px;
  text-align: center;
  @include sphoneP {
    padding-left: 20px;
    padding-right: 20px;
  }
}