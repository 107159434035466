@charset "utf-8";
/*
#overview
modList
*/

/*
#styleguide
modList

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ul class="modListNormal modListNormal_large">
    <li>font-size:18px;</li>
    <li>font-size:18px;</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modListCheck">
    <li>リスト</li>
    <li>リスト</li>
</ul>
```
*/

.modListNormal {
	margin-bottom: 35px;
	li {
		@include fz(16);
		padding-left: 15px;
		line-height: 1.8;
		margin-bottom: 8px;
		position: relative;
		&:before {
			content: "";
			position: absolute;
			top: 12px;
			left: 0px;
			width: 4px;
			height: 4px;
			background-color: #1e1e1e;
		}
	}
}
.modListNormal_large {
	margin-top: 20px;
	margin-bottom: 20px;
	li {
		@include fz(18);
	}
}
.modListCheck {
	margin-top: 35px;
	margin-bottom: 35px;
	li {
		@include fz(18);
		margin-bottom: 8px;
		padding-left: 45px;
		position: relative;
		line-height: 1.8;
		&:last-child {
			margin-bottom: 0;
		}
		&:before {
			content: "";
			width: 23px;
			height: 18px;
			background: url(../img/low/icon_check.png) no-repeat;
			background-size: contain;
			position: absolute;
			top: 7px;
			left: 0px;
		}
	}
}

ol.modListNormal {
	li {
		list-style:decimal;
		margin-left: 1em;
		&:before {
			display: none;
		}
	}
}

/* indent */
.modIndent01 li {
	text-indent: -1em;
	margin: 0 0 0 1em;
}

.modIndent02 li {
	text-indent: -2.5em;
	margin: 0 0 0 2.5em;
}

/*
#styleguide
modListTag

```
<dl class="modListTag theme_blue">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag theme_red">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
```
*/
.modListTag {
	display: flex;
	margin-top: 70px;
	margin-bottom: 25px;
	@include sphoneP {
		flex-direction: column;
	}
	dt {
		padding: 6px 18px;
		color: white;
		display: flex;
		align-items: center;
		white-space: nowrap;
		font-weight: bold;
	}
	dd {
		padding: 6px 18px;
		border: 1px solid #000;
		display: flex;
		align-items: center;
	}
	&.theme_blue {
		dt {
			background-color: $colorBlue;
		}
		dd {
			color: $colorBlue;
			border-color: $colorBlue;
		}
	}
	&.theme_red {
		dt {
			background-color: $colorRed;
		}
		dd {
			color: $colorRed;
			border-color: $colorRed;
		}
	}
}

/*
#styleguide
modListTag_large

```
<dl class="modListTag_large theme_blue">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag_large theme_red">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag_large theme_gray">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
```
*/
.modListTag_large {
	display: flex;
	margin-top: 100px;
	margin-bottom: 25px;
	line-height: 1.4;
	@include sphoneP {
		flex-direction: column;
	}
	dt {
		padding: 10px 18px;
		color: white;
		display: flex;
		align-items: center;
		white-space: nowrap;
		font-weight: bold;
		@include fz(14);
	}
	dd {
		padding: 10px 18px;
		border: 1px solid #000;
		display: flex;
		align-items: center;
		@include fz(14);
	}
	&.theme_blue {
		dt {
			background-color: $colorBlue;
		}
		dd {
			color: $colorBlue;
			border-color: $colorBlue;
		}
	}
	&.theme_red {
		dt {
			background-color: $colorRed;
		}
		dd {
			color: $colorRed;
			border-color: $colorRed;
		}
	}
	&.theme_gray {
		dt {
			background-color: $colorGray64;
		}
		dd {
			color: $colorGray64;
			border-color: $colorGray64;
		}
	}
}

/*
#styleguide
solutionLinkList

```
<ul class="solutionLinkList">
<li><a class="link" href="#"><span class="solutionLinkList__left"><span class="solutionLinkList__leftInner"><span class="solutionLinkList__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/logo_pilot.png" alt="" /></span><span class="solutionLinkList__title">出荷予測</span></span></span><span class="solutionLinkList__text">予測に連動した発注機能は<br />こちら</span></a></li>
<li><a class="link" href="#"><span class="solutionLinkList__left"><span class="solutionLinkList__leftInner"><span class="solutionLinkList__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/logo_conductor.png" alt="" /></span><span class="solutionLinkList__title">生産需要量計算</span></span></span><span class="solutionLinkList__text">テキストが入ります□テキストが入ります□テキストが入</span></a></li>
</ul>
```
*/

.solutionLinkList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  li {
		padding-left: 0;
    width: 448px;
    border: 1px solid #1e1e1e;
    margin-bottom: 25px;
    &:before {
      display: none;
    }
    a.link {
      display: flex;
      width: 100%;
      height: 100%;
      text-decoration: none;
      align-items: center;
      position: relative;
      &:after {
        content: "";
        background: url(../img/arrow_red_long.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        right: 14px;
        width: 27px;
        height: 6px;
        margin-top: -3px;
        @include sphoneP {
          width: 16px;
          right: 5px;
        }
      }
    }
  }
  &__left {
    width: 160px;
    background-color: #1e1e1e;
    color: #fff;
    flex: none;
    display: flex;
    padding: 3px 12px 5px;
    text-align: center;
    height: 100%;
    align-items: center;
    @include sphoneP {
      width: 130px;
    }
    &Inner {
      width: 100%;
    }
    span {
      margin: 0;
      @include fz(14);
      @include sphoneP {
        @include fz(12);
      }
    }
  }
  &__text {
    margin: 0;
    padding: 10px 56px 10px 20px;
    line-height: 1.6;
    letter-spacing: 0;
    @include sphoneP {
      font-size: 14px;
      padding: 10px 30px 10px 10px;
    }
  }
  &__img {
    padding-bottom: 5px;
    display: block;
    img {
      vertical-align: middle;
    }
  }
  &__title {
    display: block;
    border-top: 1px solid #646464;
    padding-top: 5px;
  }
}

/* フォーム
***************************************/
.modDlForm{
	margin:0 0 20px;
	
	dt{
		float:left;
		clear:both;
		width:16em;
		padding:33px 0 20px 63px;
		position:relative;
		
		@include tabletP{
			float:none;
			width:auto;
			padding:33px 0 0 63px;
		}
		&.required:before,
		&.any:before{
			position:absolute;
			left:0;
			top:33px;
			color:#FFF;
			@include fz(11);
			padding:2px 10px;
		}
		&.required:before{
			
			content:"必須";
			background:$colorStrong;
			
		}
		&.any:before{
			background:#999999;
			content:"任意";
		}
	}
	dd{
		border-bottom:solid 1px #ccc;
		padding:10px 0;
		padding-left:17em;
		
		@include tabletP{
			padding:10px 0;
		}
		.parts{
			padding:10px 0;
			textarea,
			input[type="text"],
			input[type="email"]
			{
				width:100%;
				
			}
			&.radio_inline,
			&.check_inline{
				>div{
					display:inline-block;
				}
			}
			&.name{
				display:flex;
				justify-content:space-between;
				>div{
					width:48.5%;
				}
				
			}
			&.post,&.tel{
				display:flex;
				.hyphen{
					padding:10px 10px 0 10px;
					
					@include sphoneP{
						padding:10px 5px 0 5px;
					}
				}
			}
			&.password{
				input{
					width:100%;
					max-width:300px;
					@include sphoneP{
						max-width:none;
					}
				}
				.text{
					padding:10px 0;
				}
			}
		}
	}
	
	.validationError{
		color:$colorStrong;
	}
}

/*
#styleguide
lpCheckList

```
<ul class="lpCheckList">
<li>在庫適正化・キャッシュフロー改善へむけた、具体的な方策が知りたい。</li>
<li>在庫の過剰・デッドストック化を合理的に防ぎたい。同時に、欠品発生を回避したい。</li>
<li>需給調整業務がExcel中心の個人依存になっている。現状から脱却し、誰もが効果的な需給調整が行える体制へと改革したい。</li>
</ul>
```
*/
.lpCheckList {
  margin-bottom: 80px;
  li {
    margin-bottom: 10px;
    padding: 20px 35px 20px 96px;
    background: #f5f5f5 url(../img/low/icon_check.png) no-repeat 38px 27px;
    background-size: 29px auto;
    @include fz(22);
    @include sphoneP {
      @include fz(17);
      padding: 15px 30px 15px 55px;
      background-size: 20px auto;
      background-position: 24px 22px;
    }
  }
}

/*
#styleguide
lpFeatureNavi

```
<ul class="lpFeatureNavi">
<li><a href="#">３つの特長</a></li>
<li><a href="#">機能のポイント</a></li>
<li><a href="#">ご導入事例</a></li>
</ul>
```
*/
.lpFeatureNavi {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 100px;
  @include sphoneP {
    flex-direction: column;
  }
  li {
    width: 32%;
    border: 1px solid #1e1e1e;
    @include sphoneP {
      width: auto;
      margin-bottom: 5px;
    }
    a {
      display: block;
      padding: 20px 40px;
      text-align: center;
      text-decoration: none;
      @include fz(20);
      font-weight: bold;
      position: relative;
      @include sphoneP {
        @include fz(17);
      }
      &:after {
        width: 27px;
        height: 5px;
        background: url(../img/arrow_black.png) no-repeat;
        background-size: contain;
        position: absolute;
        top: 36px;
        right: 10px;
        content: "";
        transform: rotate(90deg);
        z-index: 2;
      }
    }
  }
}

/*
#styleguide
lpSupportList

```
<div class="lpSupportList">
<dl class="lpSupportList__item">
<dt>01</dt>
<dd>「業務のあるべき姿（To-Beモデル）」を実装したパッケージ機能をベースに、見える形でお客様とご検討が出来ます。</dd>
</dl>
<dl class="lpSupportList__item">
<dt>02</dt>
<dd>パッケージとして充実した機能と完成度を保証でき、短期間での安定稼働をお約束できます。</dd>
</dl>
<dl class="lpSupportList__item">
<dt>03</dt>
<dd>在庫にまつわる業務分野を専門領域として、豊富な導入経験と知識を持ったプロジェクトメンバーがサポートします。</dd>
</dl>
</div>
```
*/
.lpSupportList {
  margin-top: 30px;
  &__item {
    border: 2px solid #c8c8c8;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    border-radius: 60px;
    margin-bottom: 20px;
    @include sphoneP {
      display: block;
      padding: 20px;
      border-radius: 30px;
    }
    dt {
      @include fz(50);
      line-height: 1.3;
      padding-right: 35px;
      margin-right: 27px;
      border-right: 3px solid $colorRed;
      color: $colorRed;
      font-weight: bold;
      flex: none;
      font-family: 'Open Sans', sans-serif;
      @include sphoneP {
        padding-right: 0;
        margin-right: 0;
        margin-bottom: 20px;
        border-right-style: none;
        border-bottom: 3px solid $colorRed;
        @include fz(31);
        display: inline-block;
        margin-bottom: 12px;
      }
    }
    dd {
      @include fz(18);
      @include sphoneP {
        @include fz(17);
      }
    }
  }
}