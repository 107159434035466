@charset "utf-8";

/*
#overview
other
*/

/*
#styleguide
modLinkArrow
modLinkWord
modLinkExcel
modLinkPower
modLinkBlank
modLinkPdf

```
<p class="modText">
	<a href="#" class="modLinkArrow">矢印付きリンク</a>
</p>
<p class="modText">
	<a href="#" class="modLinkWord">Wordリンクデザイン</a><br>
	<a href="#" class="modLinkExcel">Excelリンクデザイン</a><br>
	<a href="#" class="modLinkPower">PowerPointリンクデザイン</a><br>
	<a href="#" class="modLinkBlank">外部リンクデザイン</a><br>
	<a href="#" class="modLinkPdf">PDFリンクデザイン</a>
</p>
```
*/
a.modLinkArrow,
.modLinkArrow a {
	display: inline-block;
	padding-right: 25px;
	text-decoration: none;
	position: relative;
	@include fz(16);
	line-height: 2.1;
	&:after {
		content: "";
		position: absolute;
		top: 11px;
		right: 0px;
		background: url(../img/arrow_red.png) no-repeat;
		background-size: contain;
		width: 7px;
		height: 14px;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}
&.modLinkWord {
	display: inline-block;
	padding-right: 25px;
	text-decoration: none;
	&:after {
		content: "";
		background: url(../img/low/icon_word.png) no-repeat;
		background-size: contain;
		width: 17px;
		height: 21px;
		margin-left: 4px;
		position: relative;
		top: 4px;
		display: inline-block;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}
&.modLinkExcel {
	display: inline-block;
	text-decoration: none;
	&:after {
		content: "";
		background: url(../img/low/icon_excel.png) no-repeat;
		background-size: contain;
		width: 17px;
		height: 21px;
		margin-left: 4px;
		position: relative;
		top: 4px;
		display: inline-block;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}
&.modLinkPower {
	display: inline-block;
	text-decoration: none;
	&:after {
		content: "";
		background: url(../img/low/icon_ppt.png) no-repeat;
		background-size: contain;
		width: 17px;
		height: 21px;
		margin-left: 4px;
		position: relative;
		top: 4px;
		display: inline-block;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}
&.modLinkBlank {
	display: inline-block;
	text-decoration: none;
	&:after {
		content: "";
		background: url(../img/low/icon_blank.png) no-repeat;
		background-size: contain;
		width: 20px;
		height: 20px;
		margin-left: 4px;
		position: relative;
		top: 4px;
		display: inline-block;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}
&.modLinkPdf {
	display: inline-block;
	text-decoration: none;
	&:after {
		content: "";
		background: url(../img/low/icon_pdf.png) no-repeat;
		background-size: contain;
		width: 17px;
		height: 21px;
		margin-left: 4px;
		position: relative;
		top: 4px;
		display: inline-block;
	}
	&:hover {
		color: $colorRed;
		opacity: 1;
	}
}

/*
#styleguide
modPageLink…スマホで縦並びになります
modPageLink02…スマホで２列ずつの横並びになります
```
<div class="modPageLink">
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
</div>
<div class="modPageLink02">
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
</div>
```
*/
.modPageLink {
	display: flex;
	flex-wrap: wrap;
	@include sphoneP {
		flex-direction: column;
	}
	&__item {
		width: 30.5%;
		margin-left: 4.25%;
		margin-bottom: 10px;
		border-bottom: 1px solid #878787;
		@include sphoneP_plus {
			&:nth-child(3n+1) {
				margin-left: 0;
			}
		}
		@include sphoneP {
			width: 100%;
			margin-left: 0;
			margin-bottom: 20px;
		}
	}
	a {
		@include fz(16);
		padding-bottom: 10px;
		padding-right: 20px;
		display: block;
		text-decoration: none;
		position: relative;
		&:after {
			content: "";
			background: url(../img/arrow_red.png) no-repeat;
			background-size: contain;
			transform: rotate(90deg);
			width: 8px;
			height: 14px;
			position: absolute;
			right: 6px;
			top: 50%;
			margin-top: -13px;
		}
	}
}
.modPageLink02 {
	display: flex;
	flex-wrap: wrap;
	@include sphoneP {
		justify-content: space-between;
	}
	.modPageLink__item {
		width: 30.5%;
		margin-left: 4.25%;
		margin-bottom: 10px;
		border-bottom: 1px solid #878787;
		@include sphoneP_plus {
			&:nth-child(3n+1) {
				margin-left: 0;
			}
		}
		@include sphoneP {
			width: 48%;
			margin-left: 0;
			margin-bottom: 20px;
		}
	}
	a {
		@include fz(16);
		padding-bottom: 10px;
		padding-right: 20px;
		display: block;
		text-decoration: none;
		position: relative;
		&:after {
			content: "";
			background: url(../img/arrow_red.png) no-repeat;
			background-size: contain;
			transform: rotate(90deg);
			width: 8px;
			height: 14px;
			position: absolute;
			right: 6px;
			top: 50%;
			margin-top: -13px;
		}
	}
}

@include keyframes(rotateLoading){

	0% {
	  -webkit-transform: rotate(0deg) scale(1);
	          transform: rotate(0deg) scale(1); }
	50% {
	  -webkit-transform: rotate(180deg) scale(0.6);
	          transform: rotate(180deg) scale(0.6); }
	100% {
	  -webkit-transform: rotate(360deg) scale(1);
	          transform: rotate(360deg) scale(1); }

}


/*↓消さないこと*/
.modLoading{
	position:fixed;
	top:0;
	bottom:0;
	left:0;
	right:0;
	background-color:rgba(255,255,255,0.8);
	z-index: 1000;

	.inner{
		position: absolute;
		top:0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 42px;
		height: 42px;
	}

	.inner > div {
		background-color: #666;
		height: 40px;
		width: 40px;
		border-radius: 100%;
		margin: 2px;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
		border: 2px solid #666;
		border-bottom-color: transparent;
		background: transparent !important;
		display: inline-block;
		@include animation(rotateLoading,0.75s,linear,infinite);
	}

}
/*↑消さないこと*/