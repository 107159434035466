@charset "utf-8";

/*
#overview
modText
*/

/*
#styleguide
modText

```
<p class="modText">テキスト</p>
```
*/
.modText {
  @include fz(16);
  line-height: 2.1;
  letter-spacing: 0.05em;
  margin-bottom: 50px;
  margin-top: 10px;
}

/*
#styleguide
modText_mbShort

```
<p class="modText_mbShort">マージンボトムが狭めのテキスト</p>
```
*/
.modText_mbShort {
  @include fz(16);
  line-height: 2.1;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  margin-top: 10px;
}

/*
#styleguide
modText_large

```
<p class="modText_large">大きいテキスト</p>
<p class="modText_large color_red">大きいテキスト</p>
<p class="modText_large color_blue">大きいテキスト</p>
<p class="modText_large color_gray">大きいテキスト</p>
```
*/
.modText_large {
  @include fz(20);
  margin-bottom: 10px;
  margin-top: 40px;
  font-weight: bold;
  line-height: 1.4;
}

/*
トップページ用
*/
.modTxtUnderTitle {
  @include fz(16);
  text-align: center;
  margin-bottom: 70px;
  @include sphoneP {
    @include fz(13);
    margin-bottom: 30px;
    line-height: 1.6;
  }
}