.strong {
  font-weight: bold;
}
.tCenter {
  text-align: center;
}

.color_red {
  color: $colorRed;
}
.color_blue {
  color: $colorBlue;
}
.color_blue2 {
  color: $colorBlue2;
}
.color_gray {
  color: $colorGray64;
}

.fz15 { @include fz(15); }
.fz20 { @include fz(20); }
.fz22 { @include fz(22); }

.mb0 { margin-bottom: 0 !important; }

.wpPost {
  > *:first-child {
    margin-top: 0;
  }
  img {
    &.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .checkListBlock {
    margin-bottom: 75px;
    h2 {
      @include fz(28);
      font-weight: bold;
      color: $colorRed;
      margin-bottom: 8px;
      border-style: none;
      padding-bottom: 0;
      position: relative;
      padding-left: 75px;
      &:before {
        content: "";
        background: url(../img/low/icon_question.png) no-repeat;
        background-size: contain;
        width: 64px;
        height: 62px;
        display: inline-block;
        margin-right: 14px;
        position: absolute;
        top: -12px;
        left: 0px;
      }
    }
  }
  .contactTable {
    width: 100%;
    margin-bottom: 50px;
    th {
      width: 195px;
      @include fz(16);
      line-height: 1.5;
      padding: 28px 20px 28px 0;
      border-top: 1px solid #e6e6e6;
      @include sphoneP {
        border-style: none;
      }
    }
    td {
      line-height: 1.5;
      padding: 15px 0;
      @include fz(16);
      border-top: 1px solid #e6e6e6;
    }
    .required {
      color: $colorRed;
      @include fz(14);
      margin-left: 8px;
      display: inline-block;
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      border: 1px solid #e6e6e6;
      border-radius: 0px;
      width: 100%;
      @include fz(16);
      padding-left: 15px;
    }
    textarea {
      width: 100%;
      padding-left: 15px;
      margin-top: 15px;
      @include fz(16);
    }
    @include sphoneP {
      margin-bottom: 30px;
      tbody,
      tr,
      th,
      td {
        display: block;
        width: 100%;
      }
      th {
        padding-bottom: 0;
      }
    }
  }
}

.modFormBtn {
  text-align: center;
  input {
    -webkit-appearance: none;
    border-style: none;
    background-color: #f5f5f5;
    padding: 30px 10px;
    max-width: 370px;
    width: 100%;
    display: inline-block;
    @include fz(16);
    &:hover {
      opacity: 0.8;
    }
  }
  input[type="submit"] {
    background-color: #1e1e1e;
    color: #fff;
    -webkit-appearance: none;
    border-radius: 0;
  }
}
.sp2clm label.addStyle {
  @include sphoneP {
    margin-right: 0;
    min-width: 50%;
  }
}
@include sphoneP_plus {
  .btnRows {
    display: flex;
    > * {
      margin-right: 33px;
    }
  }
}
.mw_wp_form_confirm .confirmHidden {
	display: none !important;
}
.mw_wp_form_input .inputHidden {
	display: none !important;
}
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important; 
}

.error404 h2 {
	font-size: 30px;
	color: #FF0004;
	text-align: center;
	padding: 160px 0 60px;
}
.error404 p {
	font-size: 20px;
	text-align: center;
	padding-bottom: 100px;
}