@charset "utf-8";

/*
#overview
modTitle
*/
.modTitle {
  &__anker {
    position: absolute;
    right: 0px;
    top: 0px;
    color: #fff;
    text-decoration: none;
    @include fz(13);
    line-height: 28px;
    min-width: 144px;
    color: #fff;
    padding: 0 5px;
    background-color: #1e1e1e;
    display: inline-block;
    text-align: center;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      margin-top: -6px;
      right: 13px;
      background: url(../img/arrow_red.png) no-repeat;
      background-size: contain;
      width: 7px;
      height: 12px;
    }
  }
}

/*
#styleguide
modTitle01

```
<h1 class="modTitle01">見出し１</h1>
```
*/
.modTitle01 {
  @include fz(40);
  margin-bottom: 40px;
  margin-top: 80px;
  line-height: 1.8;
  @include sphoneP {
    @include fz(24);
    line-height: 1.6;
  }
}

/*
#styleguide
modTitle02
modTitle02_link
modTitle02_center

```
<h2 class="modTitle02">見出し２</h2>
<h2 class="modTitle02_link">見出し２<a href="#" class="modTitle__anker">詳しくはこちら</a></h2>
<h2 class="modTitle02_center">見出し２　中央寄せ版</h2>
```
*/
.modTitle02 {
  @include fz(24);
  padding-bottom: 6px;
  border-bottom: 2px solid $colorRed;
  margin-top: 80px;
  margin-bottom: 45px;
  line-height: 1.8;
  @include sphoneP {
    @include fz(20);
    line-height: 1.5;
  }
  &_link {
    @extend .modTitle02;
    position: relative;
    padding-right: 172px;
    .modTitle__anker {
      top: 10px;
    }
    @include sphoneP {
      padding-right: 0;
      padding-bottom: 40px;
      .modTitle__anker {
        top: auto;
        bottom: 10px;
      }
    }
  }
}
.modTitle02_center {
  position: relative;
  text-align: center;
  @include fz(24);
  border-style: none;
  padding-bottom: 10px;
  margin-bottom: 45px;
  margin-top: 80px;
  line-height: 1.8;
  @include sphoneP {
    @include fz(20);
    line-height: 1.5;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 8px;
    background: url(../img/icon_h2.png) no-repeat center;
    background-size: 16px 8px;
  }
}

/*
#styleguide
modTitle03
modTitle03_link

```
<h3 class="modTitle03">見出し３</h3>
<h3 class="modTitle03_link">見出し３<a href="#" class="modTitle__anker">詳しくはこちら</a></h3>
```
*/
.modTitle03 {
  @include fz(20);
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  letter-spacing: 0.08em;
  line-height: 1.8;
  @include sphoneP {
    @include fz(18);
    line-height: 1.6;
  }
  &:after {
    width: 34px;
    height: 2px;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(90deg,$colorRed 0%,$colorRed 80%,#444444 80%,#444444 100%);
  }
  &_link {
    @extend .modTitle03;
    position: relative;
    padding-right: 172px;
    .modTitle__anker {
      top: 7px;
    }
    @include sphoneP {
      padding-right: 0;
      padding-bottom: 40px;
      .modTitle__anker {
        top: auto;
        bottom: 10px;
      }
    }
  }
}

/*
#styleguide
modTitle04
modTitle04_link

```
<h4 class="modTitle04">見出し４</h4>
<h4 class="modTitle04_link">見出し４<a href="#" class="modTitle__anker">詳しくはこちら</a></h4>
```
*/
.modTitle04 {
  @include fz(18);
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  line-height: 1.8;
  @include sphoneP {
    @include fz(16);
    line-height: 1.6;
  }
  &_link {
    @extend .modTitle04;
    position: relative;
    padding-right: 172px;
    .modTitle__anker {
      top: 6px;
    }
    @include sphoneP {
      padding-right: 0;
      padding-bottom: 40px;
      .modTitle__anker {
        top: auto;
        bottom: 10px;
      }
    }
  }
}

/*
#styleguide
modTitle05

```
<h5 class="modTitle05">見出し５</h5>
```
*/
.modTitle05 {
  @include fz(16);
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  line-height: 1.8;
  @include sphoneP {
    @include fz(14);
    line-height: 1.6;
  }
}

/*
#styleguide
modTitleEnBack

```
<h2 class="modTitleEnBack"><span class="en">CATCH COPY</span>見出し</h2>
```
*/
.modTitleEnBack {
  @include fz(40);
  padding-top: 16px;
  padding-bottom: 12px;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  z-index: 2;
  line-height: 1.8;
  @include sphoneP {
    @include fz(24);
    line-height: 28px;
    padding-bottom: 25px;
    margin-bottom: 12px;
    width: 100vw;
    margin-left: -20px;
  }
  &:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 8px;
    background: url(../img/icon_h2.png) no-repeat center;
    background-size: 16px 8px;
  }
  .en {
    position: absolute;
    z-index: -1;
    top: -24px;
    left: 0px;
    width: 100%;
    @include fz(60);
    color: #828282;
    opacity: 0.2;
    @include openSans($bold);
    @include sphoneP {
      @include fz(32);
      line-height: 28px;
      top: 0;
      letter-spacing: -0.03em;
    }
  }
  &.row2 {
    margin-top: 40px;
    .en {
      top: -24px;
    }
  }
  &.color-2 {
    text-shadow: 0px 0px 13px rgba(170,20,0,1);
    .en {
      color: #fff;
      opacity: 0.5;
      text-shadow: 0 0 0 rgba(0,0,0,0);
    }
    &:after {
      background-image: url(../img/icon_h2_2.png);
    }
  }
}

/*
#styleguide
modTitle06

```
<h1 class="modTitle06 modTitle06_blue">見出し</h1>
<h1 class="modTitle06 modTitle06_red">見出し</h1>
<h1 class="modTitle06 modTitle06_gray">見出し</h1>
```
*/
.modTitle06 {
  padding-top: 144px;
  padding-bottom: 54px;
  color: #fff;
  @include fz(42);
  text-align: center;
  background: url(../img/lp/icon_shake.png) no-repeat center 76px;
  background-size: 90px auto;
  @media only screen and (min-width: 1341px) {
    padding-left: calc((100% - 1300px)/2);
    padding-right: calc((100% - 1300px)/2);
  }
  padding-left: 20px;
  padding-right: 20px;
  @include sphoneP {
    @include fz(24);
    line-height: 1.4;
    background-size: 82px auto;
    padding-top: 110px;
    padding-bottom: 35px;
    background-position: center 39px;
  }
  &_blue {
    background-color: $colorBlue;
  }
  &_red {
    background-color: $colorRed;
  }
  &_gray {
    background-color: $colorGray;
    color: $baseColor;
    background-image: url(../img/lp/icon_shake_black.png);
  }
}

/*
#styleguide
modTitle07

```
<h2 class="modTitle07 modTitle07_red">見出し</h2>
<h2 class="modTitle07 modTitle07_blue">見出し</h2>
<h2 class="modTitle07 modTitle07_gray">見出し</h2>
```
*/
.modTitle07 {
  padding: 19px 36px;
  @include fz(32);
  color: #fff;
  margin-bottom: 30px;
  @include sphoneP {
    @include fz(23);
    padding: 15px 25px;
  }
  &_blue {
    background-color: $colorBlue;
  }
  &_red {
    background-color: $colorRed;
  }
  &_gray {
    background-color: $colorGray;
    color: $baseColor;
  }
}

/*
#styleguide
modTitle08

```
<h3 class="modTitle08 modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_gray">見出し</h3>
```
*/
.modTitle08 {
  margin-bottom: 40px;
  margin-top: 70px;
  padding: 5px 24px;
  line-height: 1.7;
  @include fz(22);
  font-weight: bold;
  color: #fff;
  &_red {
    background-color: $colorRed;
  }
  &_blue {
    background-color: $colorBlue;
  }
  &_gray {
    background-color: $colorGray;
    color: $baseColor;
  }
  &_light {
    &:before {
      content: "";
      background: url(../img/lp/icon_light.png) no-repeat;
      background-size: contain;
      width: 18px;
      height: 26px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
  }
  &_caution {
    &:before {
      content: "";
      background: url(../img/lp/icon_caution.png) no-repeat;
      background-size: contain;
      width: 5px;
      height: 23px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
    &.modTitle08_gray {
      &:before {
        background-image: url(../img/lp/icon_caution_black.png);
      }
    }
  }
  &_arrow {
    &:before {
      content: "";
      background: url(../img/lp/icon_arrow.png) no-repeat;
      background-size: contain;
      width: 20px;
      height: 22px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
    &.modTitle08_gray {
      &:before {
        background-image: url(../img/lp/icon_arrow_black.png);
      }
    }
  }
  &_check {
    &:before {
      content: "";
      background: url(../img/lp/icon_check.png) no-repeat;
      background-size: contain;
      width: 22px;
      height: 16px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
    &.modTitle08_gray {
      &:before {
        background-image: url(../img/lp/icon_check_black.png);
      }
    }
  }
  &_question {
    &:before {
      content: "";
      background: url(../img/lp/icon_question.png) no-repeat;
      background-size: contain;
      width: 16px;
      height: 25px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
    &.modTitle08_gray {
      &:before {
        background-image: url(../img/lp/icon_question_black.png);
      }
    }
  }
  &_man {
    &:before {
      content: "";
      background: url(../img/lp/icon_man.png) no-repeat;
      background-size: contain;
      width: 23px;
      height: 23px;
      display: inline-block;
      vertical-align: -3px;
      margin-right: 20px;
    }
    &.modTitle08_gray {
      &:before {
        background-image: url(../img/lp/icon_man_black.png);
      }
    }
  }
}

/*
#styleguide
modTitle09

```
<h4 class="modTitle09 modTitle09_red">見出し</h4>
<h4 class="modTitle09 modTitle09_blue">見出し</h4>
<h4 class="modTitle09 modTitle09_gray">見出し</h4>

<h4 class="modTitle09 modTitle09_red"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
<h4 class="modTitle09 modTitle09_blue"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
<h4 class="modTitle09 modTitle09_gray"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
```
*/
.modTitle09 {
  display: flex;
  @include fz(22);
  font-weight: bold;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-bottom: 30px;
  line-height: 1.8;
  @include sphoneP {
    display: block;
  }
  &__tag {
    color: #fff;
    padding: 0 15px;
    margin-right: 15px;
    @include fz(20);
    @include sphoneP {
      display: inline-block;
      margin-right: 0;
      margin-bottom: 5px;
    }
  }
  &__text {
    @include sphoneP {
      display: block;
    }
  }
  &_red {
    border-color: $colorRed;
    .modTitle09__tag {
      background: $colorRed;
    }
  }
  &_blue {
    border-color: $colorBlue;
    .modTitle09__tag {
      background: $colorBlue;
    }
  }
  &_gray {
    border-color: $colorGray;
    .modTitle09__tag {
      background: $colorGray;
      color: $baseColor;
    }
  }
}

/*
#styleguide
lpQuestionHeadline

```
<h3 class="lpQuestionHeadline">このようなお悩みのある企業様、ぜひお越しください。<br /><span class="strong">システム導入による在庫適正化・業務改革の実現可能性があります！</span></h3>
```
*/
.lpQuestionHeadline {
  padding: 20px 60px 25px;
  background: $colorRed url(../img/lp/icon_question.png) no-repeat 29px 24px;
  background-size: 17px auto;
  color: #fff;
  @include fz(24);
  line-height: 1.5;
  margin-bottom: 10px;
  @include sphoneP {
    @include fz(21);
    padding: 15px 39px 15px 55px;
  }
}