@charset "UTF-8";
@import url("//fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@import url("//fonts.googleapis.com/css?family=Open+Sans:400,700");
/* Reset
------------------------------------------------------------*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

address,
em {
  font-style: normal;
}

strong,
th {
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
}

hr,
legend {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

img,
fieldset {
  border: 0;
}

img {
  border: 0;
  vertical-align: top;
  max-width: 100%;
  height: auto;
}

li {
  list-style-type: none;
}

input[type="submit"], button, label, select {
  cursor: pointer;
}

.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

main {
  display: block;
}

/* link
------------------------------------------------------------*/
a {
  color: #333;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:hover, button:hover {
  opacity: 0.8;
}

/* Fonts
------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

/* 10px base */
body {
  -webkit-text-size-adjust: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "Open Sans", "Hiragino Kaku Gothic ProN", meiryo, sans-serif;
  line-height: 1.8;
  color: #444;
  font-size: 14px;
  font-size: 1.4rem;
  min-width: 1330px;
}

@media only screen and (max-width: 750px) {
  body {
    min-width: 1px;
  }
}

input, button, textarea, select {
  line-height: 1.8;
  color: #444;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", "Noto Sans Japanese", "Open Sans", "Hiragino Kaku Gothic ProN", meiryo, sans-serif;
}

/* form
------------------------------------------------------------*/
textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
select {
  padding: 10px;
  border-radius: 5px;
  border: solid 1px #aaa;
}

textarea.error,
input[type="text"].error,
input[type="password"].error,
input[type="tel"].error,
input[type="email"].error,
select.error {
  background-color: #fff2f2;
  border: solid 2px #E33E41;
}

/* radio
-------------*/
input[type="radio"] {
  display: none;
}

input[type="radio"] + label,
label.radioStyle {
  position: relative;
  display: inline-block;
  padding: 3px 40px 3px 24px;
  cursor: pointer;
}

input[type="radio"] + label::before,
input[type="radio"] + label::after,
label.radioStyle:after,
label.radioStyle:before {
  content: '';
  top: 50%;
  position: absolute;
  border-radius: 100%;
  transition: all .2s;
}

input[type="radio"] + label::before,
label.radioStyle:before {
  width: 16px;
  height: 16px;
  margin-top: -9px;
  left: 0;
  background: #EEE;
  border: 1px solid #ccc;
}

input[type="radio"].error + label::before {
  border: 1px solid #E33E41;
  background-color: #fff2f2;
}

input[type="radio"] + label:hover::before,
label.radioStyle:hover:before {
  background: #FEFEFE;
}

input[type="radio"] + label::after,
label.radioStyle:after {
  opacity: 0;
  left: 3px;
  width: 10px;
  height: 10px;
  margin-top: -6px;
  background: #103459;
  transform: scale(0.5);
}

input[type="radio"]:checked + label::before,
label.radioStyleChecked:before {
  background: #fff;
  border: 1px solid #103459;
}

input[type="radio"]:checked + label::after,
label.radioStyleChecked:after {
  opacity: 1;
  transform: scale(1);
}

/* checkbox
-------------*/
input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label,
label.addStyle {
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 25px;
  cursor: pointer;
  margin-right: 33px;
}

@media only screen and (max-width: 750px) {
  input[type="checkbox"] + label,
  label.addStyle {
    width: 100%;
  }
}

label.addStyle:hover {
  opacity: 0.8;
}

input[type="checkbox"] + label::before,
input[type="checkbox"] + label::after,
label.addStyle::before,
label.addStyle::after {
  position: absolute;
  content: '';
}

input[type="checkbox"] + label::before,
label.addStyle::before {
  top: 50%;
  left: 0;
  width: 18px;
  height: 18px;
  margin-top: -9px;
  background: #fff;
  border: 1px solid #646464;
  border-radius: 0px;
}

input[type="checkbox"] + label::after,
label.addStyle::after {
  opacity: 0;
  top: 50%;
  left: 3px;
  width: 12px;
  height: 6px;
  margin-top: -5px;
  border-left: 1px solid #6d6d6d;
  border-bottom: 1px solid #6d6d6d;
  transform: rotate(-45deg) scale(0.5);
}

input[type="checkbox"] + label:hover::before,
label.addStyle:hover::before {
  background: #fff;
}

input[type="checkbox"]:checked + label::before,
label.addStyleChecked::before {
  background: #fff;
  border: 1px solid #103459;
}

input[type="checkbox"]:checked + label::after,
label.addStyleChecked::after {
  opacity: 1;
  transform: rotate(-45deg) scale(1);
}

/*
#overview
base
*/
/* Header
------------------------------------------------------------*/
.header {
  position: relative;
}

.headerInner {
  padding-top: 30px;
}

@media only screen and (max-width: 750px) {
  .headerInner {
    padding-top: 12px;
    padding-bottom: 12px;
    position: relative;
  }
}

.headerTop {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .headerTop {
    padding-right: 58px;
    position: relative;
  }
}

.headerLeft {
  display: flex;
}

.headerDescription {
  font-size: 12px;
  font-size: 1.2rem;
  color: #828282;
}

@media only screen and (max-width: 750px) {
  .headerDescription {
    display: none;
  }
}

.logo {
  margin-right: 20px;
}

@media only screen and (max-width: 750px) {
  .logo {
    margin-right: 0;
    width: 110px;
  }
}

.headerSubNavi {
  padding-top: 10px;
}

@media only screen and (max-width: 750px) {
  .headerSubNavi {
    padding-top: 2px;
  }
}

.headerSubNavi__list {
  display: flex;
}

.headerSubNavi li {
  margin-left: 30px;
  text-align: center;
  line-height: 1.6em;
}

@media only screen and (max-width: 750px) {
  .headerSubNavi li {
    width: 23px;
  }
}

.headerSubNavi a {
  text-decoration: none;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 0.01em;
}

.spMenuBtn {
  display: none;
}

@media only screen and (max-width: 750px) {
  .spMenuBtn {
    padding: 7px;
    display: block;
    position: absolute;
    top: 15px;
    right: 5px;
  }
  .spMenuBtn__inner {
    position: relative;
    width: 18px;
    height: 14px;
  }
  .spMenuBtn span {
    height: 1px;
    width: 18px;
    display: inline-block;
    position: absolute;
    background-color: #aa1400;
    transition: .2s;
  }
  .spMenuBtn span:first-child {
    top: 0px;
    left: 0px;
  }
  .spMenuBtn span:nth-child(2) {
    top: 50%;
    left: 0px;
  }
  .spMenuBtn span:last-child {
    bottom: 0px;
    left: 0px;
  }
  .spMenuBtn.is-active span:first-child {
    top: 6px;
    left: 1px;
    transform: rotate(45deg);
  }
  .spMenuBtn.is-active span:nth-child(2) {
    top: 50%;
    left: 0px;
    display: none;
  }
  .spMenuBtn.is-active span:last-child {
    bottom: 7px;
    left: 1px;
    transform: rotate(-45deg);
  }
}

.gNavi {
  margin-top: 46px;
}

@media only screen and (max-width: 750px) {
  .gNavi {
    display: none;
  }
}

.gNavi__list {
  display: flex;
  justify-content: space-between;
}

.gNavi li {
  width: 16.67%;
  text-align: center;
  position: relative;
  line-height: 1;
}

.gNavi li:before {
  position: absolute;
  left: 0px;
  top: 1px;
  width: 2px;
  height: 13px;
  content: "";
  background-color: #1e1e1e;
}

.gNavi li:last-child:after {
  position: absolute;
  right: 0px;
  top: 1px;
  width: 2px;
  height: 13px;
  content: "";
  background-color: #1e1e1e;
}

.gNavi a {
  display: block;
  text-decoration: none;
  font-size: 16px;
  font-size: 1.6rem;
  padding-bottom: 25px;
}

.gNavi a:hover {
  color: #aa1400;
  opacity: 1;
}

.gNavi .is-hover a {
  color: #aa1400;
}

.gNavi .is-current a {
  padding-bottom: 19px;
  border-bottom: 6px solid #aa1400;
}

.searchArea {
  position: absolute;
  top: 106px;
  left: 0px;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  padding-top: 18px;
  padding-bottom: 18px;
  display: none;
  z-index: 10;
}

@media only screen and (max-width: 750px) {
  .searchArea {
    top: 58px;
  }
}

.searchArea__inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .searchArea__inner {
    flex-direction: column;
  }
}

.searchArea__left {
  width: 1105px;
}

@media only screen and (max-width: 750px) {
  .searchArea__left {
    width: 100%;
    margin-bottom: 15px;
  }
}

.searchArea__input {
  width: 100%;
  height: 56px;
  border-radius: 0px !important;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .searchArea__input {
    font-size: 14px;
    font-size: 1.4rem;
  }
}

.searchArea__submit {
  -webkit-appearance: none;
  border-style: none;
  width: 170px;
  height: 56px;
  background: #1e1e1e;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .searchArea__submit {
    width: 100%;
    height: 45px;
    font-size: 14px;
    font-size: 1.4rem;
    margin-bottom: 20px;
    background: #1e1e1e url(../img/arrow_white.png) no-repeat 96% 50%;
    background-size: 17px auto;
  }
}

.searchArea__close {
  font-size: 12px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.searchArea__close:before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background: url(../img/icon_close.png) no-repeat;
  background-size: contain;
  margin-right: 5px;
}

.subNavi {
  position: absolute;
  top: 100%;
  padding: 45px 60px 60px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: .2s;
  z-index: 10;
}

@media only screen and (max-width: 750px) {
  .subNavi {
    display: none;
  }
}

.subNavi.is-show {
  opacity: 1;
  visibility: visible;
}

.subNavi__column {
  margin-right: 100px;
}

.subNavi__tag {
  padding-left: 45px;
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  margin-bottom: 15px;
}

.subNavi__tag:before {
  position: absolute;
  width: 36px;
  height: 2px;
  top: 12px;
  left: 0px;
  background-color: #aa1400;
  content: "";
}

.subNavi__link {
  padding-left: 45px;
}

.subNavi__link li {
  line-height: 1.4;
  font-size: 13px;
  font-size: 1.3rem;
  margin-bottom: 5px;
}

.subNavi__link a {
  padding-right: 17px;
  position: relative;
  display: inline-block;
}

.subNavi__link a:after {
  content: "";
  width: 5px;
  height: 9px;
  background: url(../img/arrow_red.png) no-repeat;
  display: inline-block;
  background-size: contain;
  top: 4px;
  right: 0px;
  position: absolute;
}

.subNavi__row {
  width: 480px;
}

.subNavi__row .subNavi__link {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}

.subNavi__row .subNavi__link li {
  width: 240px;
  padding-right: 30px;
}

.subNavi a {
  color: #fff;
  text-decoration: none;
}

.subNavi a:hover {
  color: #aa1400;
  opacity: 1;
}

.spNav {
  position: absolute;
  top: 100%;
  left: 100%;
  width: 100%;
  z-index: 100;
  transition: .2s;
}

@media only screen and (min-width: 751px) {
  .spNav {
    display: none;
  }
}

.spNav.is-active {
  left: 0px;
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
}

.spNav__list {
  border-top: 1px solid #e6e6e6;
}

.spNav__list > li {
  border-bottom: 1px solid #e6e6e6;
  position: relative;
}

.spNav__list > li > a {
  display: block;
  padding: 15px 24px;
  background-color: #f5f5f5;
  text-decoration: none;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.2;
  position: relative;
}

.spNav__list > li > a:after {
  position: absolute;
  top: 50%;
  right: 23px;
  height: 12px;
  width: 7px;
  margin-top: -6px;
  content: "";
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
}

.spNav .spSubNav {
  display: none;
}

.spNav .spSubNav li {
  border-bottom: 1px solid #c8c8c8;
}

.spNav .spSubNav li:last-child {
  border-bottom-style: none;
}

.spNav .spSubNav li a {
  background-color: #e6e6e6;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  padding: 12px 24px 12px 37px;
  display: block;
  text-decoration: none;
  position: relative;
}

.spNav .spSubNav li a:after {
  position: absolute;
  top: 50%;
  right: 26px;
  height: 12px;
  width: 7px;
  margin-top: -6px;
  content: "";
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
}

.navOpen {
  width: 30px;
  height: 30px;
  background: url(../img/icon_plus.png) no-repeat center;
  background-size: 12px;
  position: absolute;
  top: 23px;
  margin-top: -15px;
  right: 12px;
}

.navOpen.is-close {
  background-image: url(../img/icon_minus.png);
  background-position: center;
}

.spHasChild > a:after {
  display: none;
}

.contents {
  padding-bottom: 160px;
}

@media only screen and (max-width: 750px) {
  .contents {
    padding-bottom: 120px;
  }
}

.contents_home {
  padding-bottom: 0px;
}

@media only screen and (max-width: 750px) {
  .contents_home {
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 750px) {
  #wrapper {
    overflow: hidden;
  }
}

/*
#styleguide
container

```
<div class="container">
左右にpadding15pxつきます
</div>
```
*/
/* Container
------------------------------------------------------------*/
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
}

@media only screen and (max-width: 750px) {
  .container {
    padding-right: 20px;
    padding-left: 20px;
  }
  .container-header {
    padding-right: 12px;
    padding-left: 12px;
  }
}

/* Fotter
------------------------------------------------------------*/
.pageTop {
  text-align: center;
  margin-bottom: 30px;
}

.pageTop a {
  color: #aa1400;
  text-decoration: none;
  font-size: 12px;
  font-size: 1.2rem;
  padding-top: 30px;
  background: url(../img/gotop.png) no-repeat center top;
  background-size: 20px 23px;
  display: inline-block;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 750px) {
  .pageTop {
    margin-bottom: 18px;
  }
}

.footerInner {
  background-color: #1e1e1e;
  color: #fff;
  padding-top: 70px;
  padding-bottom: 45px;
}

@media only screen and (max-width: 750px) {
  .footerInner {
    padding-top: 32px;
    padding-bottom: 15px;
  }
}

.footerInner a {
  color: #fff;
  text-decoration: none;
}

.footerInner a:hover {
  opacity: 1;
  color: #aa1400;
}

.footerTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

@media only screen and (max-width: 750px) {
  .footerTop {
    margin-bottom: 18px;
    flex-direction: column;
  }
}

.footerTopContact a {
  text-align: center;
  display: inline-block;
  background-color: #646464;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  width: 300px;
  height: 64px;
  padding-top: 19px;
  text-decoration: none;
  position: relative;
}

.footerTopContact a:after {
  content: "";
  position: absolute;
  width: 27px;
  height: 5px;
  background: url(../img/arrow_white.png) no-repeat;
  background-size: contain;
  right: 17px;
  top: 50%;
  margin-top: -3px;
}

@media only screen and (max-width: 750px) {
  .footerTopContact a {
    width: 100%;
    font-size: 14px;
    font-size: 1.4rem;
    height: 60px;
    padding-top: 17px;
  }
}

@media only screen and (max-width: 750px) {
  .footerTopLeft {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.footerTop__logo {
  margin-bottom: 25px;
}

.footerTop__logo img {
  border: 2px solid #fff;
  width: 144px;
}

@media only screen and (max-width: 750px) {
  .footerTop__logo {
    width: 111px;
  }
}

.footerTop__address {
  display: block;
  line-height: 2.1;
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 750px) {
  .footerTop__address {
    width: calc(100% - 150px);
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.footerNav {
  display: flex;
  justify-content: space-between;
  padding-right: 70px;
}

@media only screen and (max-width: 750px) {
  .footerNav {
    display: none;
  }
}

.footerNav__tag {
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 48px;
  position: relative;
  line-height: 1;
  margin-bottom: 25px;
}

.footerNav__tag:before {
  width: 26px;
  height: 2px;
  content: "";
  position: absolute;
  top: 6px;
  left: 0px;
  background-color: #aa1400;
}

.footerNav__list_clm2 {
  display: flex;
  flex-wrap: wrap;
}

.footerNav__list_clm2 li {
  width: 50%;
}

.footerNav__list li {
  padding-left: 62px;
  position: relative;
  margin-bottom: 17px;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
}

.footerNav__list li:after {
  left: 48px;
  top: 3px;
  content: "";
  width: 6px;
  height: 6px;
  background-color: #aa1400;
  position: absolute;
}

.footerNavListWrapper {
  width: 505px;
}

.footerBottom {
  display: flex;
  justify-content: space-between;
  margin-top: 115px;
}

@media only screen and (max-width: 750px) {
  .footerBottom {
    margin-top: 30px;
    flex-direction: column;
  }
}

.footerBottomSubNav {
  flex: 1;
}

@media only screen and (max-width: 750px) {
  .footerBottomSubNav__list {
    text-align: center;
    margin-bottom: 30px;
  }
}

.footerBottomSubNav__list li {
  margin-right: 30px;
  display: inline-block;
  font-size: 12px;
  font-size: 1.2rem;
}

@media only screen and (max-width: 750px) {
  .footerBottomSubNav__list li {
    margin: 0 10px;
  }
}

.copyright {
  font-size: 13px;
  font-size: 1.3rem;
  width: 28%;
  text-align: right;
  white-space: nowrap;
}

@media only screen and (max-width: 750px) {
  .copyright {
    text-align: center;
    width: 100%;
  }
}

.spFooterNav {
  display: none;
}

@media only screen and (max-width: 750px) {
  .spFooterNav {
    display: block;
  }
  .spFooterNav li {
    border-bottom: 1px solid #a8a2a1;
  }
  .spFooterNav a {
    padding: 15px 0;
    display: block;
    position: relative;
  }
  .spFooterNav a:after {
    content: "";
    background: url(../img/arrow_red.png) no-repeat;
    background-size: contain;
    width: 7px;
    height: 14px;
    position: absolute;
    right: 0px;
    top: 50%;
    margin-top: -7px;
  }
}

/* Page Common
------------------------------------------------------------*/
.breadCrumb {
  background-color: #f5f5f5;
  padding: 10px 0;
  margin-bottom: 80px;
  font-size: 13px;
  font-size: 1.3rem;
}

@media only screen and (max-width: 750px) {
  .breadCrumb {
    padding: 10px 20px;
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.breadCrumb__list {
  width: 1300px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .breadCrumb__list {
    width: 100%;
  }
}

.breadCrumb a {
  color: #828282;
}

.breadCrumb span {
  display: inline;
}

.breadCrumb span.current-item {
  font-weight: bold;
}

.breadCrumb span:not(:last-child) {
  margin-right: 27px;
  position: relative;
}

.breadCrumb span:not(:last-child):after {
  content: ">";
  font-size: 16px;
  font-size: 1.6rem;
  position: absolute;
  right: -18px;
  top: -6px;
}

@media only screen and (max-width: 750px) {
  .breadCrumb span:not(:last-child):after {
    top: -7px;
  }
}

.breadCrumb .home a {
  text-decoration: none;
  text-indent: 100%;
  overflow: hidden;
  width: 15px;
  height: 14px;
  display: inline-block;
  background: url(../img/icon_home.png) no-repeat;
  background-size: contain;
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 750px) {
  .breadCrumb .home a {
    top: 2px;
  }
}

.layoutClm1 {
  width: 1300px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .layoutClm1 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.layoutClm2 {
  display: flex;
  justify-content: space-between;
  width: 1300px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .layoutClm2 {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
}

.contents_clm2 {
  width: 916px;
}

@media only screen and (max-width: 750px) {
  .contents_clm2 {
    width: 100%;
  }
}

.side {
  width: 326px;
  padding-bottom: 160px;
}

@media only screen and (max-width: 750px) {
  .side {
    padding: 30px 20px 64px;
    width: 100vw;
    margin-left: -20px;
    border-top: 2px solid #f5f5f5;
  }
}

.sideBlock__title {
  border-top: 2px solid #aa1400;
  background-color: #1e1e1e;
  color: #fff;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .sideBlock__title {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.6;
  }
}

.sideBlock__title_nolink {
  padding: 17px 25px;
}

.sideBlock__title a {
  padding: 17px 25px;
  display: block;
  color: inherit;
  text-decoration: none;
}

.sideBlock:not(:first-child) {
  margin-top: 40px;
}

.sideNav {
  padding: 15px 25px;
  background-color: #fafafa;
  border-bottom: 2px solid #c8c8c8;
}

.sideNav:empty {
  padding: 0;
  border-style: none;
}

.sideNav > li {
  padding: 6px 0;
  position: relative;
}

.sideNav > li:not(:last-child) {
  border-bottom: 1px solid #e4e4e4;
}

.sideNav > li:after {
  width: 7px;
  height: 13px;
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
  content: "";
  position: absolute;
  right: 0px;
  top: 14px;
}

.sideNav > li > a {
  font-size: 16px;
  display: block;
  text-decoration: none;
  padding-right: 15px;
}

.sideNav > li > a:hover {
  color: #aa1400;
  opacity: 1;
}

.sideNav .current_page_ancestor > a {
  font-weight: bold;
}

.sideNav .current_page_item > a {
  font-weight: bold;
}

.sideNav .children {
  padding-left: 14px;
}

.sideNav .children li {
  padding-top: 10px;
}

.sideNav .children a {
  text-decoration: none;
  display: block;
}

.sideNav .children a:hover {
  color: #aa1400;
  opacity: 1;
}

.homeMv {
  height: 500px;
  background: url(../img/home/bg_mv2.jpg) no-repeat;
  background-size: cover;
}

.homeMv__h1 {
  max-width: 1340px;
  padding-top: 126px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .homeMv {
    background-image: none;
    height: auto;
    margin-bottom: 150px;
  }
  .homeMv__h1 {
    padding-top: 50px;
    padding-left: 0;
    padding-right: 0;
    width: 83%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 750px) {
  .spMvWrap {
    background: url(../img/home/bg_mv2_sp.jpg) no-repeat;
    background-size: cover;
    padding-bottom: 10px;
    margin-bottom: 40px;
  }
}

.homeNews {
  display: flex;
  margin-top: -48px;
  position: relative;
  z-index: 3;
  width: 100%;
  margin-bottom: 100px;
}

@media only screen and (max-width: 750px) {
  .homeNews {
    margin-top: 0;
    flex-direction: column;
    margin-bottom: 0;
  }
}

.homeNews__title {
  width: 147px;
  background-color: rgba(4, 44, 58, 0.8);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.08em;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .homeNews__title {
    width: 100%;
    padding: 10px;
    text-align: left;
    font-size: 12px;
    font-size: 1.2rem;
    display: block;
  }
}

.homeNews__info {
  padding: 30px 50px;
  background-color: rgba(9, 99, 131, 0.8);
  width: calc(100% - 147px);
}

@media only screen and (max-width: 750px) {
  .homeNews__info {
    width: 100%;
    padding: 10px;
  }
}

.homeNews__text {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .homeNews__text {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.homeNews__text a {
  color: #fff;
  text-decoration: none;
  display: inline-block;
  padding-right: 50px;
  position: relative;
}

.homeNews__text a:after {
  content: "";
  background: url(../img/arrow_white.png) no-repeat;
  background-size: contain;
  width: 27px;
  height: 5px;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -3px;
}

@media only screen and (max-width: 750px) {
  .homeNews__text a:after {
    width: 20px;
  }
}

.homeSlider .slick-slide img {
  margin: 0 auto;
}

.homeSlider .slick-slide {
  margin: 0 5px;
}

.homeSlider .overlay {
  opacity: 0.3;
}

.homeSlider .slick-center.overlay {
  opacity: 1;
}

.homeSlider:not(.slick-center) {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.homeSlider .overlay1 {
  opacity: 1 !important;
}

.homeSlider .overlay2 {
  opacity: 0.3 !important;
}

.homeSlider .slick-prev {
  left: 50%;
  z-index: 5;
  margin-left: -572px;
  width: 24px;
  height: 38px;
}

@media only screen and (max-width: 750px) {
  .homeSlider .slick-prev {
    width: 15px;
    height: 30px;
    margin-left: -44%;
  }
}

.homeSlider .slick-prev:before {
  width: 100%;
  height: 100%;
  background: url(../img/home/prev.png) no-repeat;
  background-size: contain;
  text-indent: -9999px;
  display: block;
}

.homeSlider .slick-next {
  right: 50%;
  z-index: 5;
  margin-right: -572px;
  width: 24px;
  height: 38px;
}

@media only screen and (max-width: 750px) {
  .homeSlider .slick-next {
    width: 15px;
    height: 30px;
    margin-right: -44%;
  }
}

.homeSlider .slick-next:before {
  width: 100%;
  height: 100%;
  background: url(../img/home/next.png) no-repeat;
  background-size: contain;
  text-indent: -9999px;
  display: block;
}

.homeSlider .slick-dots {
  bottom: -40px;
}

@media only screen and (max-width: 750px) {
  .homeSlider .slick-dots {
    bottom: -30px;
  }
}

.homeSlider .slick-dots li {
  margin: 0 1px;
}

.homeSlider .slick-dots li button:before {
  color: #333333;
}

.homeSlider .slick-dots li.slick-active button:before {
  color: #aa1400;
}

.homeSlider.slick-slider {
  margin-bottom: 170px;
}

@media only screen and (max-width: 750px) {
  .homeSlider.slick-slider {
    margin-bottom: 70px;
  }
}

.homeSlider.slick-dotted.slick-slider {
  margin-bottom: 170px;
}

@media only screen and (max-width: 750px) {
  .homeSlider.slick-dotted.slick-slider {
    margin-bottom: 70px;
  }
}

.homeSupply {
  padding-bottom: 185px;
}

@media only screen and (max-width: 750px) {
  .homeSupply {
    padding-bottom: 64px;
  }
}

.homeSolution {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
  margin-bottom: 150px;
}

@media only screen and (max-width: 750px) {
  .homeSolution {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 64px;
  }
}

.homeSubject {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f5f5f5;
  margin-bottom: 150px;
}

@media only screen and (max-width: 750px) {
  .homeSubject {
    padding-top: 40px;
    padding-bottom: 40px;
    margin-bottom: 64px;
  }
}

.homeSubjectList {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList {
    flex-direction: column;
  }
}

.homeSubjectList__item {
  width: 630px;
  background-color: #fff;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList__item {
    width: 100%;
  }
}

.homeSubjectList__inner {
  display: flex;
  justify-content: space-between;
  padding: 45px;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList__inner {
    padding: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .homeSubjectList__thumb {
    width: 85px;
  }
}

.homeSubjectList__link {
  width: 265px;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList__link {
    width: calc(100% - 100px);
  }
}

.homeSubjectList li {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
}

.homeSubjectList a {
  text-decoration: none;
  padding-right: 23px;
  display: inline-block;
  max-width: 100%;
  position: relative;
}

.homeSubjectList a:hover {
  opacity: 1;
  color: #aa1400;
}

.homeSubjectList a:after {
  width: 7px;
  height: 13px;
  background: url(../img/home/arrow_red.png) no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 7px;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList a:after {
    width: 5px;
    top: 8px;
  }
}

@media only screen and (max-width: 750px) {
  .homeSubjectList a {
    font-size: 12px;
    font-size: 1.2rem;
    padding-left: 1.1em;
    padding-right: 0;
  }
  .homeSubjectList a:after {
    left: 0px;
    right: auto;
  }
}

.homeSubjectList__title {
  padding: 8px;
  background-color: #aa1400;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .homeSubjectList__title {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 4px 8px;
  }
}

.homeColumn {
  margin-bottom: 150px;
}

@media only screen and (max-width: 750px) {
  .homeColumn {
    margin-bottom: 64px;
  }
}

.homeColumn__bgArea {
  background: url(../img/home/bg_column.jpg) no-repeat center top;
  background-size: cover;
  height: 430px;
  padding-top: 64px;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .homeColumn__bgArea {
    height: 296px;
    padding: 45px 20px 0;
    background-image: url(../img/home/bg_column_sp.jpg);
  }
}

.homeColumnList {
  margin-top: -150px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .homeColumnList {
    flex-direction: column;
    margin-top: -48px;
  }
}

.homeColumnList__item {
  width: 630px;
  box-shadow: -3px 2px 5px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__item {
    width: 100%;
    margin-bottom: 20px;
  }
}

.homeColumnList a {
  display: flex;
  padding: 30px 60px 30px 30px;
  justify-content: space-between;
  text-decoration: none;
  background-color: #fff;
  height: 100%;
}

@media only screen and (max-width: 750px) {
  .homeColumnList a {
    padding: 15px 40px 15px 15px;
  }
}

.homeColumnList a:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 24px;
  height: 48px;
  margin-top: -24px;
  background: url(../img/home/arrow_column.png);
  background-size: contain;
}

.homeColumnList a:hover {
  opacity: 0.9;
}

.homeColumnList a:hover:after {
  opacity: 0.7;
}

.homeColumnList__info {
  width: 402px;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__info {
    width: calc(100% - 90px);
  }
}

.homeColumnList__tag {
  min-width: 124px;
  line-height: 32px;
  padding: 0 4px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  display: inline-block;
  border: 1px solid #1871b9;
  color: #1871b9;
  margin-bottom: 13px;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__tag {
    min-width: 62px;
    line-height: 18px;
    font-size: 11px;
    font-size: 1.1rem;
    margin-bottom: 0;
  }
}

.homeColumnList__title {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 7px;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__title {
    font-size: 15px;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
}

.homeColumnList__text {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__text {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.homeColumnList__thumb {
  width: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeColumnList__thumb img {
  flex: 100%;
}

@media only screen and (max-width: 750px) {
  .homeColumnList__thumb {
    width: 75px;
  }
}

.homeInfo {
  margin-bottom: 160px;
}

@media only screen and (max-width: 750px) {
  .homeInfo {
    margin-bottom: 120px;
  }
}

.homeInfoList {
  border-top: 1px solid #e6e6e6;
}

.homeInfoList__item {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media only screen and (max-width: 750px) {
  .homeInfoList__item {
    flex-wrap: wrap;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.homeInfoList a {
  text-decoration: none;
}

.homeInfoList a:hover {
  color: #aa1400;
}

.homeInfoList__date {
  width: 150px;
  padding-top: 6px;
}

@media only screen and (max-width: 750px) {
  .homeInfoList__date {
    width: 80px;
    font-size: 13px;
    font-size: 1.3rem;
    padding-top: 0;
  }
}

.homeInfoList__tag {
  width: 220px;
}

@media only screen and (max-width: 750px) {
  .homeInfoList__tag {
    width: calc(100% - 80px);
  }
}

.homeInfoList__tag span {
  min-width: 150px;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 38px;
  text-align: center;
  display: inline-block;
  border: 1px solid #000;
}

@media only screen and (max-width: 750px) {
  .homeInfoList__tag span {
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 19px;
    min-width: 120px;
  }
}

.homeInfoList__tag span.color-seminar {
  color: #aa1400;
  border-color: #aa1400;
}

.homeInfoList__tag span.color-products {
  color: #646464;
  border-color: #646464;
}

.homeInfoList__tag span.color-works {
  color: #103459;
  border-color: #103459;
}

.homeInfoList__tag span.color-news {
  color: #096383;
  border-color: #096383;
}

.homeInfoList__title {
  width: calc(100% - 400px);
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 5px;
}

@media only screen and (max-width: 750px) {
  .homeInfoList__title {
    width: 100%;
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.5;
  }
}

.strong {
  font-weight: bold;
}

.tCenter {
  text-align: center;
}

.color_red {
  color: #aa1400;
}

.color_blue {
  color: #103459;
}

.color_blue2 {
  color: #096383;
}

.color_gray {
  color: #646464;
}

.fz15 {
  font-size: 15px;
  font-size: 1.5rem;
}

.fz20 {
  font-size: 20px;
  font-size: 2rem;
}

.fz22 {
  font-size: 22px;
  font-size: 2.2rem;
}

.mb0 {
  margin-bottom: 0 !important;
}

.wpPost > *:first-child {
  margin-top: 0;
}

.wpPost img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wpPost .checkListBlock {
  margin-bottom: 75px;
}

.wpPost .checkListBlock h2 {
  font-size: 28px;
  font-size: 2.8rem;
  font-weight: bold;
  color: #aa1400;
  margin-bottom: 8px;
  border-style: none;
  padding-bottom: 0;
  position: relative;
  padding-left: 75px;
}

.wpPost .checkListBlock h2:before {
  content: "";
  background: url(../img/low/icon_question.png) no-repeat;
  background-size: contain;
  width: 64px;
  height: 62px;
  display: inline-block;
  margin-right: 14px;
  position: absolute;
  top: -12px;
  left: 0px;
}

.wpPost .contactTable {
  width: 100%;
  margin-bottom: 50px;
}

.wpPost .contactTable th {
  width: 195px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 28px 20px 28px 0;
  border-top: 1px solid #e6e6e6;
}

@media only screen and (max-width: 750px) {
  .wpPost .contactTable th {
    border-style: none;
  }
}

.wpPost .contactTable td {
  line-height: 1.5;
  padding: 15px 0;
  font-size: 16px;
  font-size: 1.6rem;
  border-top: 1px solid #e6e6e6;
}

.wpPost .contactTable .required {
  color: #aa1400;
  font-size: 14px;
  font-size: 1.4rem;
  margin-left: 8px;
  display: inline-block;
}

.wpPost .contactTable input[type="text"],
.wpPost .contactTable input[type="email"],
.wpPost .contactTable input[type="tel"] {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 15px;
}

.wpPost .contactTable textarea {
  width: 100%;
  padding-left: 15px;
  margin-top: 15px;
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .wpPost .contactTable {
    margin-bottom: 30px;
  }
  .wpPost .contactTable tbody,
  .wpPost .contactTable tr,
  .wpPost .contactTable th,
  .wpPost .contactTable td {
    display: block;
    width: 100%;
  }
  .wpPost .contactTable th {
    padding-bottom: 0;
  }
}

.modFormBtn {
  text-align: center;
}

.modFormBtn input {
  -webkit-appearance: none;
  border-style: none;
  background-color: #f5f5f5;
  padding: 30px 10px;
  max-width: 370px;
  width: 100%;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
}

.modFormBtn input:hover {
  opacity: 0.8;
}

.modFormBtn input[type="submit"] {
  background-color: #1e1e1e;
  color: #fff;
  -webkit-appearance: none;
  border-radius: 0;
}

@media only screen and (max-width: 750px) {
  .sp2clm label.addStyle {
    margin-right: 0;
    min-width: 50%;
  }
}

@media only screen and (min-width: 751px) {
  .btnRows {
    display: flex;
  }
  .btnRows > * {
    margin-right: 33px;
  }
}

.mw_wp_form_confirm .confirmHidden {
  display: none !important;
}

.mw_wp_form_input .inputHidden {
  display: none !important;
}

.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 0 !important;
}

.error404 h2 {
  font-size: 30px;
  color: #FF0004;
  text-align: center;
  padding: 160px 0 60px;
}

.error404 p {
  font-size: 20px;
  text-align: center;
  padding-bottom: 100px;
}

.columnList {
  margin-bottom: 25px;
}

.columnList > .modTitle01 {
  margin-top: 0;
}

.columnList .modPager {
  margin-top: 60px;
}

.columnListItem {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: flex-start;
}

@media only screen and (max-width: 750px) {
  .columnListItem {
    flex-direction: column;
  }
}

.columnListItem a {
  text-decoration: none;
}

.columnListItem a:hover {
  color: #aa1400;
}

.columnListItem__thumb {
  margin-right: 25px;
  flex: none;
  width: 222px;
  display: flex;
}

.columnListItem__thumb a {
  display: flex;
  min-height: 164px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
}

.columnListItem__thumb a img {
  flex: none;
}

@media only screen and (max-width: 750px) {
  .columnListItem__thumb {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.columnListItem__title {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin: 5px 0 10px;
  letter-spacing: 0.05em;
}

.columnListItem__excerpt {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .columnCatBlock {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.columnCatBlock__date {
  font-size: 14px;
  font-size: 1.4rem;
  color: #646464;
  letter-spacing: 0.04em;
  margin-right: 14px;
}

.columnCatBlock__date:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../img/low/icon_clock.png) no-repeat center;
  background-size: contain;
  vertical-align: -3px;
  margin-right: 7px;
}

.columnCatBlock__cat {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7;
  border: 1px solid #096383;
  color: #096383;
  padding: 0 15px;
  display: inline-block;
}

.singlePost > .modTitle01 {
  margin-top: 0;
}

.singlePost .columnCatBlock {
  margin-bottom: 30px;
}

.wpPost_single {
  padding-bottom: 55px;
  margin-bottom: 65px;
  border-bottom: 1px solid #e6e6e6;
}

.wpPost_single > *:last-child {
  margin-bottom: 0;
}

.wpPost .solutionBlock {
  padding: 16px 30px 30px;
  background-color: #f5f5f5;
  margin-top: 100px;
}

.wpPost .solutionBlock > *:last-child {
  margin-bottom: 0;
}

.wpPost .solutionBlock__heading {
  padding: 16px 0px 0px 52px;
  margin-bottom: 25px;
  border-bottom: 2px solid #aa1400;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  position: relative;
}

.wpPost .solutionBlock__heading:before {
  content: "";
  display: inline-block;
  background: url(../img/low/icon_solution.png) no-repeat;
  background-size: contain;
  width: 45px;
  height: 50px;
  margin-right: 10px;
  position: absolute;
  top: 0px;
  left: 0px;
}

.wpPost .beforeIcon__hint {
  margin-left: 50px;
  position: relative;
  border-bottom: 2px solid #aa1400;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: 40px;
}

.wpPost .beforeIcon__hint:before {
  content: "";
  position: absolute;
  top: 0px;
  left: -50px;
  background: url(../img/low/icon_hint.png) no-repeat;
  background-size: contain;
  width: 39px;
  height: 56px;
}

.wpPost .beforeIcon__hint + .modTitle03, .wpPost .beforeIcon__hint + .modTitle03_link {
  margin-top: 0;
}

.wpPost .contactBlock .modTitle02_center {
  margin-bottom: 20px;
}

.wpPost .contactBlock p {
  margin-bottom: 30px;
  text-align: center;
}

.wpPost .contactBlock .modBtnLink {
  margin-top: 30px;
}

.wpPost .contactBlock .modBtnLink a {
  width: 458px;
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 27px;
}

@media only screen and (max-width: 750px) {
  .wpPost .contactBlock .modBtnLink a {
    width: 100%;
    font-size: 13px;
    font-size: 1.3rem;
    padding-top: 17px;
  }
  .wpPost .contactBlock .modBtnLink a:after {
    display: none;
  }
}

.wpPost .recentPost__client {
  color: #646464;
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  border: 1px solid #646464;
  padding: 9px 10px;
  line-height: 1;
}

.wpPost .contactBlock {
  margin-top: 100px;
}

.wpPost .conceptTag {
  display: flex;
  margin-top: 70px;
  margin-bottom: 25px;
}

.wpPost .conceptTag dt {
  padding: 6px 18px;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
}

.wpPost .conceptTag dd {
  padding: 6px 18px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
}

.wpPost .conceptTag.theme_blue dt {
  background-color: #103459;
}

.wpPost .conceptTag.theme_blue dd {
  color: #103459;
  border-color: #103459;
}

.wpPost .conceptTag.theme_red dt {
  background-color: #aa1400;
}

.wpPost .conceptTag.theme_red dd {
  color: #aa1400;
  border-color: #aa1400;
}

.wpPost .partnerBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -40px;
}

.wpPost .partnerBlock__item {
  width: 438px;
  min-height: 320px;
  border: 1px solid #e6e6e6;
  margin-bottom: 40px;
}

.wpPost .partnerBlock__item a {
  display: block;
  padding: 30px 30px 25px;
  height: 100%;
  text-decoration: none;
}

.wpPost .partnerBlock__img {
  display: block;
  text-align: center;
  margin-bottom: 25px;
}

.wpPost .partnerBlock__name {
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
  padding-right: 20px;
  font-size: 16px;
  font-size: 1.6rem;
}

.wpPost .partnerBlock__name:after {
  width: 9px;
  height: 16px;
  content: "";
  position: absolute;
  top: 4px;
  right: 0px;
  background: url(../img/arrow_red.png) no-repeat center;
  background-size: contain;
}

.wpPost .partnerBlock__text {
  display: block;
  font-size: 14px;
  font-size: 1.4rem;
}

.wpPost .recruitInfo {
  padding: 20px 24px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
}

.wpPost .recruitInfo dt {
  font-size: 18px;
  font-size: 1.8rem;
}

.wpPost .recruitInfo dd {
  font-size: 16px;
  font-size: 1.6rem;
}

.productLogoBlock {
  display: flex;
  align-items: flex-end;
}

@media only screen and (max-width: 750px) {
  .productLogoBlock {
    flex-direction: column;
    align-items: flex-start;
  }
}

.productLogoBlock__logo {
  margin-right: 17px;
}

@media only screen and (max-width: 750px) {
  .productLogoBlock__logo {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.productLogoBlock__text {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 1.2;
}

.productRowBlock {
  padding: 45px 30px 50px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  margin-bottom: 50px;
}

@media only screen and (max-width: 750px) {
  .productRowBlock {
    flex-direction: column;
  }
}

.productRowBlock__left > * {
  margin-top: 0 !important;
}

.productRowBlock__right {
  flex: none;
  margin-left: 20px;
}

@media only screen and (max-width: 750px) {
  .productRowBlock__right {
    margin-left: 0;
  }
}

.productFirstImg + .modTitle01 {
  margin-top: 45px;
}

.productSec > *:first-child {
  margin-top: 100px !important;
}

.lpContainer {
  max-width: 1300px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .lpContainer {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.lpHeader {
  padding-top: 33px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .lpHeader {
    padding-top: 10px;
  }
}

.lpIntro {
  padding-bottom: 115px;
}

.lpBeforeBtn {
  text-align: center;
  font-size: 17px;
  font-size: 1.7rem;
  margin-bottom: 30px;
}

.lpBeforeBtn_mt70 {
  margin-top: 70px;
}

.lpGuideFlow {
  padding-top: 155px;
}

.lpText {
  font-size: 17px;
  font-size: 1.7rem;
  margin-bottom: 40px;
}

.lpText_mb20 {
  margin-bottom: 20px;
}

.lpGuideTable {
  padding-top: 155px;
  padding-bottom: 150px;
}

.lpGuideTable__toMap {
  margin-left: 45px;
}

.colorBox a {
  display: block;
  position: relative;
}

.colorBox a:after {
  content: "";
  position: absolute;
  width: 47px;
  height: 47px;
  bottom: 0px;
  right: 0px;
  background: url(../img/lp/icon_zoom.png) no-repeat;
  background-size: contain;
}

.underColorbox {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
  font-size: 1.4rem;
}

.lpFeature__banner {
  margin-top: 60px;
  margin-bottom: 130px;
  text-align: center;
}

.lpFeature__image {
  max-width: 800px;
  margin: 0 auto;
}

.lpFeatureList__title {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin-top: 100px;
}

.lpFeatureList__text {
  font-size: 17px;
  font-size: 1.7rem;
}

.lpSupportLargeText {
  margin-top: 70px;
  margin-bottom: 30px;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

.lpForm {
  max-width: 900px;
  margin: 130px auto 0;
}

.lpForm .contactTable {
  width: 100%;
  margin-bottom: 50px;
}

.lpForm .contactTable th {
  width: 195px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  padding: 25px 20px 25px 0;
  border-top: 1px solid #e6e6e6;
}

@media only screen and (max-width: 750px) {
  .lpForm .contactTable th {
    border-style: none;
  }
}

.lpForm .contactTable td {
  line-height: 1.5;
  padding: 15px 0;
  font-size: 16px;
  font-size: 1.6rem;
  border-top: 1px solid #e6e6e6;
}

.lpForm .contactTable .required {
  color: #aa1400;
  font-size: 14px;
  font-size: 1.4rem;
  margin-left: 8px;
  display: inline-block;
}

.lpForm .contactTable input[type="text"],
.lpForm .contactTable input[type="email"],
.lpForm .contactTable input[type="tel"] {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  width: 100%;
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 15px;
}

.lpForm .contactTable input[type="text"]::-webkit-input-placeholder, .lpForm .contactTable input[type="text"]::-moz-placeholder, .lpForm .contactTable input[type="text"]:-ms-input-placeholder,
.lpForm .contactTable input[type="email"]::-webkit-input-placeholder,
.lpForm .contactTable input[type="email"]::-moz-placeholder,
.lpForm .contactTable input[type="email"]:-ms-input-placeholder,
.lpForm .contactTable input[type="tel"]::-webkit-input-placeholder,
.lpForm .contactTable input[type="tel"]::-moz-placeholder,
.lpForm .contactTable input[type="tel"]:-ms-input-placeholder {
  color: #c8c8c8;
}

.lpForm .contactTable textarea {
  width: 100%;
  padding-left: 15px;
  font-size: 16px;
  font-size: 1.6rem;
  margin-top: 15px;
}

@media only screen and (max-width: 750px) {
  .lpForm .contactTable {
    margin-bottom: 30px;
  }
  .lpForm .contactTable tbody,
  .lpForm .contactTable tr,
  .lpForm .contactTable th,
  .lpForm .contactTable td {
    display: block;
    width: 100%;
  }
  .lpForm .contactTable th {
    padding-bottom: 0;
  }
}

.clearBtn {
  -webkit-appearance: none;
  border-style: none;
  background-color: #c8c8c8;
  padding: 30px 10px;
  max-width: 240px;
  width: 100%;
  display: inline-block;
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 30px;
}

.clearBtn:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 750px) {
  .clearBtn {
    max-width: initial;
    margin-bottom: 5px;
  }
}

.lpFooter {
  padding: 120px 0 40px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .lpFooter {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* CSS Document */
.container {
  max-width: 1330px;
}

@media only screen and (max-width: 750px) {
  .container {
    max-width: 750px;
  }
}

@media only screen and (min-width: 751px) {
  .pc-non {
    display: none !important;
  }
}

@media only screen and (max-width: 750px) {
  .sp-non {
    display: none !important;
  }
}

/*
#overview
modTitle
*/
.modTitle__anker {
  position: absolute;
  right: 0px;
  top: 0px;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
  font-size: 1.3rem;
  line-height: 28px;
  min-width: 144px;
  color: #fff;
  padding: 0 5px;
  background-color: #1e1e1e;
  display: inline-block;
  text-align: center;
}

.modTitle__anker:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 13px;
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
  width: 7px;
  height: 12px;
}

/*
#styleguide
modTitle01

```
<h1 class="modTitle01">見出し１</h1>
```
*/
.modTitle01 {
  font-size: 40px;
  font-size: 4rem;
  margin-bottom: 40px;
  margin-top: 80px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle01 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.6;
  }
}

/*
#styleguide
modTitle02
modTitle02_link
modTitle02_center

```
<h2 class="modTitle02">見出し２</h2>
<h2 class="modTitle02_link">見出し２<a href="#" class="modTitle__anker">詳しくはこちら</a></h2>
<h2 class="modTitle02_center">見出し２　中央寄せ版</h2>
```
*/
.modTitle02, .modTitle02_link {
  font-size: 24px;
  font-size: 2.4rem;
  padding-bottom: 6px;
  border-bottom: 2px solid #aa1400;
  margin-top: 80px;
  margin-bottom: 45px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle02, .modTitle02_link {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}

.modTitle02_link {
  position: relative;
  padding-right: 172px;
}

.modTitle02_link .modTitle__anker {
  top: 10px;
}

@media only screen and (max-width: 750px) {
  .modTitle02_link {
    padding-right: 0;
    padding-bottom: 40px;
  }
  .modTitle02_link .modTitle__anker {
    top: auto;
    bottom: 10px;
  }
}

.modTitle02_center {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  border-style: none;
  padding-bottom: 10px;
  margin-bottom: 45px;
  margin-top: 80px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle02_center {
    font-size: 20px;
    font-size: 2rem;
    line-height: 1.5;
  }
}

.modTitle02_center:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 8px;
  background: url(../img/icon_h2.png) no-repeat center;
  background-size: 16px 8px;
}

/*
#styleguide
modTitle03
modTitle03_link

```
<h3 class="modTitle03">見出し３</h3>
<h3 class="modTitle03_link">見出し３<a href="#" class="modTitle__anker">詳しくはこちら</a></h3>
```
*/
.modTitle03, .modTitle03_link {
  font-size: 20px;
  font-size: 2rem;
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  letter-spacing: 0.08em;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle03, .modTitle03_link {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.6;
  }
}

.modTitle03:after, .modTitle03_link:after {
  width: 34px;
  height: 2px;
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: linear-gradient(90deg, #aa1400 0%, #aa1400 80%, #444444 80%, #444444 100%);
}

.modTitle03_link {
  position: relative;
  padding-right: 172px;
}

.modTitle03_link .modTitle__anker {
  top: 7px;
}

@media only screen and (max-width: 750px) {
  .modTitle03_link {
    padding-right: 0;
    padding-bottom: 40px;
  }
  .modTitle03_link .modTitle__anker {
    top: auto;
    bottom: 10px;
  }
}

/*
#styleguide
modTitle04
modTitle04_link

```
<h4 class="modTitle04">見出し４</h4>
<h4 class="modTitle04_link">見出し４<a href="#" class="modTitle__anker">詳しくはこちら</a></h4>
```
*/
.modTitle04, .modTitle04_link {
  font-size: 18px;
  font-size: 1.8rem;
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle04, .modTitle04_link {
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 1.6;
  }
}

.modTitle04_link {
  position: relative;
  padding-right: 172px;
}

.modTitle04_link .modTitle__anker {
  top: 6px;
}

@media only screen and (max-width: 750px) {
  .modTitle04_link {
    padding-right: 0;
    padding-bottom: 40px;
  }
  .modTitle04_link .modTitle__anker {
    top: auto;
    bottom: 10px;
  }
}

/*
#styleguide
modTitle05

```
<h5 class="modTitle05">見出し５</h5>
```
*/
.modTitle05 {
  font-size: 16px;
  font-size: 1.6rem;
  position: relative;
  padding-bottom: 8px;
  margin-top: 90px;
  margin-bottom: 15px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle05 {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.6;
  }
}

/*
#styleguide
modTitleEnBack

```
<h2 class="modTitleEnBack"><span class="en">CATCH COPY</span>見出し</h2>
```
*/
.modTitleEnBack {
  font-size: 40px;
  font-size: 4rem;
  padding-top: 16px;
  padding-bottom: 12px;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
  z-index: 2;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitleEnBack {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 28px;
    padding-bottom: 25px;
    margin-bottom: 12px;
    width: 100vw;
    margin-left: -20px;
  }
}

.modTitleEnBack:after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 8px;
  background: url(../img/icon_h2.png) no-repeat center;
  background-size: 16px 8px;
}

.modTitleEnBack .en {
  position: absolute;
  z-index: -1;
  top: -24px;
  left: 0px;
  width: 100%;
  font-size: 60px;
  font-size: 6rem;
  color: #828282;
  opacity: 0.2;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 750px) {
  .modTitleEnBack .en {
    font-size: 32px;
    font-size: 3.2rem;
    line-height: 28px;
    top: 0;
    letter-spacing: -0.03em;
  }
}

.modTitleEnBack.row2 {
  margin-top: 40px;
}

.modTitleEnBack.row2 .en {
  top: -24px;
}

.modTitleEnBack.color-2 {
  text-shadow: 0px 0px 13px #aa1400;
}

.modTitleEnBack.color-2 .en {
  color: #fff;
  opacity: 0.5;
  text-shadow: 0 0 0 transparent;
}

.modTitleEnBack.color-2:after {
  background-image: url(../img/icon_h2_2.png);
}

/*
#styleguide
modTitle06

```
<h1 class="modTitle06 modTitle06_blue">見出し</h1>
<h1 class="modTitle06 modTitle06_red">見出し</h1>
<h1 class="modTitle06 modTitle06_gray">見出し</h1>
```
*/
.modTitle06 {
  padding-top: 144px;
  padding-bottom: 54px;
  color: #fff;
  font-size: 42px;
  font-size: 4.2rem;
  text-align: center;
  background: url(../img/lp/icon_shake.png) no-repeat center 76px;
  background-size: 90px auto;
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1341px) {
  .modTitle06 {
    padding-left: calc((100% - 1300px)/2);
    padding-right: calc((100% - 1300px)/2);
  }
}

@media only screen and (max-width: 750px) {
  .modTitle06 {
    font-size: 24px;
    font-size: 2.4rem;
    line-height: 1.4;
    background-size: 82px auto;
    padding-top: 110px;
    padding-bottom: 35px;
    background-position: center 39px;
  }
}

.modTitle06_blue {
  background-color: #103459;
}

.modTitle06_red {
  background-color: #aa1400;
}

.modTitle06_gray {
  background-color: #e6e6e6;
  color: #444;
  background-image: url(../img/lp/icon_shake_black.png);
}

/*
#styleguide
modTitle07

```
<h2 class="modTitle07 modTitle07_red">見出し</h2>
<h2 class="modTitle07 modTitle07_blue">見出し</h2>
<h2 class="modTitle07 modTitle07_gray">見出し</h2>
```
*/
.modTitle07 {
  padding: 19px 36px;
  font-size: 32px;
  font-size: 3.2rem;
  color: #fff;
  margin-bottom: 30px;
}

@media only screen and (max-width: 750px) {
  .modTitle07 {
    font-size: 23px;
    font-size: 2.3rem;
    padding: 15px 25px;
  }
}

.modTitle07_blue {
  background-color: #103459;
}

.modTitle07_red {
  background-color: #aa1400;
}

.modTitle07_gray {
  background-color: #e6e6e6;
  color: #444;
}

/*
#styleguide
modTitle08

```
<h3 class="modTitle08 modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_caution modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_arrow modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_check modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_question modTitle08_gray">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_red">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_blue">見出し</h3>
<h3 class="modTitle08 modTitle08_man modTitle08_gray">見出し</h3>
```
*/
.modTitle08 {
  margin-bottom: 40px;
  margin-top: 70px;
  padding: 5px 24px;
  line-height: 1.7;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
}

.modTitle08_red {
  background-color: #aa1400;
}

.modTitle08_blue {
  background-color: #103459;
}

.modTitle08_gray {
  background-color: #e6e6e6;
  color: #444;
}

.modTitle08_light:before {
  content: "";
  background: url(../img/lp/icon_light.png) no-repeat;
  background-size: contain;
  width: 18px;
  height: 26px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_caution:before {
  content: "";
  background: url(../img/lp/icon_caution.png) no-repeat;
  background-size: contain;
  width: 5px;
  height: 23px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_caution.modTitle08_gray:before {
  background-image: url(../img/lp/icon_caution_black.png);
}

.modTitle08_arrow:before {
  content: "";
  background: url(../img/lp/icon_arrow.png) no-repeat;
  background-size: contain;
  width: 20px;
  height: 22px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_arrow.modTitle08_gray:before {
  background-image: url(../img/lp/icon_arrow_black.png);
}

.modTitle08_check:before {
  content: "";
  background: url(../img/lp/icon_check.png) no-repeat;
  background-size: contain;
  width: 22px;
  height: 16px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_check.modTitle08_gray:before {
  background-image: url(../img/lp/icon_check_black.png);
}

.modTitle08_question:before {
  content: "";
  background: url(../img/lp/icon_question.png) no-repeat;
  background-size: contain;
  width: 16px;
  height: 25px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_question.modTitle08_gray:before {
  background-image: url(../img/lp/icon_question_black.png);
}

.modTitle08_man:before {
  content: "";
  background: url(../img/lp/icon_man.png) no-repeat;
  background-size: contain;
  width: 23px;
  height: 23px;
  display: inline-block;
  vertical-align: -3px;
  margin-right: 20px;
}

.modTitle08_man.modTitle08_gray:before {
  background-image: url(../img/lp/icon_man_black.png);
}

/*
#styleguide
modTitle09

```
<h4 class="modTitle09 modTitle09_red">見出し</h4>
<h4 class="modTitle09 modTitle09_blue">見出し</h4>
<h4 class="modTitle09 modTitle09_gray">見出し</h4>

<h4 class="modTitle09 modTitle09_red"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
<h4 class="modTitle09 modTitle09_blue"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
<h4 class="modTitle09 modTitle09_gray"><span class="modTitle09__tag">題名</span><span class="modTitle09__text">見出し</span></h4>
```
*/
.modTitle09 {
  display: flex;
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  margin-bottom: 30px;
  line-height: 1.8;
}

@media only screen and (max-width: 750px) {
  .modTitle09 {
    display: block;
  }
}

.modTitle09__tag {
  color: #fff;
  padding: 0 15px;
  margin-right: 15px;
  font-size: 20px;
  font-size: 2rem;
}

@media only screen and (max-width: 750px) {
  .modTitle09__tag {
    display: inline-block;
    margin-right: 0;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 750px) {
  .modTitle09__text {
    display: block;
  }
}

.modTitle09_red {
  border-color: #aa1400;
}

.modTitle09_red .modTitle09__tag {
  background: #aa1400;
}

.modTitle09_blue {
  border-color: #103459;
}

.modTitle09_blue .modTitle09__tag {
  background: #103459;
}

.modTitle09_gray {
  border-color: #e6e6e6;
}

.modTitle09_gray .modTitle09__tag {
  background: #e6e6e6;
  color: #444;
}

/*
#styleguide
lpQuestionHeadline

```
<h3 class="lpQuestionHeadline">このようなお悩みのある企業様、ぜひお越しください。<br /><span class="strong">システム導入による在庫適正化・業務改革の実現可能性があります！</span></h3>
```
*/
.lpQuestionHeadline {
  padding: 20px 60px 25px;
  background: #aa1400 url(../img/lp/icon_question.png) no-repeat 29px 24px;
  background-size: 17px auto;
  color: #fff;
  font-size: 24px;
  font-size: 2.4rem;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media only screen and (max-width: 750px) {
  .lpQuestionHeadline {
    font-size: 21px;
    font-size: 2.1rem;
    padding: 15px 39px 15px 55px;
  }
}

/*
#overview
modText
*/
/*
#styleguide
modText

```
<p class="modText">テキスト</p>
```
*/
.modText {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1;
  letter-spacing: 0.05em;
  margin-bottom: 50px;
  margin-top: 10px;
}

/*
#styleguide
modText_mbShort

```
<p class="modText_mbShort">マージンボトムが狭めのテキスト</p>
```
*/
.modText_mbShort {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1;
  letter-spacing: 0.05em;
  margin-bottom: 15px;
  margin-top: 10px;
}

/*
#styleguide
modText_large

```
<p class="modText_large">大きいテキスト</p>
<p class="modText_large color_red">大きいテキスト</p>
<p class="modText_large color_blue">大きいテキスト</p>
<p class="modText_large color_gray">大きいテキスト</p>
```
*/
.modText_large {
  font-size: 20px;
  font-size: 2rem;
  margin-bottom: 10px;
  margin-top: 40px;
  font-weight: bold;
  line-height: 1.4;
}

/*
トップページ用
*/
.modTxtUnderTitle {
  font-size: 16px;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 70px;
}

@media only screen and (max-width: 750px) {
  .modTxtUnderTitle {
    font-size: 13px;
    font-size: 1.3rem;
    margin-bottom: 30px;
    line-height: 1.6;
  }
}

/*
#overview
modBtn
*/
/*
#styleguide
modBtn01
modBtn02
modBtn03
modBtn04

```
<div class="modBtn01"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_ptnConcept"><a href="#">サンプルテキスト</a></div>
<div class="modBtn02"><a href="#">サンプルテキスト</a></div>
<div class="modBtn03"><a href="#">サンプルテキスト</a></div>
<div class="modBtn04"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_blue modBtn01_large"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01 modBtn01_red modBtn01_large"><a href="#">サンプルテキスト</a></div>
<div class="modBtn01  modBtn01_large"><a href="#">サンプルテキスト</a></div>
```
*/
.modBtn01 {
  margin-bottom: 45px;
}

.modBtn01 a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 30px 60px;
  min-width: 370px;
  text-align: center;
  text-decoration: none;
  position: relative;
  color: #fff;
  background-color: #1e1e1e;
}

@media only screen and (max-width: 750px) {
  .modBtn01 a {
    min-width: initial;
    width: 100%;
  }
}

.modBtn01 a:after {
  content: "";
  position: absolute;
  background: url(../img/arrow_white.png) no-repeat;
  background-size: contain;
  right: 24px;
  top: 50%;
  width: 27px;
  height: 6px;
  margin-top: -3px;
}

.modBtn01_ptnConcept a {
  width: 100%;
  max-width: 428px;
  font-size: 20px;
  font-size: 2rem;
  padding: 27px 60px;
}

.modBtn01_red a {
  background-color: #aa1400;
}

.modBtn01_blue a {
  background-color: #103459;
}

.modBtn01_large a {
  width: 100%;
  max-width: 800px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn01_middle a {
  width: 100%;
  max-width: 520px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn02 {
  margin-bottom: 45px;
}

.modBtn02 a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 30px 60px;
  min-width: 370px;
  text-align: center;
  text-decoration: none;
  position: relative;
  color: #444;
  background-color: #c8c8c8;
}

@media only screen and (max-width: 750px) {
  .modBtn02 a {
    min-width: initial;
    width: 100%;
  }
}

.modBtn02 a:after {
  content: "";
  position: absolute;
  background: url(../img/arrow_black.png) no-repeat;
  background-size: contain;
  right: 24px;
  top: 50%;
  width: 27px;
  height: 6px;
  margin-top: -3px;
}

.modBtn02_large a {
  width: 100%;
  max-width: 800px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn02_middle a {
  width: 100%;
  max-width: 520px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn03 {
  margin-bottom: 45px;
}

.modBtn03 a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 30px 60px;
  min-width: 370px;
  text-align: center;
  text-decoration: none;
  position: relative;
  color: #444;
  border: 1px solid #444;
}

@media only screen and (max-width: 750px) {
  .modBtn03 a {
    min-width: initial;
    width: 100%;
  }
}

.modBtn03 a:after {
  content: "";
  position: absolute;
  background: url(../img/arrow_black.png) no-repeat;
  background-size: contain;
  right: 24px;
  top: 50%;
  width: 27px;
  height: 6px;
  margin-top: -3px;
}

.modBtn03_large a {
  width: 100%;
  max-width: 800px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn03_middle a {
  width: 100%;
  max-width: 520px;
  font-size: 24px;
  font-size: 2.4rem;
}

.modBtn04 {
  margin-bottom: 45px;
}

.modBtn04 a {
  display: inline-block;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 30px 60px;
  min-width: 370px;
  text-align: center;
  text-decoration: none;
  position: relative;
  color: #444;
  border: 1px solid #aa1400;
}

@media only screen and (max-width: 750px) {
  .modBtn04 a {
    min-width: initial;
    width: 100%;
  }
}

.modBtn04 a:after {
  content: "";
  position: absolute;
  background: url(../img/arrow_black.png) no-repeat;
  background-size: contain;
  right: 24px;
  top: 50%;
  width: 27px;
  height: 6px;
  margin-top: -3px;
}

/*
トップページ用
*/
.modBtnLink {
  margin-top: 50px;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .modBtnLink {
    margin-top: 28px;
  }
}

.modBtnLink a {
  display: inline-block;
  background-color: #1e1e1e;
  color: #fff;
  font-size: 20px;
  width: 380px;
  padding: 28px 50px;
  line-height: 1.2;
  text-decoration: none;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .modBtnLink a {
    width: 100%;
    font-size: 14px;
    padding: 19px 50px;
  }
}

.modBtnLink a:after {
  content: "";
  position: absolute;
  width: 27px;
  height: 5px;
  background: url(../img/arrow_white.png) no-repeat;
  background-size: contain;
  right: 17px;
  top: 50%;
  margin-top: -3px;
}

.modBtnLink-row4 {
  display: flex;
  justify-content: space-between;
}

.modBtnLink-row4 a {
  width: 290px;
  font-size: 16px;
  padding-top: 28px;
}

@media only screen and (max-width: 750px) {
  .modBtnLink-row4 {
    flex-wrap: wrap;
  }
  .modBtnLink-row4 a {
    width: 100%;
    font-size: 14px;
    padding: 19px 50px;
    margin-bottom: 16px;
  }
}

/*
#overview
modBlock
*/
/*
#styleguide
modBlock01
modBlock02
modBlock03
modBlock04

```
<div class="modBlock01">
  <h3 class="modTitle03">H3タイトルが入りますタイトルが入ります</h3>
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock02">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock03">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
<div class="modBlock04">
  <p class="modText">テキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト</p>
</div>
```
*/
.modBlock01 {
  padding: 30px 30px 40px;
  background-color: #f5f5f5;
  margin-bottom: 55px;
}

.modBlock01 > *:first-child {
  margin-top: 0;
}

.modBlock01 > *:last-child {
  margin-bottom: 0;
}

.modBlock02 {
  padding: 30px 30px 40px;
  border: 1px solid #c8c8c8;
  margin-bottom: 55px;
}

.modBlock02 > *:first-child {
  margin-top: 0;
}

.modBlock02 > *:last-child {
  margin-bottom: 0;
}

.modBlock03 {
  padding: 30px 30px 40px;
  border: 1px solid #1e1e1e;
  margin-bottom: 55px;
}

.modBlock03 > *:first-child {
  margin-top: 0;
}

.modBlock03 > *:last-child {
  margin-bottom: 0;
}

.modBlock04 {
  padding: 30px 30px 40px;
  border: 1px solid #aa1400;
  margin-bottom: 55px;
}

.modBlock04 > *:first-child {
  margin-top: 0;
}

.modBlock04 > *:last-child {
  margin-bottom: 0;
}

/*
#styleguide
modPager

```
<div class="modPager">
  <div class="wp-pagenavi">
    <a href="#" class="prevpostslink">前へ</a>
    <span class="current">1</span>
    <a href="#" class="page">2</a>
    <a href="#" class="page">3</a>
    <a href="#" class="page">4</a>
    <a href="#" class="nextpostslink">次へ</a>
  </div>
</div>
```
*/
.modPager {
  margin-top: 40px;
}

.modPager .wp-pagenavi {
  text-align: center;
}

.modPager .page {
  text-decoration: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-size: 1.4rem;
  border: 1px solid #c8c8c8;
  line-height: 1;
  padding-top: 4px;
}

.modPager .page:hover,
.modPager .current {
  text-decoration: none;
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #aa1400;
  border: 1px solid #aa1400;
  line-height: 1;
  padding-top: 4px;
}

.modPager .page,
.modPager .current {
  margin: 0 6px;
}

.modPager .prevpostslink {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 15px;
  text-decoration: none;
  line-height: 1.8;
}

.modPager .prevpostslink:before {
  content: "";
  display: inline-block;
  background: url(../img/arrow_pager.png) no-repeat;
  background-size: contain;
  width: 7px;
  height: 13px;
  transform: rotate(180deg);
  margin-right: 13px;
}

.modPager .nextpostslink {
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: 15px;
  text-decoration: none;
  line-height: 1.8;
}

.modPager .nextpostslink:after {
  content: "";
  display: inline-block;
  background: url(../img/arrow_pager.png) no-repeat;
  background-size: contain;
  width: 7px;
  height: 13px;
  margin-left: 13px;
}

/*
#styleguide
modBlockSubjectList
modBlockSubjectList_clm2

```
<div class="modBlockSubjectList">
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">在庫適正化・PSI・計画業務</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">在庫削減・在庫適正化</a></li>
				<li><a href="">在庫の見える化（可視化）</a></li>
				<li><a href="">需要予測（出荷予測）の精度向上</a></li>
				<li><a href="">生産計画の精度向上</a></li>
				<li><a href="">需給調整業務、表計算からの脱却需給調整業務、表計算からの脱却</a></li>
				<li><a href="">複数倉庫の在庫適正配分複数倉庫の在庫適正配分複数倉庫の</a></li>
				<li><a href="">発注業務の効率化</a></li>
				<li><a href="">デッドストック化の防止</a></li>
				<li><a href="">特売・催事に対応した発注</a></li>
			</ul>
		</div>
	</div>
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">販売物流業務・オーダーマネジメント</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">正確な在庫引当て</a></li>
				<li><a href="">即時納期回答（サービス向上）</a></li>
				<li><a href="">受注手配業務の効率化</a></li>
				<li><a href="">賞味期限・有効期限管理の徹底</a></li>
				<li><a href="">納品問合せ対応</a></li>
				<li><a href="">トラブル時の再手配</a></li>
				<li><a href="">納品リードタイムの短縮</a></li>
				<li><a href="">バックオーダー管理業務の効率化</a></li>
				<li><a href="">物流環境変化への対応</a></li>
			</ul>
		</div>
	</div>
</div><!--/modBlockSubjectList-->
<div class="modBlockSubjectList modBlockSubjectList_clm2">
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">在庫適正化・PSI・計画業務</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">在庫削減・在庫適正化</a></li>
				<li><a href="">在庫の見える化（可視化）</a></li>
				<li><a href="">需要予測（出荷予測）の精度向上</a></li>
				<li><a href="">生産計画の精度向上</a></li>
				<li><a href="">需給調整業務、表計算からの脱却需給調整業務、表計算からの脱却</a></li>
				<li><a href="">複数倉庫の在庫適正配分複数倉庫の在庫適正配分複数倉庫の</a></li>
				<li><a href="">発注業務の効率化</a></li>
				<li><a href="">デッドストック化の防止</a></li>
				<li><a href="">特売・催事に対応した発注</a></li>
			</ul>
		</div>
	</div>
	<div class="modBlockSubjectList__item">
		<h2 class="modBlockSubjectList__title">販売物流業務・オーダーマネジメント</h2>
		<div class="modBlockSubjectList__inner">
			<div class="modBlockSubjectList__thumb"><img src="http://placehold.jp/242x242.png"></div>
			<ul class="modBlockSubjectList__link">
				<li><a href="">正確な在庫引当て</a></li>
				<li><a href="">即時納期回答（サービス向上）</a></li>
				<li><a href="">受注手配業務の効率化</a></li>
				<li><a href="">賞味期限・有効期限管理の徹底</a></li>
				<li><a href="">納品問合せ対応</a></li>
				<li><a href="">トラブル時の再手配</a></li>
				<li><a href="">納品リードタイムの短縮</a></li>
				<li><a href="">バックオーダー管理業務の効率化</a></li>
				<li><a href="">物流環境変化への対応</a></li>
			</ul>
		</div>
	</div>
</div><!--/modBlockSubjectList-->
```
*/
.modBlockSubjectList {
  padding-top: 10px;
}

.modBlockSubjectList__item {
  background-color: #fff;
  margin-top: 40px;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList__item {
    width: 100%;
  }
}

.modBlockSubjectList__inner {
  display: flex;
  justify-content: space-between;
  padding: 45px;
  box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.1);
  align-items: flex-start;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList__inner {
    padding: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList__thumb {
    width: 85px;
  }
}

.modBlockSubjectList__link {
  width: calc(100% - 280px);
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList__link {
    width: calc(100% - 100px);
  }
}

.modBlockSubjectList li {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 25px;
  width: 292px;
  line-height: 1.8;
  margin-bottom: 30px;
}

.modBlockSubjectList li:nth-child(3n) {
  margin-right: 0;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList li {
    margin-bottom: 0;
  }
}

.modBlockSubjectList a {
  text-decoration: none;
  padding-right: 20px;
  display: inline-block;
  max-width: 100%;
  position: relative;
}

.modBlockSubjectList a:hover {
  opacity: 1;
  color: #aa1400;
}

.modBlockSubjectList a:after {
  width: 7px;
  height: 13px;
  background: url(../img/home/arrow_red.png) no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 7px;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList a:after {
    width: 5px;
    top: 8px;
  }
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList a {
    font-size: 12px;
    font-size: 1.2rem;
    padding-left: 1.1em;
    padding-right: 0;
  }
  .modBlockSubjectList a:after {
    left: 0px;
    right: auto;
  }
}

.modBlockSubjectList__title {
  padding: 8px;
  background-color: #aa1400;
  text-align: center;
  font-size: 18px;
  font-size: 1.8rem;
  color: #fff;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList__title {
    font-size: 14px;
    font-size: 1.4rem;
    padding: 4px 8px;
  }
}

.modBlockSubjectList_clm2 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList_clm2 {
    flex-direction: column;
  }
}

.modBlockSubjectList_clm2 .modBlockSubjectList__item {
  width: 630px;
  background-color: #fff;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList_clm2 .modBlockSubjectList__item {
    width: 100%;
  }
}

.modBlockSubjectList_clm2 .modBlockSubjectList__inner {
  box-shadow: 0px 0px 0px 0px transparent;
}

.modBlockSubjectList_clm2 .modBlockSubjectList__link {
  width: 265px;
}

@media only screen and (max-width: 750px) {
  .modBlockSubjectList_clm2 .modBlockSubjectList__link {
    width: calc(100% - 100px);
  }
}

.modBlockSubjectList_clm2 li {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 0;
  margin-right: 0;
}

.modBlockSubjectList_clm2 a {
  padding-right: 23px;
}

.modBlockSubjectList_clm2 a:after {
  top: 9px;
}

/*
#styleguide
modBlockSolution
modBlockSolution_low

```
<div class="modBlockSolution">
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">賞味期限・ロット別在庫への自動引当</span><span class="modBlockSolution__text">食品業界必須の賞味期限管理。産地など属性別の在庫を自動引当し、業務コストを抑えて販売物流の業務スピード向上</span><span class="modBlockSolution__tag">食品流通</span></a>
	</div>
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">需給調整スピードアップ、生産計画精度向上</span><span class="modBlockSolution__text">PSI可視化・コントロールで需給調整業務を支援し、クイックレスポンスの実現へ</span><span class="modBlockSolution__tag">食品製造</span></a>
	</div>
</div><!--/modBlockSolution-->
<div class="modBlockSolution modBlockSolution_low">
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">賞味期限・ロット別在庫への自動引当</span><span class="modBlockSolution__text">食品業界必須の賞味期限管理。産地など属性別の在庫を自動引当し、業務コストを抑えて販売物流の業務スピード向上</span><span class="modBlockSolution__tag">食品流通</span></a>
	</div>
	<div class="modBlockSolution__item">
		<a href=""><span class="modBlockSolution__thumb"><img src="http://placehold.jp/630x226.png"></span><span class="modBlockSolution__title">需給調整スピードアップ、生産計画精度向上</span><span class="modBlockSolution__text">PSI可視化・コントロールで需給調整業務を支援し、クイックレスポンスの実現へ</span><span class="modBlockSolution__tag">食品製造</span></a>
	</div>
</div><!--/modBlockSolution-->
```
*/
.modBlockSolution {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -40px;
}

.modBlockSolution__item {
  width: 630px;
  background-color: #fff;
  margin-bottom: 40px;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__item {
    margin: 0 auto 30px;
  }
}

.modBlockSolution a {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 0 30px 30px;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution a {
    padding: 0 13px 13px;
  }
}

.modBlockSolution__thumb {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-bottom: 12px;
  display: block;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__thumb {
    width: calc(100% + 26px);
    margin-left: -13px;
  }
}

.modBlockSolution__tag {
  text-align: center;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 110px;
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #aa1400;
  line-height: 1.4;
  font-size: 14px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__tag {
    width: 100px;
    height: 48px;
    font-size: 11px;
    font-size: 1.1rem;
    line-height: 1.2;
    letter-spacing: -0.02em;
  }
}

.modBlockSolution__title {
  font-size: 20px;
  font-size: 2rem;
  padding-right: 23px;
  position: relative;
  display: inline-block;
  display: block;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__title {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.2;
  }
}

.modBlockSolution__title:after {
  content: "";
  width: 10px;
  height: 17px;
  background: url(../img/home/arrow_red.png) no-repeat;
  background-size: contain;
  display: inline-block;
  position: absolute;
  right: 0px;
  top: 10px;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__title:after {
    width: 7px;
    top: 4px;
  }
}

.modBlockSolution__title small {
  font-size: 16px;
  font-size: 1.6rem;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__title small {
    font-size: 11px;
    font-size: 1.1rem;
  }
}

.modBlockSolution__text {
  margin-top: 10px;
  font-size: 16px;
  font-size: 1.6rem;
  padding-top: 14px;
  position: relative;
  display: block;
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__text {
    font-size: 13px;
    font-size: 1.3rem;
  }
}

.modBlockSolution__text:before {
  width: 33px;
  height: 2px;
  display: inline-block;
  content: "";
  top: 0px;
  left: 0px;
  position: absolute;
  background: linear-gradient(90deg, #aa1400 0%, #aa1400 80%, #444444 80%, #444444 100%);
}

@media only screen and (max-width: 750px) {
  .modBlockSolution__text:before {
    width: 20px;
  }
}

.modBlockSolution_low {
  margin-top: 100px;
}

.modBlockSolution_low.modBlockSolution a {
  padding: 0 20px 20px;
}

.modBlockSolution_low .modBlockSolution__item {
  width: 438px;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
}

.modBlockSolution_low .modBlockSolution__thumb {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}

.modBlockSolution_low .modBlockSolution__title {
  line-height: 1.3;
}

/*
#styleguide
recentPostBlock

```
<div class="recentPostBlock">
<div class="recentPostBlock__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/thumb_sks.jpg" alt=""></div>
<p class="recentPostBlock__text"><span class="recentPost__client">精密機械商社</span><br>
<a class="modLinkArrow">株式会社清光社様</a><br>
<span class="strong">需給調整業務をExcel管理からシステム化。業務標準化・作業効率化・在庫有効活用に効果あり</span><br>
運動機械部品のメーカーとユーザーを結ぶ専門商社、株式会社清光社様のご活用事例です。</p>
</div>
```
*/
.recentPostBlock {
  margin-bottom: 50px;
  display: flex;
}

.recentPostBlock a {
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .recentPostBlock {
    flex-direction: row;
  }
  .recentPostBlock > *:first-child {
    margin-right: 10px;
  }
}

.recentPostBlock__img {
  width: 120px;
  margin-right: 30px;
}

@media only screen and (max-width: 750px) {
  .recentPostBlock__img {
    width: 80px;
    margin-right: 15px;
  }
}

.recentPostBlock__text {
  flex: 1;
  font-size: 16px;
  font-size: 1.6rem;
}

/*
#styleguide
textImgRow

```
<div class="textImgRow">
<p class="modText">商品ライフサイクルが短縮化している近年、いわゆる「定番品」の比率が低下し、需要予測は大変困難な時代です。現場では特売やキャンペーンなどイベントも多く、予測の課題は増すばかり。いまだ表計算で予測を行っている企業様は、限界があるように感じてはいるものの、高価な予測システムを入れるほどでは…と思われているのではないでしょうか。<br>
また、営業部の作成した予測が、全社計画のベースになる場合も多いですが、「在庫計画上は疑問も多い」といった声も聞かれます</p>
<div class="textImgRow__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/img_solution_human.jpg" alt=""></div>
</div>
```
*/
.textImgRow {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .textImgRow {
    flex-direction: column;
  }
}

.textImgRow__img {
  flex: none;
}

@media only screen and (max-width: 750px) {
  .textImgRow__img {
    text-align: center;
  }
}

.textImgRow p {
  margin-top: 0;
}

.textImgRow > *:first-child {
  margin-right: 45px;
}

@media only screen and (max-width: 750px) {
  .textImgRow > *:first-child {
    margin-bottom: 20px;
    margin-right: 0px;
  }
}

/*
#styleguide
lpIntro

```
<div class="lpHero">
<div class="lpContainer">
<div class="lpHero__inner">
<div class="lpHero__left">
<p class="lpHero__title">在庫/販売物流<br />イノベーションセミナー＆<br />相談会</p>
<p class="lpHero__location">会場：東京都千代田区（神田）</p>
</div>
<div class="lpHero__right"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_hero.png" alt="今こそ、業務の改革を。" /></div>
</div>
</div>
</div>
```
*/
.lpHero {
  height: 428px;
  background: url(../img/lp/bg_hero.jpg) no-repeat center;
  background-size: cover;
  margin-bottom: 75px;
}

@media only screen and (max-width: 750px) {
  .lpHero {
    height: auto;
    padding: 60px 0;
  }
}

.lpHero__inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .lpHero__inner {
    flex-direction: column;
  }
}

.lpHero__left {
  padding-top: 110px;
  padding-left: 35px;
  width: 590px;
}

@media only screen and (max-width: 750px) {
  .lpHero__left {
    padding-top: 0;
    width: auto;
    padding-left: 0;
    padding-bottom: 20px;
  }
}

.lpHero__title {
  font-size: 47px;
  font-size: 4.7rem;
  line-height: 1.4;
  margin-bottom: 27px;
  letter-spacing: -0.03em;
}

@media only screen and (max-width: 750px) {
  .lpHero__title {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.lpHero__location {
  background-color: #103459;
  color: #fff;
  font-size: 23px;
  padding: 3px;
  text-align: center;
}

/*
#styleguide
lpIntro

```
<div class="lpIntroTop">
<div class="lpIntroTopLeft">
<div class="lpIntroTopLeft__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/logo_conductor.png" alt="Conductor for Foods" /></div>
<p class="lpIntroTopLeft__text">食品業界向け</p>
</div>
<div class="lpIntroTopRight">
<div class="lpIntroTopRight__contact">
<p class="lpIntroTopRight__tag">お問合せ</p>
<p class="lpIntroTopRight__tel"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_tel.png" alt="" width="20" />03-5822-6511</p>
</div>
<p class="lpIntroTopRight__name">株式会社フェアウェイソリューションズ</p>
</div>
</div>
<div class="lpIntroBottom">
<div class="lpIntroBottomLeft">
<p class="lpIntroBottomLeft__tag">業務支援型</p>
<p class="lpIntroBottomLeft__text">販売物流ソリューション</p>
</div>
<ul class="lpIntroBottomRight">
<li>
<p class="lpIntroBottomRight__title">商社卸売業</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_track.png" alt="" width="71" /></div>
<p class="lpIntroBottomRight__description">食肉・生鮮食品</p>
</li>
<li>
<p class="lpIntroBottomRight__title">製造業</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_building.png" alt="" width="52" /></div>
<p class="lpIntroBottomRight__description">加工食品・惣菜</p>
</li>
<li>
<p class="lpIntroBottomRight__title">外食チェーン</p>
<div class="lpIntroBottomRight__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/icon_house.png" alt="" width="53" /></div>
<p class="lpIntroBottomRight__description">輸入食品ほか</p>
</li>
</ul>
</div>
```
*/
.lpIntroTop {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #aa1400;
  padding-bottom: 25px;
}

@media only screen and (max-width: 750px) {
  .lpIntroTop {
    flex-direction: column;
  }
}

.lpIntroTopLeft {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .lpIntroTopLeft {
    flex-direction: column;
  }
}

.lpIntroTopLeft__image {
  margin-right: 15px;
}

@media only screen and (max-width: 750px) {
  .lpIntroTopLeft__image {
    margin-right: 0;
    margin-bottom: 5px;
  }
}

.lpIntroTopLeft__text {
  font-size: 22px;
  font-size: 2.2rem;
}

.lpIntroTopRight__contact {
  display: flex;
  align-items: center;
}

.lpIntroTopRight__tag {
  background-color: #aa1400;
  color: #fff;
  width: 90px;
  line-height: 24px;
  font-size: 14px;
  font-size: 1.4rem;
  text-align: center;
  margin-right: 28px;
  border-radius: 12px;
}

.lpIntroTopRight__tel {
  display: flex;
  align-items: center;
  font-size: 22px;
  font-size: 2.2rem;
}

.lpIntroTopRight__tel img {
  margin-right: 5px;
}

.lpIntroTopRight__name {
  font-size: 16px;
  font-weight: bold;
}

.lpIntroBottom {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottom {
    flex-direction: column;
  }
}

.lpIntroBottomLeft {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottomLeft {
    padding-top: 30px;
    flex-direction: column;
  }
}

.lpIntroBottomLeft__tag {
  width: 220px;
  line-height: 48px;
  background-color: #aa1400;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-size: 2.4rem;
  border-radius: 24px;
  margin-right: 24px;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottomLeft__tag {
    margin-right: 0;
  }
}

.lpIntroBottomLeft__text {
  font-size: 46px;
  font-size: 4.6rem;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottomLeft__text {
    font-size: 26px;
    font-size: 2.6rem;
  }
}

.lpIntroBottomRight {
  display: flex;
  padding-top: 25px;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottomRight {
    justify-content: space-between;
  }
}

.lpIntroBottomRight li {
  width: 140px;
  height: 140px;
  text-align: center;
  border: 2px solid #aa1400;
  color: #aa1400;
  border-radius: 20px;
  margin-left: 17px;
  padding-top: 17px;
}

@media only screen and (max-width: 750px) {
  .lpIntroBottomRight li {
    margin-left: 0;
    width: 31%;
  }
}

.lpIntroBottomRight__title {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1;
  font-weight: bold;
}

.lpIntroBottomRight__image {
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lpIntroBottomRight__description {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1;
}

/*
#styleguide
lpGuideTop

```
<div class="lpGuideTop">
<div class="lpContainer">
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">「ほとんどの仕事は人工知能に取って代わられる」という衝撃的なフレーズを耳にされたことがありませんか？<br />在庫管理や需給調整、販売物流に関わる業務も例外ではなく、グローバルな競争環境で厳しいコスト削減とスピード経営が叫ばれる中で、<span class="strong">従来の属人的なやり方を続けている業務現場には限界が近づいています。</span><br />これからの時代、IT活用による飛躍的な業務合理化と、<span class="strong">ベテランの経験値による業務判断とをバランス良く両立できる業務インフラ</span>が必要となって来るでしょう。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_01.jpg" alt="" /></div>
</div>
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">さて、私共フェアウェイソリューションズは、在庫適正化・販売物流業務の最適化をテーマとした専門アプリケーションを開発・販売し今年で17年目となります。様々な企業様とのお付き合いのなかで断言できることはたった一つ、<br /><span class="strong fz22 color_blue2">多くの企業様で『業務プロセス改革』は一筋縄では行きません。</span><br />在庫適正化という目的ひとつを取っても、アプローチは様々です。<br />私たちは業務課題を解決するためには、まず企業様それぞれのご事情把握とビジョンの共有が肝要と考えており、この度、一方的な情報発信ではない<br /><span class="strong fz20 color_red">１社対応の相談会形式セミナー</span>を開催する運びとなりました。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_02.jpg" alt="" /></div>
</div>
<div class="lpGuideTop__rows">
<p class="lpGuideTopLeft">当日は、製造・流通業の数多のお客様でSCMシステム構築経験を持つスペシャリストが、お客様の課題解決に向けてアドバイスをさせていただきます。<br />システム導入を検討されている方も、まだ時期は早いと思われている方も、ぜひお気軽にご参加下さい。<br />在庫や物流に関する課題、煩雑なオペレーションの省力化にお悩みの企業様に、解決の一助となる事を願っております。</p>
<div class="lpGuideTop__image"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_seminar_03.jpg" alt="" /></div>
</div>
</div>
</div>
```
*/
.lpGuideTop {
  background-color: #f5f5f5;
  margin-bottom: 120px;
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
}

.lpGuideTop:after {
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  border-color: rgba(245, 245, 245, 0);
  border-top-width: 40px;
  border-bottom-width: 40px;
  border-left-width: 65px;
  border-right-width: 65px;
  margin-left: -65px;
  border-top-color: #F5F5F5;
  top: 100%;
  left: 50%;
}

.lpGuideTop__rows {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media only screen and (max-width: 750px) {
  .lpGuideTop__rows {
    flex-direction: column;
  }
}

.lpGuideTopLeft {
  width: 917px;
  font-size: 17px;
  font-size: 1.7rem;
}

@media only screen and (max-width: 750px) {
  .lpGuideTopLeft {
    width: auto;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .lpGuideTop__image {
    text-align: center;
  }
}

/*
#styleguide
lpGuideFlowBlock

```
<div class="lpGuideFlowBlock">
<div class="lpGuideFlowBlock__left">
<p class="lpGuideFlowBlock__balloon">同業他社ではどのように在庫（需給）を管理しオペレーションを改善しているのか知りたい。<br />SCM/業務システムの最近の技術動向を知りたい。</p>
<p class="lpGuideFlowBlock__title">セミナー</p>
<p class="lpGuideFlowBlock__text">最新技術を用いた企業様の業務改革への取組みを様々な導入事例をもとに解り易くご紹介します。</p>
</div>
<div class="lpGuideFlowBlock__right"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_flow_01.jpg" alt="" /></div>
</div>
```
*/
.lpGuideFlowBlock {
  margin-top: 40px;
  border: 1px solid #e6e6e6;
  padding: 25px 70px 25px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .lpGuideFlowBlock {
    flex-direction: column;
    padding: 20px;
  }
}

.lpGuideFlowBlock:first-child {
  margin-top: 60px;
}

.lpGuideFlowBlock:last-child {
  margin-bottom: 70px;
}

.lpGuideFlowBlock__left {
  width: 840px;
}

@media only screen and (max-width: 750px) {
  .lpGuideFlowBlock__left {
    width: auto;
    margin-bottom: 20px;
  }
}

.lpGuideFlowBlock__balloon {
  background-color: #f5f5f5;
  margin-bottom: 15px;
  position: relative;
  padding: 25px 34px;
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 2.2;
  border-radius: 8px;
}

.lpGuideFlowBlock__balloon:after {
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  pointer-events: none;
  position: absolute;
  border-color: rgba(245, 245, 245, 0);
  border-top-width: 24px;
  border-bottom-width: 24px;
  border-left-width: 35px;
  border-right-width: 35px;
  margin-left: -35px;
  border-top-color: #F5F5F5;
  top: 100%;
  left: 50%;
}

@media only screen and (max-width: 750px) {
  .lpGuideFlowBlock__right {
    text-align: center;
  }
}

.lpGuideFlowBlock__title {
  color: #aa1400;
  font-size: 34px;
  font-size: 3.4rem;
  font-weight: bold;
  line-height: 1.6;
}

.lpGuideFlowBlock__text {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.6;
}

/*
#styleguide
lpContact

```
<section class="lpContact">
<div class="lpContainer">
<h1 class="lpContact__headline">お申込み</h1>
<p class="lpContact__text">ご来場をお待ちしております。ご不明の点はお気軽にお問合せ下さい。</p>
<div class="modBtn01 modBtn01_large"><a href="#">お申込みはこちら</a></div>
<p class="lpContact__text lpContact__text_small">株式会社フェアウェイソリューションズ　イノベーションセミナー事務局<br />TEL 03-5822-6511 ／ FAX 03-5822-6515</p>
<p class="modLinkArrow strong"><a href="https://www.fw-solutions.com/" target="_blank" rel="noopener noreferrer">https://www.fw-solutions.com/</a></p>
</div>
</section>
```
*/
.lpContact {
  text-align: center;
  background-color: #f5f5f5;
  padding: 80px 0 60px;
  margin-bottom: 200px;
}

@media only screen and (max-width: 750px) {
  .lpContact {
    padding-top: 35px;
  }
}

.lpContact__headline {
  padding-top: 74px;
  line-height: 1;
  background: url(../img/lp/icon_pen.png) no-repeat center top;
  background-size: 52px auto;
  color: #aa1400;
  font-size: 42px;
  font-size: 4.2rem;
  font-weight: bold;
  margin-bottom: 60px;
}

@media only screen and (max-width: 750px) {
  .lpContact__headline {
    font-size: 31px;
    font-size: 3.1rem;
  }
}

.lpContact__text {
  font-size: 17px;
  font-size: 1.7rem;
  margin-bottom: 30px;
}

.lpContact__text.lpContact__text_small {
  margin-bottom: 10px;
}

/*
#styleguide
lpFeatureListBlock

```
<div class="lpFeatureListBlock">
<dl class="lpFeatureList__item lpFeatureList__item_task">
<dt>お悩み</dt>
<dd>いろいろな形態・方式で注文が来るため、手配作業に入る前の「受注入力」自体に時間がかかっている。</dd>
</dl>
<dl class="lpFeatureList__item lpFeatureList__item_solve">
<dt>解決策</dt>
<dd>流通BMSに標準対応。Web・FAX・Excel表を利用して受注入力作業負荷の軽減を図ります</dd>
</dl>
</div>
```
*/
.lpFeatureListBlock {
  margin-top: 35px;
}

.lpFeatureListBlock_mb140 {
  margin-bottom: 140px;
}

.lpFeatureList__item {
  padding: 6px;
  display: flex;
  background-color: #f5f5f5;
}

@media only screen and (max-width: 750px) {
  .lpFeatureList__item {
    flex-direction: column;
    padding: 20px;
  }
}

.lpFeatureList__item dt {
  min-height: 108px;
  width: 168px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
  margin-right: 46px;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .lpFeatureList__item dt {
    width: 100%;
    margin-right: 0;
    font-size: 21px;
    font-size: 2.1rem;
    margin-bottom: 12px;
  }
}

@media only screen and (min-width: 751px) {
  .lpFeatureList__item dt:after {
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    pointer-events: none;
    position: absolute;
    border-color: rgba(245, 245, 245, 0);
    border-top-width: 13px;
    border-bottom-width: 13px;
    border-left-width: 13px;
    border-right-width: 13px;
    margin-top: -13px;
    border-left-color: #fff;
    top: 50%;
    left: 100%;
  }
}

.lpFeatureList__item dd {
  width: calc(100% - 214px);
  font-size: 18px;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .lpFeatureList__item dd {
    width: 100%;
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.lpFeatureList__item_task {
  margin-bottom: 65px;
  position: relative;
}

.lpFeatureList__item_task dt {
  color: #aa1400;
}

.lpFeatureList__item_task:after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 50%;
  margin-left: -17px;
  background: url(../img/lp/arrow_gray.png) no-repeat;
  background-size: contain;
  width: 34px;
  height: 32px;
}

.lpFeatureList__item_solve dt {
  color: #103459;
}

/*
#styleguide
lpSupportFlow

```
<section class="lpSupportFlow">
<h2 class="lpSupportHeadline">ご導入までの流れ</h2>
<div class="lpSupportFlowBlock">
<div class="lpSupportFlowBlock__left arrowPlus">
<p class="lpSupportFlowBlock__largeText">システム化計画支援</p>
<p class="lpSupportFlowBlock__text">業務改革の目的に沿ったシステム化計画を、投資対効果を考慮しながらご一緒に作成していきます。</p>
<div class="lpSupportFlowWhite">
<ul class="lpSupportFlowList">
<li>①現状業務の確認（As-Is）</li>
<li>②Conductorを活用した新業<br />務提案（To-Be）</li>
<li>③導入効果の事前推定</li>
<li>④概算お見積</li>
</ul>
</div>
</div>
<div class="lpSupportFlowBlock__center">
<h3 class="lpSupportH3">最短距離での目的達成へ</h3>
<div class="lpSupportFlowBlock__column arrowPlus">
<p class="lpSupportFlowBlock__largeText">要件定義 １〜２ヶ月</p>
<div class="lpSupportFlowWhite">
<ul class="lpSupportFlowList">
<li>①業務要件定義</li>
<li>②外部I/F要件定義</li>
<li>③システム要件定義</li>
<li>④稼働方式検討・承認</li>
<li>⑤ご導入可否判断</li>
</ul>
</div>
</div>
<div class="lpSupportFlowBlock__column arrowPlus">
<p class="lpSupportFlowBlock__largeText">導入準備・開発 ２〜３ヶ月</p>
<div class="lpSupportFlowWhite">
<dl class="lpSupportFlow2">
<dt>導入準備</dt>
<dd>移行準備（マスタ・トラン）</dd>
<dd>Conductor研修</dd>
<dd>ユーザテスト準備・実施</dd>
<dd>システム間連携テスト</dd>
<dt>Conductor開発</dt>
<dd>設計/開発/テスト</dd>
<dt>環境構築</dt>
<dd>ハードウェア調達</dd>
<dd>Conductorセットアップ</dd>
</dl>
</div>
</div>
</div>
<div class="lpSupportFlowBlock__right">
<p class="lpSupportFlowBlock__largeText">導入後サポート</p>
<p class="lpSupportFlowBlock__text">導入後の効果を定期的に追跡し、目標達成を検証します。必要時は改善提案を継続します。</p>
<div class="lpSupportFlowWhite">
<dl class="lpSupportFlow2">
<dt>運用サポート</dt>
<dd>運用定着化サポート</dd>
<dd>Conductor運用・活用相談</dd>
<dd>オペレーションヘルプデスク</dd>
</dl>
<dl class="lpSupportFlow2">
<dt>導入後効果追跡</dt>
<dd>導入効果検証</dd>
<dd>データベース使用状況点検</dd>
<dd>運用改善提案</dd>
</dl>
</div>
</div>
</div>
</section>
```
*/
.lpSupportHeadline {
  padding: 23px 10px;
  line-height: 1;
  color: #fff;
  background: #103459;
  font-size: 32px;
  font-size: 3.2rem;
  text-align: center;
}

.arrowPlus {
  position: relative;
}

@media only screen and (min-width: 751px) {
  .arrowPlus:after {
    content: "";
    position: absolute;
    bottom: 149px;
    right: -45px;
    width: 34px;
    height: 32px;
    background: url(../img/lp/arrow_gray.png) no-repeat;
    background-size: contain;
    transform: rotate(-90deg);
  }
}

.lpSupportFlowBlock {
  display: flex;
  justify-content: space-between;
  padding: 43px 40px 35px;
  background-color: #f5f5f5;
  margin-bottom: 70px;
}

@media only screen and (max-width: 750px) {
  .lpSupportFlowBlock {
    flex-direction: column;
    padding: 20px;
  }
}

.lpSupportFlowBlock__left {
  width: 262px;
  margin-top: -10px;
}

@media only screen and (max-width: 750px) {
  .lpSupportFlowBlock__left {
    margin-top: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}

.lpSupportFlowBlock__center {
  width: 582px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .lpSupportFlowBlock__center {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
  }
}

.lpSupportFlowBlock__right {
  width: 262px;
  margin-top: -10px;
}

@media only screen and (max-width: 750px) {
  .lpSupportFlowBlock__right {
    margin-top: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}

.lpSupportFlowBlock__column {
  width: 262px;
}

@media only screen and (max-width: 750px) {
  .lpSupportFlowBlock__column {
    width: 100%;
    margin-bottom: 1px;
  }
}

.lpSupportFlowBlock__largeText {
  font-size: 20px;
  margin-bottom: 10px;
  color: #103459;
  border-radius: 6px;
}

.lpSupportFlowBlock__text {
  margin-bottom: 10px;
}

.lpSupportFlowWhite {
  background-color: #ffffff;
  height: 338px;
  border-radius: 12px;
  padding: 20px 24px;
}

.lpSupportH3 {
  width: 100%;
  line-height: 1em;
  padding: 15px 10px;
  color: #fff;
  background-color: #103459;
  text-align: center;
  font-size: 26px;
  font-size: 2.6rem;
  margin-bottom: 20px;
  border-radius: 8px;
}

.lpSupportFlowList li {
  margin-left: 1em;
  text-indent: -1em;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1;
}

.lpSupportFlow2 {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
}

.lpSupportFlow2:not(:first-child) {
  padding-top: 10px;
  margin-top: 10px;
  border-top: 1px solid #c8c8c8;
}

.lpSupportFlow2 dt:not(:first-child) {
  margin-top: 10px;
}

.lpSupportFlow2 dd {
  margin-left: 30px;
  text-indent: -13px;
}

.lpSupportFlow2 dd:before {
  width: 4px;
  height: 4px;
  content: "";
  display: inline-block;
  background-color: #444;
  margin-right: 5px;
  vertical-align: 3px;
}

/*
#styleguide
lpExample

```
<div class="lpExample">
<div class="lpExample__item"><a><span class="lpExample__thumb"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/thumb_example.jpg" alt="" /></span><span class="lpExample__right"><span class="lpExample__title">総合小売業</span><span class="lpExample__largeText">生鮮食品、加工食品</span><span class="lpExample__text">賞味期限別週間発注への引当・出荷調整業務</span></span></a></div>
<div class="lpExample__item"><a><span class="lpExample__thumb"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/thumb_example.jpg" alt="" /></span><span class="lpExample__right"><span class="lpExample__title">外食チェーン</span><span class="lpExample__largeText">本部から店舗への物流を効率化</span><span class="lpExample__tag">100店舗を超えるレストランチェーンの本部</span><span class="lpExample__text">食材や用度品の手配本部業務を効率化</span></span></a></div>
</div>
```
*/
.lpExample {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  flex-wrap: wrap;
}

.lpExample__item {
  width: 625px;
  border: 1px solid #e6e6e6;
  margin-bottom: 50px;
}

.lpExample__item a {
  display: flex;
  width: 100%;
  text-decoration: none;
  position: relative;
}

.lpExample__item a:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 14px;
  top: 50%;
  right: 15px;
  margin-top: -7px;
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
}

@media only screen and (max-width: 750px) {
  .lpExample__item a:after {
    right: 7px;
  }
}

@media only screen and (max-width: 750px) {
  .lpExample__thumb {
    width: 95px;
  }
}

.lpExample__left {
  flex: none;
}

.lpExample__right {
  padding: 10px 15px 5px;
  flex: 1;
}

@media only screen and (max-width: 750px) {
  .lpExample__right {
    padding: 10px 25px 5px 15px;
  }
}

.lpExample__title {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: bold;
  border-bottom: 2px solid #aa1400;
  margin-bottom: 10px;
  display: block;
}

.lpExample__largeText {
  font-size: 18px;
  font-size: 1.8rem;
  display: block;
}

.lpExample__text {
  margin-top: 10px;
  font-size: 15px;
  font-size: 1.5rem;
  display: block;
}

.lpExample__tag {
  padding: 0 10px;
  background-color: #aa1400;
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}

/*
#styleguide
lpExample

```
<div class="lpMoreBlock">
<p class="lpMoreBlock__largeText">詳しいことが知りたい！というお客様へ</p>
<div class="lpMoreBlock__row">
<div class="modBtn01 modBtn01_middle"><a>セミナー/相談会のお申込み</a></div>
<div class="modBtn01 modBtn01_middle"><a>お問合せ・資料ご請求</a></div>
</div>
<p class="lpMoreBlock__text"><a href="https://www.fw-solutions.com/" class="modLinkArrow" target="_blank" rel="noopener noreferrer">FAIRWAY SOLUTIONS 公式ホームページへ</a></p>
</div>
```
*/
.lpMoreBlock {
  text-align: center;
  padding: 30px;
  border: 1px solid #c8c8c8;
}

.lpMoreBlock .modBtn01 {
  margin-bottom: 25px;
}

.lpMoreBlock__largeText {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 35px;
}

.lpMoreBlock__row {
  display: flex;
  justify-content: center;
}

.lpMoreBlock__row > * {
  margin: 0 24px;
  width: 520px;
}

@media only screen and (max-width: 750px) {
  .lpMoreBlock__row {
    flex-direction: column;
  }
  .lpMoreBlock__row > * {
    width: auto;
    margin: 0 0 10px;
  }
}

/*
#styleguide
lpSimulation

```
<div class="lpSimulation">
<h3 class="modTitle08 modTitle08_red mb0">3.改善効果の試算</h3>
<div class="lpSimulationBlock">
<div class="lpSimulationBlock__left">
<p class="lpText">実現可能な対策を検討し、定量的効果を試算します。</p>
<p class="lpText">改善策が実行されたとき、経営へのインパクトがどのくらいあるのか、ご関心のない経営者はおられないことでしょう。<br />在庫診断サービスでは、皆様と「実行可能な改善策は何か？」「対象となる商品群はどれか？」を検討した上で在庫圧縮効果を推算します。</p>
<ul class="modListCheck">
<li>「まずは定番品だけでも、発注点を適正に設定し直せば、在庫日数が〇〇日→〇日に！」</li>
<li>現実的に到達可能な目標と、そこに至る方法を明確化</li>
</ul>
</div>
<div class="lpSimulationBlock__right">
<div class="colorBox"><a href="../wp/wp-content/themes/fwsol/assets/img/lp/img_simulation.jpg"><img src="../wp/wp-content/themes/fwsol/assets/img/lp/img_simulation.jpg" alt="" /></a></div>
<p class="underColorbox">クリックすると拡大します。</p>
</div>
</div>
</div>
```
*/
.lpSimulation {
  margin-top: 170px;
}

.lpSimulationBlock {
  display: flex;
  padding: 45px 50px 80px;
  border: 1px solid #c8c8c8;
  background: url(../img/lp/arrow_up_down.png) no-repeat center 90%;
}

@media only screen and (max-width: 750px) {
  .lpSimulationBlock {
    padding: 20px 20px 95px;
    display: block;
    background-position: center 98%;
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .lpSimulationBlock__left {
    text-align: left;
  }
}

.lpSimulationBlock__right {
  margin-left: 58px;
  flex: none;
}

@media only screen and (max-width: 750px) {
  .lpSimulationBlock__right {
    display: inline-block;
    margin: 0 auto;
  }
}

/*
#styleguide
colorGuide

```
<p>赤系</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_red">#aa1400</div>
</div>
<p>青系</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_blue01">#096383</div>
  <div class="modColorBlock__item modColorBlock_blue02">#103459</div>
</div>
<p>背景色等</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_bg01">#1e1e1e</div>
  <div class="modColorBlock__item modColorBlock_bg02">#646464</div>
  <div class="modColorBlock__item modColorBlock_bg03">#c8c8c8</div>
  <div class="modColorBlock__item modColorBlock_bg04">#e6e6e6</div>
  <div class="modColorBlock__item modColorBlock_bg05">#f5f5f5</div>
</div>
<p>標準テキスト</p>
<div class="modColorBlock">
  <div class="modColorBlock__item modColorBlock_text">#444444</div>
</div>
```
*/
.modColorBlock {
  display: flex;
}

.modColorBlock__item {
  width: 75px;
  height: 75px;
  text-align: center;
  margin-bottom: 25px;
}

.modColorBlock_red {
  background: #aa1400;
  color: #fff;
}

.modColorBlock_blue01 {
  background: #096383;
  color: #fff;
}

.modColorBlock_blue02 {
  background: #103459;
  color: #fff;
}

.modColorBlock_bg01 {
  background: #1e1e1e;
  color: #fff;
}

.modColorBlock_bg02 {
  background: #646464;
  color: #fff;
}

.modColorBlock_bg03 {
  background: #c8c8c8;
  color: #fff;
}

.modColorBlock_bg04 {
  background: #e6e6e6;
}

.modColorBlock_bg05 {
  background: #f5f5f5;
}

.modColorBlock_text {
  background: #444;
  color: #fff;
}

/*
#overview
modTable
*/
/*
#styleguide
modTable…通常のtableスタイル
modTable_fixed…各列が均等になります。
modTable_scroll…スマホで横スクロールになります。
modTable_column…スマホで縦並びになります。

※「modTable_scroll」「modTable_column」は、スマホでのスタイルを指定する役割を持ちます。
単体では意味を持たず、「modTable」もしくは「modTable_fixed」と組み合わせて使用します。
また、「modTable_scroll」は「scrollWrap」で囲む必要があります。
```
<div class="scrollWrap">
  <table class="modTable_fixed modTable_scroll">
    <tbody>
      <tr>
        <th>見出し</th>
        <th>見出し</th>
        <th>見出し</th>
      </tr>
      <tr>
        <th class="bgGray">サンプルテキスト</th>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
      </tr>
      <tr>
        <th class="bgGray">サンプルテキスト</th>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
        <td>本文が入ります。本文が入ります。本文が入ります。本文が入りま</td>
      </tr>
    </tbody>
  </table>
</div>

<table class="modTable modTable_column">
  <tbody>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <th class="bgGray">サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th class="bgGray">サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable, .modTable_fixed {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
}

.modTable tbody th, .modTable_fixed tbody th {
  background-color: #103459;
  color: #fff;
  padding: 10px 33px;
  border: 1px solid #fff;
}

.modTable tbody td, .modTable_fixed tbody td {
  background-color: #f5f5f5;
  padding: 10px 33px;
  border: 1px solid #fff;
}

.modTable tbody .bgGray, .modTable_fixed tbody .bgGray {
  background-color: #e6e6e6;
  border: 1px solid #fff;
  color: #444;
}

.modTable_fixed {
  table-layout: fixed;
}

@media only screen and (max-width: 750px) {
  .modTable_column thead,
  .modTable_column tr,
  .modTable_column tbody,
  .modTable_column th,
  .modTable_column td {
    display: block;
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .modTable_scroll {
    min-width: 600px;
    margin-bottom: 10px !important;
  }
}

.scrollWrap {
  overflow: auto;
  margin-bottom: 45px;
}

/*
#styleguide
modTable_gray…採用情報に使用している、グレーのtableスタイル
```
<table class="modTable_gray modTable_column">
  <tbody>
    <tr>
      <th>見出し</th>
      <th>見出し</th>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable_gray {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
}

.modTable_gray tbody th {
  background-color: #646464;
  color: #fff;
  padding: 10px 33px;
  border: 1px solid #fff;
}

@media only screen and (min-width: 751px) {
  .modTable_gray tbody th {
    width: 178px;
  }
}

.modTable_gray tbody td {
  background-color: #f5f5f5;
  padding: 10px 33px;
  border: 1px solid #fff;
}

/*
#styleguide
modTable_large…LPに使用している、大き目のtableスタイル
```
<table class="modTable_large modTable_column">
  <tbody>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
    <tr>
      <th>サンプルテキスト</th>
      <td>本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入ります。本文が入</td>
    </tr>
  </tbody>
</table>
```
*/
.modTable_large {
  width: 100%;
  font-size: 16px;
  margin-bottom: 55px;
}

.modTable_large tbody th {
  background-color: #103459;
  color: #fff;
  padding: 20px 33px;
  border: 1px solid #fff;
}

@media only screen and (min-width: 751px) {
  .modTable_large tbody th {
    width: 235px;
  }
}

.modTable_large tbody td {
  background-color: #f5f5f5;
  padding: 20px 33px;
  border: 1px solid #fff;
}

/*
#overview
modList
*/
/*
#styleguide
modList

```
<ul class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ul>
<ul class="modListNormal modListNormal_large">
    <li>font-size:18px;</li>
    <li>font-size:18px;</li>
</ul>
<ol class="modListNormal">
    <li>リスト</li>
    <li>リスト</li>
</ol>
<ul class="modListCheck">
    <li>リスト</li>
    <li>リスト</li>
</ul>
```
*/
.modListNormal {
  margin-bottom: 35px;
}

.modListNormal li {
  font-size: 16px;
  font-size: 1.6rem;
  padding-left: 15px;
  line-height: 1.8;
  margin-bottom: 8px;
  position: relative;
}

.modListNormal li:before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0px;
  width: 4px;
  height: 4px;
  background-color: #1e1e1e;
}

.modListNormal_large {
  margin-top: 20px;
  margin-bottom: 20px;
}

.modListNormal_large li {
  font-size: 18px;
  font-size: 1.8rem;
}

.modListCheck {
  margin-top: 35px;
  margin-bottom: 35px;
}

.modListCheck li {
  font-size: 18px;
  font-size: 1.8rem;
  margin-bottom: 8px;
  padding-left: 45px;
  position: relative;
  line-height: 1.8;
}

.modListCheck li:last-child {
  margin-bottom: 0;
}

.modListCheck li:before {
  content: "";
  width: 23px;
  height: 18px;
  background: url(../img/low/icon_check.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 7px;
  left: 0px;
}

ol.modListNormal li {
  list-style: decimal;
  margin-left: 1em;
}

ol.modListNormal li:before {
  display: none;
}

/* indent */
.modIndent01 li {
  text-indent: -1em;
  margin: 0 0 0 1em;
}

.modIndent02 li {
  text-indent: -2.5em;
  margin: 0 0 0 2.5em;
}

/*
#styleguide
modListTag

```
<dl class="modListTag theme_blue">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag theme_red">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
```
*/
.modListTag {
  display: flex;
  margin-top: 70px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 750px) {
  .modListTag {
    flex-direction: column;
  }
}

.modListTag dt {
  padding: 6px 18px;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
}

.modListTag dd {
  padding: 6px 18px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
}

.modListTag.theme_blue dt {
  background-color: #103459;
}

.modListTag.theme_blue dd {
  color: #103459;
  border-color: #103459;
}

.modListTag.theme_red dt {
  background-color: #aa1400;
}

.modListTag.theme_red dd {
  color: #aa1400;
  border-color: #aa1400;
}

/*
#styleguide
modListTag_large

```
<dl class="modListTag_large theme_blue">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag_large theme_red">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
<dl class="modListTag_large theme_gray">
	<dt>計画系</dt>
	<dd>需要予測・生産計画支援（必要量計算）・発注・需給調整・移動指示・在庫監視・最適発注点</dd>
</dl>
```
*/
.modListTag_large {
  display: flex;
  margin-top: 100px;
  margin-bottom: 25px;
  line-height: 1.4;
}

@media only screen and (max-width: 750px) {
  .modListTag_large {
    flex-direction: column;
  }
}

.modListTag_large dt {
  padding: 10px 18px;
  color: white;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  font-size: 1.4rem;
}

.modListTag_large dd {
  padding: 10px 18px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-size: 1.4rem;
}

.modListTag_large.theme_blue dt {
  background-color: #103459;
}

.modListTag_large.theme_blue dd {
  color: #103459;
  border-color: #103459;
}

.modListTag_large.theme_red dt {
  background-color: #aa1400;
}

.modListTag_large.theme_red dd {
  color: #aa1400;
  border-color: #aa1400;
}

.modListTag_large.theme_gray dt {
  background-color: #646464;
}

.modListTag_large.theme_gray dd {
  color: #646464;
  border-color: #646464;
}

/*
#styleguide
solutionLinkList

```
<ul class="solutionLinkList">
<li><a class="link" href="#"><span class="solutionLinkList__left"><span class="solutionLinkList__leftInner"><span class="solutionLinkList__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/logo_pilot.png" alt="" /></span><span class="solutionLinkList__title">出荷予測</span></span></span><span class="solutionLinkList__text">予測に連動した発注機能は<br />こちら</span></a></li>
<li><a class="link" href="#"><span class="solutionLinkList__left"><span class="solutionLinkList__leftInner"><span class="solutionLinkList__img"><img src="../wp/wp-content/themes/fwsol/assets/img/low/logo_conductor.png" alt="" /></span><span class="solutionLinkList__title">生産需要量計算</span></span></span><span class="solutionLinkList__text">テキストが入ります□テキストが入ります□テキストが入</span></a></li>
</ul>
```
*/
.solutionLinkList {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.solutionLinkList li {
  padding-left: 0;
  width: 448px;
  border: 1px solid #1e1e1e;
  margin-bottom: 25px;
}

.solutionLinkList li:before {
  display: none;
}

.solutionLinkList li a.link {
  display: flex;
  width: 100%;
  height: 100%;
  text-decoration: none;
  align-items: center;
  position: relative;
}

.solutionLinkList li a.link:after {
  content: "";
  background: url(../img/arrow_red_long.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 14px;
  width: 27px;
  height: 6px;
  margin-top: -3px;
}

@media only screen and (max-width: 750px) {
  .solutionLinkList li a.link:after {
    width: 16px;
    right: 5px;
  }
}

.solutionLinkList__left {
  width: 160px;
  background-color: #1e1e1e;
  color: #fff;
  flex: none;
  display: flex;
  padding: 3px 12px 5px;
  text-align: center;
  height: 100%;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .solutionLinkList__left {
    width: 130px;
  }
}

.solutionLinkList__leftInner {
  width: 100%;
}

.solutionLinkList__left span {
  margin: 0;
  font-size: 14px;
  font-size: 1.4rem;
}

@media only screen and (max-width: 750px) {
  .solutionLinkList__left span {
    font-size: 12px;
    font-size: 1.2rem;
  }
}

.solutionLinkList__text {
  margin: 0;
  padding: 10px 56px 10px 20px;
  line-height: 1.6;
  letter-spacing: 0;
}

@media only screen and (max-width: 750px) {
  .solutionLinkList__text {
    font-size: 14px;
    padding: 10px 30px 10px 10px;
  }
}

.solutionLinkList__img {
  padding-bottom: 5px;
  display: block;
}

.solutionLinkList__img img {
  vertical-align: middle;
}

.solutionLinkList__title {
  display: block;
  border-top: 1px solid #646464;
  padding-top: 5px;
}

/* フォーム
***************************************/
.modDlForm {
  margin: 0 0 20px;
}

.modDlForm dt {
  float: left;
  clear: both;
  width: 16em;
  padding: 33px 0 20px 63px;
  position: relative;
}

@media only screen and (max-width: 992px) {
  .modDlForm dt {
    float: none;
    width: auto;
    padding: 33px 0 0 63px;
  }
}

.modDlForm dt.required:before, .modDlForm dt.any:before {
  position: absolute;
  left: 0;
  top: 33px;
  color: #FFF;
  font-size: 11px;
  font-size: 1.1rem;
  padding: 2px 10px;
}

.modDlForm dt.required:before {
  content: "必須";
  background: #E33E41;
}

.modDlForm dt.any:before {
  background: #999999;
  content: "任意";
}

.modDlForm dd {
  border-bottom: solid 1px #ccc;
  padding: 10px 0;
  padding-left: 17em;
}

@media only screen and (max-width: 992px) {
  .modDlForm dd {
    padding: 10px 0;
  }
}

.modDlForm dd .parts {
  padding: 10px 0;
}

.modDlForm dd .parts textarea,
.modDlForm dd .parts input[type="text"],
.modDlForm dd .parts input[type="email"] {
  width: 100%;
}

.modDlForm dd .parts.radio_inline > div, .modDlForm dd .parts.check_inline > div {
  display: inline-block;
}

.modDlForm dd .parts.name {
  display: flex;
  justify-content: space-between;
}

.modDlForm dd .parts.name > div {
  width: 48.5%;
}

.modDlForm dd .parts.post, .modDlForm dd .parts.tel {
  display: flex;
}

.modDlForm dd .parts.post .hyphen, .modDlForm dd .parts.tel .hyphen {
  padding: 10px 10px 0 10px;
}

@media only screen and (max-width: 750px) {
  .modDlForm dd .parts.post .hyphen, .modDlForm dd .parts.tel .hyphen {
    padding: 10px 5px 0 5px;
  }
}

.modDlForm dd .parts.password input {
  width: 100%;
  max-width: 300px;
}

@media only screen and (max-width: 750px) {
  .modDlForm dd .parts.password input {
    max-width: none;
  }
}

.modDlForm dd .parts.password .text {
  padding: 10px 0;
}

.modDlForm .validationError {
  color: #E33E41;
}

/*
#styleguide
lpCheckList

```
<ul class="lpCheckList">
<li>在庫適正化・キャッシュフロー改善へむけた、具体的な方策が知りたい。</li>
<li>在庫の過剰・デッドストック化を合理的に防ぎたい。同時に、欠品発生を回避したい。</li>
<li>需給調整業務がExcel中心の個人依存になっている。現状から脱却し、誰もが効果的な需給調整が行える体制へと改革したい。</li>
</ul>
```
*/
.lpCheckList {
  margin-bottom: 80px;
}

.lpCheckList li {
  margin-bottom: 10px;
  padding: 20px 35px 20px 96px;
  background: #f5f5f5 url(../img/low/icon_check.png) no-repeat 38px 27px;
  background-size: 29px auto;
  font-size: 22px;
  font-size: 2.2rem;
}

@media only screen and (max-width: 750px) {
  .lpCheckList li {
    font-size: 17px;
    font-size: 1.7rem;
    padding: 15px 30px 15px 55px;
    background-size: 20px auto;
    background-position: 24px 22px;
  }
}

/*
#styleguide
lpFeatureNavi

```
<ul class="lpFeatureNavi">
<li><a href="#">３つの特長</a></li>
<li><a href="#">機能のポイント</a></li>
<li><a href="#">ご導入事例</a></li>
</ul>
```
*/
.lpFeatureNavi {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  margin-top: 100px;
}

@media only screen and (max-width: 750px) {
  .lpFeatureNavi {
    flex-direction: column;
  }
}

.lpFeatureNavi li {
  width: 32%;
  border: 1px solid #1e1e1e;
}

@media only screen and (max-width: 750px) {
  .lpFeatureNavi li {
    width: auto;
    margin-bottom: 5px;
  }
}

.lpFeatureNavi li a {
  display: block;
  padding: 20px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .lpFeatureNavi li a {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

.lpFeatureNavi li a:after {
  width: 27px;
  height: 5px;
  background: url(../img/arrow_black.png) no-repeat;
  background-size: contain;
  position: absolute;
  top: 36px;
  right: 10px;
  content: "";
  transform: rotate(90deg);
  z-index: 2;
}

/*
#styleguide
lpSupportList

```
<div class="lpSupportList">
<dl class="lpSupportList__item">
<dt>01</dt>
<dd>「業務のあるべき姿（To-Beモデル）」を実装したパッケージ機能をベースに、見える形でお客様とご検討が出来ます。</dd>
</dl>
<dl class="lpSupportList__item">
<dt>02</dt>
<dd>パッケージとして充実した機能と完成度を保証でき、短期間での安定稼働をお約束できます。</dd>
</dl>
<dl class="lpSupportList__item">
<dt>03</dt>
<dd>在庫にまつわる業務分野を専門領域として、豊富な導入経験と知識を持ったプロジェクトメンバーがサポートします。</dd>
</dl>
</div>
```
*/
.lpSupportList {
  margin-top: 30px;
}

.lpSupportList__item {
  border: 2px solid #c8c8c8;
  padding: 30px 50px;
  display: flex;
  align-items: center;
  border-radius: 60px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 750px) {
  .lpSupportList__item {
    display: block;
    padding: 20px;
    border-radius: 30px;
  }
}

.lpSupportList__item dt {
  font-size: 50px;
  font-size: 5rem;
  line-height: 1.3;
  padding-right: 35px;
  margin-right: 27px;
  border-right: 3px solid #aa1400;
  color: #aa1400;
  font-weight: bold;
  flex: none;
  font-family: 'Open Sans', sans-serif;
}

@media only screen and (max-width: 750px) {
  .lpSupportList__item dt {
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 20px;
    border-right-style: none;
    border-bottom: 3px solid #aa1400;
    font-size: 31px;
    font-size: 3.1rem;
    display: inline-block;
    margin-bottom: 12px;
  }
}

.lpSupportList__item dd {
  font-size: 18px;
  font-size: 1.8rem;
}

@media only screen and (max-width: 750px) {
  .lpSupportList__item dd {
    font-size: 17px;
    font-size: 1.7rem;
  }
}

/*
#overview
other
*/
/*
#styleguide
modLinkArrow
modLinkWord
modLinkExcel
modLinkPower
modLinkBlank
modLinkPdf

```
<p class="modText">
	<a href="#" class="modLinkArrow">矢印付きリンク</a>
</p>
<p class="modText">
	<a href="#" class="modLinkWord">Wordリンクデザイン</a><br>
	<a href="#" class="modLinkExcel">Excelリンクデザイン</a><br>
	<a href="#" class="modLinkPower">PowerPointリンクデザイン</a><br>
	<a href="#" class="modLinkBlank">外部リンクデザイン</a><br>
	<a href="#" class="modLinkPdf">PDFリンクデザイン</a>
</p>
```
*/
a.modLinkArrow,
.modLinkArrow a {
  display: inline-block;
  padding-right: 25px;
  text-decoration: none;
  position: relative;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 2.1;
}

a.modLinkArrow:after,
.modLinkArrow a:after {
  content: "";
  position: absolute;
  top: 11px;
  right: 0px;
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
  width: 7px;
  height: 14px;
}

a.modLinkArrow:hover,
.modLinkArrow a:hover {
  color: #aa1400;
  opacity: 1;
}

.modLinkWord {
  display: inline-block;
  padding-right: 25px;
  text-decoration: none;
}

.modLinkWord:after {
  content: "";
  background: url(../img/low/icon_word.png) no-repeat;
  background-size: contain;
  width: 17px;
  height: 21px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.modLinkWord:hover {
  color: #aa1400;
  opacity: 1;
}

.modLinkExcel {
  display: inline-block;
  text-decoration: none;
}

.modLinkExcel:after {
  content: "";
  background: url(../img/low/icon_excel.png) no-repeat;
  background-size: contain;
  width: 17px;
  height: 21px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.modLinkExcel:hover {
  color: #aa1400;
  opacity: 1;
}

.modLinkPower {
  display: inline-block;
  text-decoration: none;
}

.modLinkPower:after {
  content: "";
  background: url(../img/low/icon_ppt.png) no-repeat;
  background-size: contain;
  width: 17px;
  height: 21px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.modLinkPower:hover {
  color: #aa1400;
  opacity: 1;
}

.modLinkBlank {
  display: inline-block;
  text-decoration: none;
}

.modLinkBlank:after {
  content: "";
  background: url(../img/low/icon_blank.png) no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.modLinkBlank:hover {
  color: #aa1400;
  opacity: 1;
}

.modLinkPdf {
  display: inline-block;
  text-decoration: none;
}

.modLinkPdf:after {
  content: "";
  background: url(../img/low/icon_pdf.png) no-repeat;
  background-size: contain;
  width: 17px;
  height: 21px;
  margin-left: 4px;
  position: relative;
  top: 4px;
  display: inline-block;
}

.modLinkPdf:hover {
  color: #aa1400;
  opacity: 1;
}

/*
#styleguide
modPageLink…スマホで縦並びになります
modPageLink02…スマホで２列ずつの横並びになります
```
<div class="modPageLink">
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
</div>
<div class="modPageLink02">
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
	<div class="modPageLink__item"><a href="#">ページ内リンク</a></div>
</div>
```
*/
.modPageLink {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .modPageLink {
    flex-direction: column;
  }
}

.modPageLink__item {
  width: 30.5%;
  margin-left: 4.25%;
  margin-bottom: 10px;
  border-bottom: 1px solid #878787;
}

@media only screen and (min-width: 751px) {
  .modPageLink__item:nth-child(3n+1) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .modPageLink__item {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.modPageLink a {
  font-size: 16px;
  font-size: 1.6rem;
  padding-bottom: 10px;
  padding-right: 20px;
  display: block;
  text-decoration: none;
  position: relative;
}

.modPageLink a:after {
  content: "";
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
  transform: rotate(90deg);
  width: 8px;
  height: 14px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -13px;
}

.modPageLink02 {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .modPageLink02 {
    justify-content: space-between;
  }
}

.modPageLink02 .modPageLink__item {
  width: 30.5%;
  margin-left: 4.25%;
  margin-bottom: 10px;
  border-bottom: 1px solid #878787;
}

@media only screen and (min-width: 751px) {
  .modPageLink02 .modPageLink__item:nth-child(3n+1) {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .modPageLink02 .modPageLink__item {
    width: 48%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.modPageLink02 a {
  font-size: 16px;
  font-size: 1.6rem;
  padding-bottom: 10px;
  padding-right: 20px;
  display: block;
  text-decoration: none;
  position: relative;
}

.modPageLink02 a:after {
  content: "";
  background: url(../img/arrow_red.png) no-repeat;
  background-size: contain;
  transform: rotate(90deg);
  width: 8px;
  height: 14px;
  position: absolute;
  right: 6px;
  top: 50%;
  margin-top: -13px;
}

@-webkit-keyframes rotateLoading {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@-moz-keyframes rotateLoading {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

@keyframes rotateLoading {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}

/*↓消さないこと*/
.modLoading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}

.modLoading .inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 42px;
  height: 42px;
}

.modLoading .inner > div {
  background-color: #666;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 2px solid #666;
  border-bottom-color: transparent;
  background: transparent !important;
  display: inline-block;
  -webkit-animation: rotateLoading;
  -moz-animation: rotateLoading;
  animation: rotateLoading;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-fill-mode: none;
  animation-fill-mode: none;
  -moz-animation-delay: 0;
  animation-delay: 0;
}

/*↑消さないこと*/
